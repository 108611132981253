<template>

    <div class="personal-favorites">
        <div class="favorites-top d-sm-flex align-items-center justify-content-between">
            <h3>Обрані товари</h3>
            <button @click.prevent="$store.dispatch('wishlist/clearProducts')"
                    class="del-all d-flex align-items-center">
                <span class="ic icon-delete"></span>
                <span class="value">Видалити все</span>
            </button>
        </div>
        <div class="catalog-list inner">
            <div class="catalog-items">
                <div class="row gutters-10">
                    <div class="col-12 col-lg-6 col-xl-4" v-for="product in $store.state.wishlist.productModels"
                         :key="product.id">
                        <product-box :product="product"
                                     :wishlist-button="false"
                                     :in-stores-link="true"
                        />
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import { useRobotNoIndexFollow } from "../metaTags"
import { usePopLoader } from "../../scrollToTop";

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
useRobotNoIndexFollow()
</script>

<script>

import ProfileLayout from "./ProfileLayout.vue";
import {defineAsyncComponent} from "vue";

export default {
    components: {
        ProductBox: defineAsyncComponent(() => import("../../Components/Product/ProductBox.vue")),
    },
    layout: ProfileLayout,
    beforeCreate() {
        this.$store.dispatch('wishlist/loadProducts')
    },
    created() {
        this.currentPage = this.page || 1;
        this.hasMorePages = this.hasMore || false;
        this.orderItems = this.orders || [];
    },
    mounted() {
        let tagNoIndexFollow = document.createElement('meta')
        tagNoIndexFollow.setAttribute('id', 'robot-noindex-nofollow')
        tagNoIndexFollow.setAttribute('name', 'robots')
        tagNoIndexFollow.setAttribute('content', 'noindex, nofollow')
        document.head.appendChild(tagNoIndexFollow)
    },
    beforeUnmount() {
        let tagNoIndexFollow = document.getElementById('robot-noindex-nofollow')
        tagNoIndexFollow.remove()
    },
}
</script>

<style scoped>

</style>
