<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Кошик</span>
        </div>
        <div class="order-page">

            <form ref="paymentForm" :action="action" method="post" class="form">
                <h3 class="payment-header">
                    Оплата замовлення #{{ order.number }}
                </h3>

                <div class="order-pay">
                    <!--                    <img src="/images/pay.png" alt="">-->
                    <div v-if="paymentResult.status === 'failure'" class="payment-error">
                        {{ paymentResult.message }}
                    </div>

                    <div class="payment-info">
                        <div class="payment-title">До сплати:</div>
                        <div class="payment-amount">{{ order.total }} {{ order.currency }}</div>


                    </div>

                    <button type="submit" ref="paymentFormButton" class="button payment-submit">
                        {{ paymentResult.status === 'failure' ? 'Спробувати ще раз' : 'Далі' }}
                    </button>

                    <a v-if="paymentResult.status === 'failure'"
                       :href="route('frontend.order.success', order.id) + '?payment=cod'"
                       class="payment-back">Оплатити при отримані замовлення</a>
                </div>


            </form>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        order: {
            type: Object,
        },
        paymentResult: {
            type: Object,
        },
    },
    computed: {
        action(){
            return this.route('frontend.payment.create', this.order.id)
        }
    },
    mounted() {
        if (this.paymentResult.status === 'pending') {
            this.$refs['paymentFormButton'].click();
        }

    },
    methods: {
        submit(){
            this.$inertia.post(this.route('frontend.order.pay', this.order.id))
        }
    }
}
</script>

<style scoped>

</style>
