<template>
    <div class="order-container">
        <div class="order-container-title">1. {{ $t('Особисті дані') }}</div>
        <div class="d-md-flex align-items-start">

            <div class="order-auth">
                <form-input v-model="firstname"
                            :error="errors.firstname"
                            :label="$t('Ім’я')"
                            :required="true"
                            :maxlength="30"
                />
                <form-input v-model="lastname"
                            :error="errors.lastname"
                            :label="$t('Прізвище')"
                            :maxlength="30"
                />
                <form-input v-model="phone"
                            autocomplete="off"
                            ref="phone"
                            :id="'phone'"
                            :disabled="isLoggedIn"
                            :default-phone="phone"
                            :error="errors.phone"
                            :label="$t('Номер телефону')"
                            :input-class="'phone'"
                            :is-phone="true"
                            :maxlength="19"
                            :required="true"
                />
                <div class="auth-text" style="margin: 5px auto 10px auto; font-size:12px">
                    На номер телефону буде відправлено SMS-повідомлення з інформацією про замовлення
                </div>
                <form-input v-model="email"
                            :error="errors.email"
                            :label="'Email (не обов\'язково)'"
                            :input-class="'email'"
                            type="email"
                            :maxlength="120"
                />
                <div v-if="!isLoggedIn" class="checkbox">
                    <input v-model="register" type="checkbox" name="registr" id="registr">
                    <label for="registr">Зареєструватись</label>
                </div>

                <div v-if="!isLoggedIn && register" style="margin-top: 1.5em">
                    <form-input v-model="password"
                                :is-password="true"
                                :error="errors.password"
                                :label="'Придумайте собі пароль'"
                                :input-class="'пароль'"
                                type="password"
                                :minlength="8"
                                :maxlength="20"
                    />

                    <form-input v-model="passwordConfirmed"
                                :is-password-confirmation="true"
                                :error="errors.passwordConfirmed"
                                :label="'Введіть пароль ще раз'"
                                :input-class="'пароль'"
                                type="password"
                                :minlength="8"
                                :maxlength="20"
                    />
                </div>
            </div>
            <div class="order-auth-info">
                <div class="value" v-if="!isLoggedIn">Якщо ви постійний клієнт, авторизуйтесь за допомогою кнопки і
                    ми автоматично заповнимо ваші дані і збережемо всю інформацію по замовленню
                    і контактні дані.
                </div>

                <a v-if="!isLoggedIn"
                   @click.prevent="$inertia.modal(route('frontend.auth.login'))" href="#"
                   class="button">
                    {{ $t('Я постійний клієнт') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineAsyncComponent } from "vue"

const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"))
</script>

<script>
export default {
    name: "OrderPersonalData",
    methods: {
        fillUserData() {
            if (!this.$store.state.order.personal.phone) {
                this.$store.state.order.personal.phone = this.$store.state.user?.profile.phone
            }
            if (!this.$store.state.order.personal.firstname) {
                this.$store.state.order.personal.firstname = this.$store.state.user?.profile.first_name
            }
            if (!this.$store.state.order.personal.lastname) {
                this.$store.state.order.personal.lastname = this.$store.state.user?.profile.last_name
            }
            if (!this.$store.state.order.personal.email) {
                this.$store.state.order.personal.email = this.$store.state.user?.profile.email
            }
        },
        focusNextInput(nextInputRef) {
            if (this.$refs[nextInputRef]) {
                this.$refs[nextInputRef].focus();
            }
        },
    },
    watch: {
        isLoggedIn(value) {
            if (value) {
                this.fillUserData();
            }
        },
        register(value) {
            if (value === true) {
                if (this.password.length < 8) {
                    this.$store.commit('order/SET_ERROR', { password: 'Має бути мінімум 8 символів' });
                }
            } else {
                this.$store.commit('order/SET_ERROR', { password: '', passwordConfirmed: '' });
            }
        }
    },
    computed: {
        isLoggedIn() {
            return this.$store.state.user.isLoggedIn;
        },
        errors() {
            return this.$store.state.order.errors;
        },
        password: {
            get() {
                return this.$store.state.order.personal.password
            },
            set(value) {
                this.$store.commit('order/UPDATE_PERSONAL', { password: value })

                if (value.length < 8) {
                    this.$store.commit('order/SET_ERROR', { password: 'Має бути мінімум 8 символів' })
                } else if (value !== this.passwordConfirmed && !!this.passwordConfirmed) {
                    this.$store.commit('order/SET_ERROR', { password: 'Пароль має співпадати' })
                    this.$store.commit('order/SET_ERROR', { passwordConfirmed: 'Пароль має співпадати' })
                } else {
                    this.$store.commit('order/SET_ERROR', { password: '' })
                    this.$store.commit('order/SET_ERROR', { passwordConfirmed: '' })
                }
            }
        },
        passwordConfirmed: {
            get() {
                return this.$store.state.order.personal.passwordConfirmed
            },
            set(value) {
                this.$store.commit('order/UPDATE_PERSONAL', { passwordConfirmed: value });

                if (value !== this.password) {
                    this.$store.commit('order/SET_ERROR', { password: 'Пароль має співпадати' })
                    this.$store.commit('order/SET_ERROR', { passwordConfirmed: 'Пароль має співпадати' })
                } else {
                    this.$store.commit('order/SET_ERROR', { password: '' })
                    this.$store.commit('order/SET_ERROR', { passwordConfirmed: '' })
                }
            }
        },
        phone: {
            get() {
                return this.$store.state.order.personal.phone
            },
            set(value) {
                this.$store.commit('order/UPDATE_PERSONAL', { phone: value });
            }
        },
        firstname: {
            get() {
                return this.$store.state.order.personal.firstname
            },
            set(value) {
                this.$store.commit('order/UPDATE_PERSONAL', { firstname: value });
                if (value.length === 0) {
                    this.$store.commit('order/SET_ERROR', { firstname: 'Заповніть своє імя' });
                }
                if (value.length >= 2) {
                    this.$store.commit('order/SET_ERROR', { firstname: '' });
                }
            }
        },
        lastname: {
            get() {
                return this.$store.state.order.personal.lastname
            },
            set(value) {
                this.$store.commit('order/UPDATE_PERSONAL', { lastname: value });
            }
        },
        email: {
            get() {
                return this.$store.state.order.personal.email
            },
            set(value) {
                this.$store.commit('order/UPDATE_PERSONAL', { email: value });
            }
        },
        register: {
            get() {
                return this.$store.state.order.personal.register
            },
            set(value) {
                this.$store.commit('order/UPDATE_PERSONAL', { register: value });
            }
        }
    },
    mounted() {
        this.fillUserData()

        if (this.register && !this.isLoggedIn) {
            if (this.$store.state.order.personal.password)
                this.password = this.$store.state.order.personal.password

            if (this.$store.state.order.personal.passwordConfirmed)
                this.passwordConfirmed = this.$store.state.order.personal.passwordConfirmed
        }  

        if (this.isLoggedIn) {
            this.phone = this.$store.state.user?.profile.phone;
        }

        if (this.firstname === '' || this.firstname === undefined) {
            this.$store.commit('order/SET_ERROR', { firstname: 'Заповніть своє імя' });
        }

        if (this.phone === '' || this.phone === undefined) {
            this.$store.commit('order/SET_ERROR', { phone: 'Заповніть номер мобільного' });
        } else {
            const strippedPhoneNumber = this.phone.replace(/_/g, '')
            if (strippedPhoneNumber.length !== 19) {
                this.$store.commit('order/SET_ERROR', { phone: 'Заповніть номер мобільного' });
            }
        }
    },
}
</script>

<style scoped>

</style>
