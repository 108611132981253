<template>

    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>

            <inertia-link v-for="category in categories" :key="category.id" :href="category.url">
                {{ category.name }}
            </inertia-link>

            <span>{{ product.name }}</span>
        </div>
        <div class="h1-containter d-lg-flex align-items-end" id="all">
            <h1>{{ seo.h1 }}</h1>
        </div>
        <div class="catalog-detail">
            <div class="detail-fixed" id="top-bar-product">
                <div class="container-fluid">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="detail-nav scroll-nav">
                            <ul class="nav">
                                <template v-for="tab in fixedTabs">
                                    <li v-if="!tab.hidden">
                                        <a @click="switchTab(tab)"
                                           :href="`#${tab.id}`"
                                           :class="{active: activeTab === tab.id || !activeTab}">{{ tab.title }}</a>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <div class="detail-fixed-right d-flex align-items-center">
                            <div class="fixed-name">
                                <div class="name">{{ product.name }}</div>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars v-if="product.rating" :rating="product.rating" />
                                    <a href="#reviews" class="raiting-chats d-flex align-items-center" tabindex="0">
                                        <span class="value">{{ product.reviews_count }} відгуків</span>
                                    </a>
                                </div>
                            </div>
                            <div class="fixed-prices">
                                <div v-if="product.discount" class="d-flex align-items-center">
                                    <div class="old-price">{{ productPrice }} грн</div>
                                    <div class="sale">-{{ product.discount }}%</div>
                                </div>
                            </div>
                            <button v-if="product.price && product.quantity"
                                    :disabled="!product.quantity"
                                    @click.prevent="addToCart"
                                    class="button buy">В кошик
                            </button>
                            <button v-if="!product.price && product.quantity" @click="checkPrice()"
                                    class="button buy">Уточнити ціну
                            </button>
                            <button v-if="!product.quantity" disabled
                                    class="button buy">Немає в наявності
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail-top d-lg-flex align-items-center justify-content-between">
                <div class="detail-top__left d-flex align-items-center justify-content-between justify-content-md-start">
                    <div class="raiting-container d-flex align-items-center">
                        <rating-stars v-if="product.rating" :rating="product.rating" />
                        <div @click="scrollToSection('reviews')" class="raiting-chats d-flex align-items-center" tabindex="0">
                            <span class="value">{{ product.reviews_count }} відгуків</span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <add-to-wishlist :applystyle="true" :product="product" />
                    </div>
                </div>
                <div class="detail-articul">
                    Артикул товару: <span class="semibold">{{ product.vendor_code }}</span>
                </div>
            </div>
            <div class="detail-info" id="info">
                <div class="detail-nav">
                    <ul class="nav">
                        <template v-for="tab in fixedTabs">
                            <li v-if="!tab.hidden">
                                <a @click="switchTab(tab)"
                                   :href="`#${tab.id}`"
                                   :class="{active: activeTab === tab.id || !activeTab, act: activeTab === tab.id || !activeTab}">
                                    {{ tab.title }}
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>

                <div class="d-lg-flex detail-info-container align-items-start justify-content-between">
                    <div class="d-block d-md-flex d-lg-block">
                        <vue-easy-lightbox
                            esc-enabled
                            move-enabled
                            :visible="visible"
                            :imgs="img"
                            :index="index"
                            @hide="handleHide"
                        />
                        <div class="detail-gallery-container d-flex ">
                            <div class="gallery-thumbs">
                                <div class="gallery-thumbs-slider">
                                    <div class="slide">
                                        <img class="lozad" :src="product.image_url" alt="">
                                    </div>

                                    <div v-for="image in images" class="slide">
                                        <img class="lozad" :src="image" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="detail-gallery">
                                <div class="shild-container">
                                    <div v-if="product.new" class="shild-action">New</div>
                                    <div v-if="product.promo" class="shild-sale">Promo</div>
                                    <div v-if="product.bestseller" class="shild-hit">Bestseller</div>
                                </div>
                                <div class="detail-slider">
                                    <div class="slide d-flex align-items-center justify-content-center">
                                        <img class="lozad" :src="product.image_url" alt="" @click="showImage(product.image_url)">
                                    </div>
                                    <div v-for="image in images" class="slide d-flex align-items-center justify-content-center">
                                        <img class="lozad" :src="image" alt="" @click="showImage(image)">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="detail-props" v-if="primaryCharacteristics.length">
                            <div class="title">Основні властивості:</div>

                            <div class="row no-gutters row-cols-2 row-cols-lg-2 row-cols-xl-3 justify-content-between">
                                <div v-for="characteristic in primaryCharacteristics" class="col">
                                    <div class="prop-item d-flex align-items-center">
                                        <div class="prop-icon">
                                            <img :src="characteristic.icon_url||characteristic.icon||'/images/ic_1.svg'" alt="">
                                        </div>
                                        <div class="prop-info">
                                            <div class="data">{{ characteristic.name }}</div>
                                            <div class="value" :style="{color: characteristic.color}">
                                                {{ characteristic.value }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info-right ">
                        <div class="d-md-flex justify-content-between align-items-start">
                            <div class="info-about">
                                <div class="title">Коротко про товар:</div>
                                <div class="info-about-container">
                                    <div class="info-about-items">
                                        <div class="item d-flex align-items-start">
                                            <div v-if="product.brand" class="data">Виробник:</div>
                                            <div v-if="product.brand" class="value">
                                                <inertia-link
                                                    :href="product.categories[0]?.url + '?filters[brands][]=' + product.brand.id">
                                                    {{ product.brand ? product.brand.name : '' }}
                                                </inertia-link>
                                            </div>
                                        </div>
                                        <div v-for="characteristic in shortCharacteristics"
                                             class="item d-flex align-items-start">
                                            <div class="data">{{ characteristic.name }}:</div>
                                            <div class="value">{{ characteristic.value }}</div>
                                        </div>
                                    </div>
                                    <button v-if="shortCharacteristics.length > 6" class="more-about">Показати більше
                                    </button>
                                </div>

                                <div class="info-about-links">
                                    <button @click.prevent="askProvisor" class="quest-link d-flex align-items-center">
                                        <span class="ic icon-informer"></span>
                                        <span class="value">Запитай професійного фармацевта</span>
                                    </button>

                                    <a v-if="instruction && false" :href="instructionUrl" target="_blank"
                                       rel="noopener noreferrer noindex nofollow"
                                       class="download d-flex align-items-center">
                                        <span class="ic icon-file"></span>
                                        <span class="value">Завантажити інструкцію</span>
                                    </a>
                                </div>
                            </div>
                            <div class="detail-buy">
                                <a href="#stores" class="get-now d-inline-flex align-items-center">
                                    <span class="ic icon-check"></span>
                                    <span class="value">Забрати сьогодні в {{ product.remnants_count }} аптеках</span>
                                </a>
                                <div class="buy-block d-flex align-items-end">
                                    <div class="buy-prices">
                                        <div v-if="product.discount" class="d-flex align-items-center">
                                            <div class="old-price">
                                                <span class="price-integer">{{ intOldPrice }}</span>
                                                <span class="price-decimal">{{ decOldPrice }}</span>
                                                <span class="price-currency"> грн</span>
                                            </div>
                                            <div v-if="product.active_discount.type === 0" class="sale">
                                                <span class="price-integer">-{{ intDiscountPrice }}</span>
                                                <span class="price-decimal">{{ decDiscountPrice }}</span>
                                                <span class="price-currency">%</span>
                                            </div>
                                            <div v-if="product.active_discount.type === 1" class="sale">
                                                <span class="price-integer">-{{ intDiscountPrice }}</span>
                                                <span class="price-decimal">{{ decDiscountPrice }}</span>
                                                <span class="price-currency"> грн</span>
                                            </div>
                                        </div>
                                        <div v-if="product.weight" class="pak">{{ product.weight }}
                                            {{ product.weight_unit }}.
                                        </div>
                                        <div v-if="product.volume" class="pak">{{ product.volume }}
                                            {{ product.volume_unit }}.
                                        </div>
                                    </div>
                                </div>

                                <div class="buy-quantity">
                                    <div v-if="false" class="cashback">
                                        Кешбек: <span class="value">+{{ product.cashback }} ₴</span>
                                    </div>
                                    <product-quantity :product="product" :characteristics="mainCharacteristics" v-model="quant" />
                                </div>

                                <product-discount-timer class="buy-text mb-2"
                                                        v-if="discount && discount.time_active && discount.days_to_end < 7"
                                                        :discount="discount"
                                />
                                <div class="buy-text">Ціна дійсна тільки при замовленні на сайті!</div>

                                <div class="buy-buttons d-flex">
                                    <div class="buy-button-in-cart d-flex justify-content-between">
                                        <button v-if="!productInCart && product.price && product.quantity" :disabled="deliveries.length === 0"
                                                @click.prevent="addToCart"
                                                class="button buy-button">В кошик
                                        </button>
                                        <button v-if="productInCart"
                                                @click.prevent="removeFromCart"
                                                class="button-2 one-click">У кошику: {{ totalQuantity }} шт.
                                        </button>
                                    </div>
                                    <button v-if="product.price && false"
                                            @click.prevent="$inertia.modal(route('frontend.product.fast-order', product.slug))"
                                            class="button-2 one-click">Швидка покупка
                                    </button>
                                    <button v-if="!product.price && product.quantity"
                                            @click.prevent="checkPrice"
                                            class="button buy-button">Ціну уточнюйте
                                    </button>
                                    <button v-if="!product.quantity" disabled
                                            class="button buy-button">Немає в наявності
                                    </button>
                                </div>

                                <div v-if="false" class="buy-kredit d-flex align-items-center">
                                    <span class="ic"><v-icon icon="speed" />
                                        <span class="value">Миттєва розстрочка</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="detail-delivery">
                            <div class="delivery-title d-sm-flex align-items-center">
                                <span class="data">Ваше місто:</span>
                                <div class="select-container">
                                    <model-list-select
                                        v-model="deliveryCity"
                                        @searchchange="getItems"
                                        @input="handleOnInput"
                                        @blur="handleOnBlur"
                                        :list="cities"
                                        option-value="id"
                                        option-text="name"
                                    />
                                </div>
                            </div>

                            <div v-if="product.is_prescription" class="text-danger text-sm fs-6"
                                 style="font-size: 14px; line-height: 16px; margin: 10px auto">
                                Відпуск лікарських засобів за рецептом здійснюється лише при самовивозі з аптеки та
                                оплаті при отриманні, з обов'язковим наданням рецепту провізору
                            </div>

                            <div v-if="deliveries.length > 0 && !product.is_prescription" v-for="deliveryItem in deliveries"
                                 class="row no-gutters detail-dellivery-item">
                                <div class="col">
                                    <div class="data">{{ deliveryItem.name }}</div>
                                </div>
                                <div class="col">
                                    <div class="value">{{ deliveryItem.price_description }}</div>
                                </div>
                                <div class="col-auto">
                                    <button class="informer" data-toggle="modal"
                                            :data-target="`#modal-info-${deliveryItem.id}`">
                                        <span class="ic icon-informer-2"></span>
                                    </button>
                                </div>

                                <div class="modal fade" :id="`modal-info-${deliveryItem.id}`" tabindex="-1"
                                     aria-labelledby="modal-info-1Label" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close"></button>
                                            <div class="modal-inform">
                                                <div class="inform-title">{{ deliveryItem.name }}</div>
                                                <div class="anons">{{ deliveryItem.description }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="deliveries.length > 0 && product.is_prescription" class="row no-gutters detail-dellivery-item">
                                <div class="col">
                                    <div class="data">{{ storeDelivery[0].name }}</div>
                                </div>
                                <div class="col">
                                    <div class="value">{{ storeDelivery[0].price_description }}</div>
                                </div>
                                <div class="col-auto">
                                    <button class="informer" data-toggle="modal"
                                            :data-target="`#modal-info-${storeDelivery[0].id}`">
                                        <span class="ic icon-informer-2"></span>
                                    </button>
                                </div>

                                <div class="modal fade" :id="`modal-info-${storeDelivery[0].id}`" tabindex="-1"
                                     aria-labelledby="modal-info-1Label" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close"></button>
                                            <div class="modal-inform">
                                                <div class="inform-title">{{ storeDelivery[0].name }}</div>
                                                <div class="anons">{{ storeDelivery[0].description }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="deliveries.length === 0">
                                <div class="alert alert-danger" style="margin: 10px auto">
                                    {{ $t('В цьому місті немає доступних методів доставки') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail-instruction" id="description" v-if="product.description">
                <h3>Опис товару</h3>
                <div class="instruction-container" :class="{open: showDescription}" v-html="product.description"></div>
                <button class="link" v-if="instructionButtonVisible" @click="showDescription = !showDescription"
                        v-text="showDescription ? 'Сховати' : 'Показати'"></button>
            </div>
            <div class="detail-instruction" id="instruction" v-if="instruction">
                <h3>Інструкція по застосуванню</h3>
                <div class="instruction-name">{{ product.name }}</div>
                <div class="instruction-container" :class="{open: showInstruction}" v-html="instruction"></div>
                <button class="link" v-if="instructionButtonVisible" @click="showInstructionAction"
                        v-text="showInstruction ? 'Сховати' : 'Показати'"></button>
            </div>
            <div class="detail-more" id="characteristics">
                <div class="row gutters-10">
                    <div class="col-md-12 col-lg-6">
                        <div class="detail-more-props">
                            <h3>Властивості препарату</h3>
                            <div class="props-name">{{ product.name }}</div>
                            <div class="props-items">
                                <div v-for="characteristic in characteristics"
                                     class="props-item row no-gutters align-items-start">
                                    <div class="col data">{{ characteristic.name }}:</div>
                                    <div class="col value">{{ characteristic.value }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6">
                        <product-review-list :product="product" />
                    </div>
                </div>
            </div>
            <product-remnants :product="product" />

            <product-carousel v-if="similarProducts.length" :title="$t('Аналоги та замінники')"
                              :products="similarProducts" />

            <product-carousel v-if="relatedProducts.length" id="analogs" :title="$t('З цим товаром купують')"
                              :products="relatedProducts" />

        </div>
    </div>
</template>


<script setup>
import { defineAsyncComponent } from "vue";
import { usePopLoader } from "../../scrollToTop";
import { ModelListSelect } from "vue-search-select"
import "vue-search-select/dist/VueSearchSelect.css"

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 50, 'header-container');

const ProductDiscountTimer = defineAsyncComponent(() => import("@/frontend/Components/Product/ProductDiscountTimer.vue"))
const ProductRemnants = defineAsyncComponent(() => import("@/frontend/Components/Product/ProductRemnants.vue"))
const ProductQuantity = defineAsyncComponent(() => import("@/frontend/Components/Product/ProductQuantity.vue"))
const ProductReviewList = defineAsyncComponent(() => import("@/frontend/Components/Product/ProductReviewList.vue"))
const AddToAid = defineAsyncComponent(() => import("@/frontend/Components/Common/AddToAid.vue"))
const AddToWishlist = defineAsyncComponent(() => import("@/frontend/Components/Common/AddToWishlist.vue"))
const RatingStars = defineAsyncComponent(() => import("@/frontend/Components/Common/RatingStars.vue"))
const ProductCarousel = defineAsyncComponent(() => import("@/frontend/Components/Widgets/ProductCarousel.vue"))

</script>

<script>
import { nextTick } from 'vue'
//TODO: Минимизировать использование кода jquery
import ProductJquery from "../../Scripts/Product.jquery";
import { toAmount } from "@/utils/number";
import { proxyToArray } from "@/utils/array";
import VueEasyLightbox from 'vue-easy-lightbox'
import axios from "axios";

export default {
    name: 'Product',
    mixins: [ProductJquery],
    components: {
        VueEasyLightbox
    },
    props: {
        product: Object,
        seo: Object,
        categories: [Object, Array],
        characteristics: [Object, Array],
        mainCharacteristics: [Object, Array],
        shortCharacteristics: [Object, Array],
        addCharacteristics: [Object, Array],
        deliveryTypes: [Object, Array],
        instruction: String,
        images: [Object, Array],
        properties: [Object, Array],
        cityId: [Object, Number],
        discount: Object,
    },
    data() {
        return {
            inited: false,
            similarProducts: [],
            relatedProducts: [],
            showInstruction: false,
            showDescription: false,
            instructionButtonVisible: true,
            quant: {
                totalQuantity: 1,
                fullQuantity: 1,
                partQuantity: 0,
                packQuantity: 0,
                pack: 'упаковка',
                minPack: null,
            },
            deliveryCity: 0,
            activeTab: 'all',
            timeout: null,
            geocoder: null,
            deliveries: [],
            windowTop: 0,
            isWindowScroll: false,
            visible: false,
            index: 0,
            img: '',
        }
    },
    computed: {
        totalQuantity() {
            if (Number.isInteger(this.productInCart.totalQuantity)) {
                return this.productInCart.totalQuantity
            }
            return parseFloat(this.productInCart.totalQuantity).toFixed(2).toString().trim().replace(/\.?0+$/, "")
        },
        intOldPrice() {
            return Math.floor(this.product.price)
        },
        decOldPrice() {
            return '.'+this.product.price.toFixed(2).slice(-2)
        },
        intDiscountPrice() {
            if (this.product?.active_discount?.type === 0) {
                return Math.floor(this.product.discount)
            }
            if (this.product?.active_discount?.type === 1) {
                return Math.floor(this.product?.active_discount?.value)
            }
            return 0
        },
        decDiscountPrice() {
            if (this.product?.active_discount?.type === 0) {
                const decimals = this.product.discount.toFixed(2).slice(-2)
                if (1 * decimals > 0) {
                    return `.${decimals}`
                }
                return
            }
            if (this.product?.active_discount?.type === 1) {
                return `.${this.product?.active_discount?.value.toFixed(2).slice(-2)}`
            }
            return 0
        },
        productInCart() {
            return this.$store.getters['cart/getItemInCartById'](this.product.id)
        },
        storeDelivery() {
            return this.deliveries.filter(item => item.slug === 'pickup')
        },
        isMobile() {
            return window.innerWidth <= 768
        },
        tabs() {
            const tabs = [{id: 'all', title: 'Все про товар'}];
            if (this.product.description) {
                tabs.push({id: 'description', title: 'Опис'});
            }
            tabs.push({id: 'instruction', title: 'Інструкція'});
            tabs.push({id: 'characteristics', title: 'Властивості'});
            tabs.push({
                id: 'reviews',
                title: 'Відгуки' + (this.product.reviews_count ? `(${this.product.reviews_count})` : '')
            });
            tabs.push({
                id: 'questions',
                title: 'Запитання' + (this.product.questions_count ? `(${this.product.questions_count})` : ''),
                hidden: true
            });
            tabs.push({
                id: 'stores',
                title: 'Наявність в аптеках' + (this.product.remnants_count ? `(${this.product.remnants_count})` : '')
            });
            tabs.push({id: 'analogs', title: 'Аналоги та замінники', hidden: true});
            return tabs;
        },
        fixedTabs() {
            const tabs = [{id: 'all', title: 'Все про товар'}];
            if (this.product.description) {
                tabs.push({id: 'description', title: 'Опис'});
            }
            tabs.push({id: 'instruction', title: 'Інструкція'});
            tabs.push({id: 'characteristics', title: 'Властивості'});
            tabs.push({
                id: 'reviews',
                title: 'Відгуки' + (this.product.reviews_count ? `(${this.product.reviews_count})` : '')
            });
            tabs.push({
                id: 'stores',
                title: 'Наявність в аптеках' + (this.product.remnants_count ? `(${this.product.remnants_count})` : '')
            });
            return tabs;
        },
        instructionUrl() {
            return this.instruction ? this.product.url + '/instruction' : '';
        },
        productPrice() {
            const price = this.product.price;
            return price > 0 ? toAmount(price) : '-';
        },
        discountPrice() {
            const price = this.product.discount_price;
            return price > 0 ? toAmount(price) : '-';
        },
        primaryCharacteristics() {
            return proxyToArray(this.addCharacteristics);
        },
        quantity() {
            return this.$store.getters['cart/getProduct'](this.item, 'quantity')
        },
        cities() {
            return this.$store.state.location.listCities;
        },
    },

    watch: {
        deliveryCity(oldCity, city) {
            if (city !== 0) {
                if (oldCity !== city && this.deliveryCity !== this.$store.state.city.id) {
                    axios.post(`/api/v1/city/${this.deliveryCity}`)
                        .then(({data}) => {
                            this.emitter.emit("city-change", data.city)
                        })
                }
            }
            if (this.inited) {
                this.loadDeliveryTypes(city);
            }
        },
    },

    mounted() {
        const productInCart = this.$store.getters['cart/getItemInCartById'](this.product.id);
        if (productInCart) {
            this.quant.totalQuantity = productInCart.totalQuantity
            this.quant.fullQuantity = productInCart.fullQuantity
            this.quant.partQuantity = productInCart.partQuantity
            this.quant.packQuantity = productInCart.packQuantity
            this.quant.pack = productInCart.pack
            this.quant.minPack = productInCart.minPack
        }
        this.deliveries = this.deliveryTypes || [];
        this.inited = true;
        this.$store.dispatch('location/getCityWithStores', { limit: 200, listOnly: true });

        let tagScript = document.createElement('script')
        tagScript.setAttribute('type', "application/ld+json")
        tagScript.setAttribute('id', "ld-json-product")
        tagScript.text = `{
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "${this.product.name}",
          "image": "${this.product.image_url}",
          "description": "${this.product.description}",
          "brand": {
            "@type": "Brand",
            "name": "${this.product.brand.name}"
          },
          "sku": "${this.product.sync_id}",
          "mpn": "${this.product.atc}",
          "offers": {
            "@type": "Offer",
            "url": "${this.product.url}",
            "priceCurrency": "UAH",
            "price": "${this.product.price}",
            "availability": "https://schema.org/InStock",
            "itemCondition": "https://schema.org/NewCondition"
          }
        }`
        document.head.appendChild(tagScript)

        nextTick(() => {
            this.deliveryCity = this.$props.cityId ?? this.$store.state.city.id;
            
            const instructionContainer = document.querySelector('.instruction-container');
            const observer = new ResizeObserver(this.toggleInstructionButtonVisibility);

            observer.observe(instructionContainer);
            
            this.toggleInstructionButtonVisibility();
        });
    },

    beforeUnmount() {
        this.deliveryCity = 0
        const tagScript = document.getElementById("ld-json-product")
        tagScript.remove()
    },

    methods: {
        showImage(url) {
            this.img = url
            this.show()
        },
        show() {
            this.visible = true
        },
        handleHide() {
            this.visible = false
        },
        showInstructionAction() {
            this.showInstruction = !this.showInstruction;

            if (this.showInstruction) {
                if (window.top.scrollY > 0 || window.scrollY > 0) {
                    this.windowTop = window.top.scrollY;
                    this.isWindowScroll = true;
                } else if (window.document.body.scrollTop > 0) {
                    this.windowTop = window.document.body.scrollTop;
                }
            } else {
                nextTick(() => {
                    if (this.isWindowScroll) {
                        window.scrollTo(0, this.windowTop);
                    } else {
                        window.document.body.scrollTo(0, this.windowTop);
                    }
                });
            }

            this.toggleInstructionButtonVisibility();

            return this.showInstruction;
        },
        toggleInstructionButtonVisibility() {
            const instructionContainer = document.querySelector('.instruction-container');
            this.instructionButtonVisible = 
                instructionContainer.scrollHeight > instructionContainer.clientHeight || 
                this.showInstruction ||
                this.showDescription;
        },
        async loadDeliveryTypes() {
            let vm = this;
            await axios.get(this.route('frontend.product.delivery', [this.product.slug, this.deliveryCity])).then(response => {
                vm.deliveries = response.data.delivery
            })
        },
        switchTab(tab) {
            this.activeTab = tab.id
            this.scrollToSection(tab.id)
        },
        scrollToSection(sectionId) {
            const element = document.getElementById(sectionId);

            if (element) {
                element.scrollIntoView({behavior: "smooth"})

                const headerBottomElement = document.getElementById("header-bottom");
                if (headerBottomElement) {
                    const headerBottomHeight = headerBottomElement.getBoundingClientRect().height;
                    const sectionOffset = element.getBoundingClientRect().top - headerBottomHeight;

                    if (this.isMobile) {
                        window.document.body.scrollTo(0, sectionOffset - 100)
                    } else {
                        window.document.body.scrollTo(0, sectionOffset)
                    }
                }
            }
        },
        askProvisor() {
            this.$inertia.modal(this.route('frontend.ask_provisor.index', {product_id: this.product.id}))
        },
        checkPrice() {
            this.$inertia.modal(this.route('frontend.check-price.index', {product_id: this.product.id}))
        },
        addToCart() {
            this.$store.dispatch('cart/add', { product_id: this.product.id, qty: this.quant });
        },
        removeFromCart() {
            this.$store.dispatch('cart/remove', { product_id: this.product.id })
        },
    }
}
</script>

<style lang="scss" scoped>
.select-container {
  width: 250px;
}
.select-container .ui.search.dropdown>.text {
  color: #2F80ED !important;
  font-weight: 500 !important;;
  font-size: 14px !important;;
}
</style>
