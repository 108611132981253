<template>
    <div class="radio-inner">
        <form-input v-model="info"
                    :error="error"
                    :label="$t('Вкажіть адресу')"
                    :required="required"
        />
    </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));

export default {
    name: "OrderDeliveryExpress",
    components: {FormInput},
    computed: {
        error() {
            return this.$store.state.order.errors['delivery.info'] || '';
        },
        info: {
            get() {
                return this.$store.state.order.delivery.info;
            },
            set(value) {
                this.$store.commit('order/UPDATE_DELIVERY', {info: value});
            }
        },
        required() {
            return this.$store.state.order.delivery.type === 'express-delivery'
        }
    }
}
</script>

<style scoped>

</style>
