<template>
    <div class="personal-page">
        <div class="aptechka-detail">
            <div class="detail-top d-md-flex align-items-start justify-content-between">
                <div class="detail-name">
                    <inertia-link :href="route('frontend.profile.aids')" class="back d-flex align-items-center">
                        <span class="ic icon-Severicons2"></span>
                        <span class="value">Всі аптечки</span>
                    </inertia-link>
                    <h3 v-html="aid.name"></h3>
                </div>
                <div class="detail-info">
                    <div class="info-top d-flex align-items-center">
                        <rating-stars v-if="aid.rating" :rating="aid.rating"/>
                        <inertia-link :href="aid.url" class="raiting-chats d-flex align-items-center">
                            <span class="ic"><VIcon icon="chat-text" />
                                <span class="value">{{ aid.reviews_count }}</span>
                            </span></inertia-link>
                        <div v-if="false" class="cashback">
                            Кешбек: <span class="value">+{{ aid.cashback }} ₴</span>
                        </div>
                    </div>
                    <div class="buy-block d-sm-flex align-items-center justify-content-between">
                        <price-block :price="aid.order_price" :old-price="aid.price" />

                        <button class="button buy" @click.prevent="deleteAid">Delete</button>
                    </div>
                </div>
            </div>
            <div class="detail-image">
                <img :src="aid.image_url" alt="" class="lozad">
            </div>
            <div class="catalog-list inner">
                <div class="catalog-items">

                    <div class="row gutters-10">
                        <div v-for="item in aid.items" class="col-12 col-sm-6 col-xl-4">

                            <aid-item-box :aid-item="item" :in-stores-link="true" >
                                <template #top-buttons>

                                    <a href="#" @click.prevent="removeItem(item)">remove</a>
                                </template>
                            </aid-item-box>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ProfileLayout from "./ProfileLayout.vue";

import {defineAsyncComponent} from "vue";

const RatingStars = defineAsyncComponent(() => import("@/frontend/Components/Common/RatingStars.vue"));
const PriceBlock = defineAsyncComponent(() => import("@/frontend/Components/PriceBlock.vue"));
const AddToCart = defineAsyncComponent(() => import("@/frontend/Components/Common/AddToCart.vue"));
const AidItemBox = defineAsyncComponent(() => import("@/frontend/Components/Aid/AidItemBox.vue"));

export default {
    components: {AidItemBox, RatingStars, AddToCart, PriceBlock},
    layout: ProfileLayout,
    props: {
        aid: {},
        type: {}
    },
    methods: {
        deleteAid() {
            if(confirm('Delete?')) {
                this.$inertia.visit(this.route('frontend.aids.remove', this.aid.id), {
                    method: 'delete'
                })
            }
        },
        removeItem(item) {
            if(confirm('Delete?')) {
                this.$inertia.visit(this.route('frontend.aids.remove_item', this.aid.id), {
                    method: 'delete',
                    data: {
                        item_id: item.id
                    }
                })
            }
        }
    }

}
</script>

<style scoped>

</style>
