import store from "./index";
import HydrateStoreFromInertia from "../Helpers/HydrateStoreFromInertia";

const state = {
    isLoggedIn: false,
    profile: {
        address: {},
        aidProductsIds: []
    },
}

const getters = {
    isInAid(state) {
        return product => {
            let id = product.id || product
            return state.profile.aidProductsIds.indexOf(id) > -1
        }
    },
}

const mutations = {
    setUser(state, user){
        if (user) {
            state.profile = user
            state.isLoggedIn = !!state.profile.id

            if (state.profile.avatar) {
                state.profile.avatar = JSON.parse(state.profile.avatar)
            }

            if (state.isLoggedIn) {
                store.commit('wishlist/setProductList', user.favorite_products.map(p => parseInt(p)) || [])
                store.commit('wishlist/setAidList', user.favorite_aids.map(p => parseInt(p)) || [])
                store.commit('wishlist/setStoreList', user.favorite_stores.map(p => parseInt(p)) || [])
            }
        } else {
            state.profile = {
                address: {}
            }
            state.isLoggedIn = false
        }
    },
    UPDATE_AVATAR: (state, data) => {
        state.profile.avatar = data;
    },
}

const actions = {
    logout(context){
        this.$inertia.delete(this.$inertia.route('frontend.auth.logout'), {
            only: ['user', 'layout'],
            onSuccess(page){
                page.props.user = page.props.user || {address:{}}
                HydrateStoreFromInertia(page)
            }
        })
    },
    addProductToAid(context, product){
        this.$inertia.modal(this.$inertia.route('frontend.aids.add', product.slug))
    },
    updateAvatar({commit, state}, data) {
        commit('UPDATE_AVATAR', data)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
