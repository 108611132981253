import axios from "axios";
import { proxyToArray } from "../../utils/array";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let cancelCityTokenSource = null;

const state = {
    checked: false,
    url: '',

    errors: {},
    deliveryTypes: [],
    paymentTypes: [],

    previousActiveDelivery: {
        previous: null,
        current: null,
    },

    cityQuery: '',
    cityRequest: false,
    cities: [],

    storeQuery: '',
    storeRequest: false,
    stores: [],

    personal: {
        phone: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        passwordConfirmed: '',
        register: false,
        nocall: false,
        regular: false,
        comment: '',
    },

    recipient: {
        type: 0,
        phone: '',
        firstname: '',
        lastname: '',
        email: '',
    },

    delivery: {
        type: null,
        info: '',
        city: null,
        store: null,
    },

    payment: {
        type: null,
        info: '',
        cashback: false,
        cashback_used: 0,
    }
}

const getters = {
    previousActiveDelivery: state => state.previousActiveDelivery,
    checked: state => state.checked,
    urlOffer: state => state.url,
    storesArray: state => proxyToArray(state.stores),
    deliveryCity: (state) => state.delivery.city,
    deliveryCityId: (state) => state.delivery.city ? state.delivery.city.id : 0,
    deliveryStoreId: (state) => state.delivery.store ? state.delivery.store.id : 0,
    deliveryStore: (state, getters) => {
        return getters.deliveryStoreId > 0
            ? getters.storesArray.find(st => st.id === getters.deliveryStoreId)
            : (getters.availableDeliveryStores.length ? getters.availableDeliveryStores[0] : null)
    },
    deliveryType: (state) => state.deliveryTypes.find(dt => dt.slug === state.delivery.type),
    paymentType: (state) => state.paymentTypes.find(dt => dt.slug === state.payment.type),

    isSelectedPaymentAvailable: (state) => {
        return !state.delivery.store || !state.payment.type || state.delivery.store.available_payments.indexOf(state.payment.type) !== -1;
    },
    storeForOrder: (state, getters) => getters.storesArray.find(st => st.available_for_order > 0),
    cityStores: (state, getters) => {
        let city_id = getters.deliveryCityId;
        return city_id > 0 ? getters.storesArray.filter(store => store.city_id === city_id) : [];
    },
    filteredStoreById: (state) => (id) => {
        return state.stores.filter(store => store.id === id )
    },
    filteredStores: (state, getters) => {
        let store_id = getters.deliveryStoreId;
        let city_id = getters.deliveryCityId;
        let stores = city_id > 0 && getters.cityStores.length ? getters.cityStores : getters.storesArray;
        if (store_id > 0) {
            return stores.filter(store => store.id === store_id);
        } else {
            stores = stores.filter(store => {
                if (state.storeQuery.length === 0) return true;
                const query = state.storeQuery.toLowerCase()
                const nameMatch = store.name.toLowerCase().includes(query);
                const addressMatch = store.address.toLowerCase().includes(query);

                return nameMatch || addressMatch;
            });
        }
        return stores.sort((a, b) => a.available_for_order > b.available_for_order ? -1 : (a.available_for_order < b.available_for_order ? 1 : 0))
    },
    availableDeliveryStores: (state, getters) => {
        return state.delivery.type ? getters.storesArray : [];
    },
    availablePartRemnants: (state, getters) => {
        return getters.deliveryStore ? getters.deliveryStore.available_partially : [];
    },
    availableNoneRemnants: (state, getters) => {
        return getters.deliveryStore ? getters.deliveryStore.not_available : [];
    },
    canDelivery: (state, getters) => {
        if (getters.deliveryType && getters.deliveryType.slug === 'pickup') {
            return getters.deliveryStore ? getters.deliveryStore.available_all : false;
        }
        return !!getters.availableDeliveryStores.find(st => st.available_all)
    },
    filteredStoresMarkers: (state, getters) => {
        return getters.filteredStores?.map(store => ({
            id: store.id,
            title: store.name,
            address: store.address,
            position: {
                lat: 1 * store.lat,
                lng: 1 * store.lng,
            }
        })) || []
    },
    isFormValid: (state, getters) => {
        return !!state.personal.firstname
            && !!state.personal.phone
            && (!state.recipient.type || (!!state.recipient.phone && !!state.recipient.firstname))
            && !!state.delivery.type
            && ((state.delivery.type === 'pickup' && getters.deliveryStoreId > 0) || (state.delivery.type !== 'pickup' && !!state.delivery.info))
            && !!state.payment.type
            && !!getters.deliveryCityId
            && getters.canDelivery;
    },
    formData: (state, getters) => {
        return {
            ...state.personal,
            delivery: {
                type: state.delivery.type,
                info: state.delivery.info,
                city_id: getters.deliveryCityId,
                store_id: getters.deliveryStoreId,
            },
            payment: {...state.payment},
            recipient: {...state.recipient},
        }
    },
    isStoreAvailable: (state, getters) => (id) => {
        const stores = getters.storesArray;
        return stores.length && !!stores.find(store => store.id === id && store.available_for_order > 0);
    },
    isDeliveryAvailable: (state, getters) => (slug) => {
        const cityId = getters.deliveryCityId;

        const delivery = state.deliveryTypes.find(i => i.slug === slug)
        if (delivery.all_cities === true) {
            if (slug === 'pickup') {
                const stores = getters.storesArray;
                return stores.length > 0
            }
            return true
        }

        if (cityId > 0 && delivery.availableCities) {
            if (slug === 'pickup' || slug === 'courier-delivery') {
                const stores = getters.storesArray;
                return stores.length > 0
            }
            return delivery.availableCities.includes(cityId)
        }

        return false
    },
    isSelectedDeliveryAvailable: (state, getters) => {
        const deliveryType = state.delivery.type || '';
        if (deliveryType) {
            return state.delivery.active && getters.isDeliveryAvailable(deliveryType)
        }
        return false
    },
    recipient: (state) => {
        if (state.recipient.type === 1) {
            return {
                firstname: state.recipient.firstname,
                lastname: state.recipient.lastname,
                phone: state.recipient.phone,
                email: state.recipient.email,
            }
        } else {
            return {
                firstname: state.personal.firstname,
                lastname: state.personal.lastname,
                phone: state.personal.phone,
                email: state.personal.email,
            }
        }
    },
}

const mutations = {
    INIT: (state, data) => {
        state.errors = data.errors || {};
        state.deliveryTypes = data.deliveryTypes ? proxyToArray(data.deliveryTypes) : [];
        state.paymentTypes = data.paymentTypes ? proxyToArray(data.paymentTypes).sort((a, b) => a.position - b.position) : [];
        state.stores = data.stores ? proxyToArray(data.stores) : [];
        state.personal = {
            phone: data.order?.phone || '',
            firstname: data.order?.firstname || '',
            lastname: data.order?.lastname || '',
            email: data.order?.email || '',
            register: data.order?.register || false,
            nocall: data.order?.nocall || false,
            regular: data.order?.regular || false,
            comment: data.order?.comment || '',
        }

        state.delivery = {
            type: data.order?.delivery?.type || null,
            info: data.order?.delivery?.info || '',
            city: data.order?.city || data.city || null,
            store: data.order?.store || null,
        }

        state.cityQuery = data.order?.city?.name || '';

        state.payment = {
            type: data.order?.payment?.type || null,
            info: data.order?.payment?.info || '',
            cashback: data.order?.payment?.cashback || false,
            cashback_used: data.order?.payment?.cashback_used || 0,
        }

        state.recipient = {
            type: data.order?.recipient?.type || 0,
            phone: data.order?.recipient?.phone || '',
            firstname: data.order?.recipient?.firstname || '',
            lastname: data.order?.recipient?.lastname || '',
            email: data.order?.recipient?.email || '',
        }
    },
    UPDATE_PERSONAL: (state, data = {}) => {
        state.personal = {...state.personal, ...data};
    },
    SET_ERROR: (state, data = {}) => {
        state.errors = {...state.errors, ...data};
    },
    SET_DELIVERY_TYPES: (state, data = {}) => {
        state.deliveryTypes = data;
    },
    UPDATE_DELIVERY: (state, data = {}) => {
        state.delivery = {...state.delivery, ...data}
    },
    UPDATE_PAYMENT: (state, data = {}) => {
        state.payment = {...state.payment, ...data};
    },
    UPDATE_RECIPIENT: (state, data = {}) => {
        state.recipient = {...state.recipient, ...data};
    },
    SET_CITY_QUERY: (state, value) => {
        state.cityQuery = value;
    },
    SET_CITY_REQUEST: (state, value) => {
        state.cityRequest = value;
    },
    SET_CITIES: (state, value) => {
        state.cities = proxyToArray(value);
    },
    SET_STORE_QUERY: (state, value) => {
        state.storeQuery = value;
    },
    SET_STORE_REQUEST: (state, value) => {
        state.storeRequest = value;
    },
    SET_STORES: (state, value) => {
        state.stores = proxyToArray(value)
    },
    SET_ORDER_CHECK: (state, value) => {
        state.checked = value.order === 'success'
    },
    SET_ORDER_URL: (state, value) => {
        state.url = value
    },
    SET_PREVIOUS_DELIVERY: (state, value) => {
        state.previousActiveDelivery.previous = state.previousActiveDelivery.current
        state.previousActiveDelivery.current = value
    },
    REMOVE_CHANGED_REMNANTS: (state, item) => {
        let index = Object.values(state.delivery.store.available_partially).findIndex(obj => obj.product_id === item.product_id);
        if (index !== -1) {
            state.delivery.store.available_partially.splice(index, 1)
        }
        index = Object.values(state.delivery.store.not_available).findIndex(obj => obj.product_id === item.product_id);
        if (index !== -1) {
            state.delivery.store.not_available.splice(index, 1)
        }
    },
    AVAILABLE_IN_CURRENT_STORE(state, item) {
        if (!state.delivery.store.available_all)  state.delivery.store.available_all = true
    }
}

const actions = {
    checkOrder({commit, state},  formData) {
        axios.post(this.$inertia.route('frontend.order.check'), formData, { hideLoader: true })
            .then(({ data }) => {
                commit('SET_ORDER_CHECK', data);
            })
    },

    storeUrl({commit}, formData) {
        if (formData.delivery.type && formData.store.id) {
            axios.post(this.$inertia.route('frontend.order.offer-url'), {
                store: formData.store.id,
                delivery: formData.delivery.type,
            }).then(({ data }) => {
                commit('SET_ORDER_URL', data.offerUrl)
            })
        }
    },

    searchCities({commit, state}) {
        if (cancelCityTokenSource) {
            cancelCityTokenSource.cancel();
        }
        commit('SET_CITY_REQUEST', true);
        commit('SET_CITIES', []);

        if (state.cityQuery.length > 2) {
            cancelCityTokenSource = axios.CancelToken.source();
            axios.get('/api/v1/cities', {
                cancelToken: cancelCityTokenSource.token,
                params: {
                    cityWithStores: true,
                    q: state.cityQuery,
                    limit: 15,
                }
            })
                .then(({data}) => {
                    commit('SET_CITIES', data);
                    commit('SET_CITY_REQUEST', false);

                })
                .catch((error) => {
                    console.log(error);
                    commit('SET_CITIES', []);
                    commit('SET_CITY_REQUEST', false);
                })
        }
    },

    updateStoreRemnants({commit, state}, item) {
        commit('REMOVE_CHANGED_REMNANTS', item)
    },

    updateStoreAvailable({commit}) {
        commit('AVAILABLE_IN_CURRENT_STORE')
    },

    availableStores({commit, state, getters}, params = {}) {
        if (!params.cityId) {
            params.cityId = getters.deliveryCityId || 0;
        }
        if (!params.deliveryType) {
            params.deliveryType = getters.deliveryType ? getters.deliveryType.slug : '';
        }
        axios.get(`/order/available-stores?city_id=${params.cityId}&delivery=${params.deliveryType}`)
            .then(({data}) => {
                commit('SET_STORES', data)
            })
            .catch((error) => {
                console.log(error);
                commit('SET_STORES', []);
            })
    },

    loadDeliveryTypes({commit, getters}, params = {}) {
        if (!params.cityId) {
            params.cityId = getters.deliveryCityId || 0;
        }
        axios.get(`/order/available-delivery?city_id=${params.cityId}`, { hideLoader: true })
            .then(({data}) => {
                commit('SET_DELIVERY_TYPES', data);
            })
            .catch((error) => {
                console.log(error);
            })
    },

    loadPaymentMethods({commit}) {
        axios.get(`/order/payments`, { hideLoader: true })
            .then(({data}) => {
                const payments = proxyToArray(data.paymentTypes).sort((a, b) => a.position - b.position)
                commit('UPDATE_PAYMENT', payments);
            })
            .catch((error) => {
                console.log(error);
            })

    },

    clearDeliveryTypes({commit}) {
        commit('SET_DELIVERY_TYPES', []);
    },

    updatePreviousActiveDelivery({commit}, val) {
        commit('SET_PREVIOUS_DELIVERY', val);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
