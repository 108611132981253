const alertTimeout = 3000

const state = {
    bgOpen: false,
    cityDropdownOpen: false,
    menuCatalogOpen: false,
    headerPhonesOpen: false,
    headerCartOpen: false,
    headerSearchOpen: false,
    headerFixed: false,
    scripts: [],
    stypes: [],
    loadedScripts: [],
    loadedStypes: [],
    menus: {
        categories: [],
        pages: [],
        header: [],
        footer: [],
    },
    menuBanners: [],
    widgets: {
        ['before-header']: [],
        ['after-header']: [],
        ['before-content']: [],
        ['after-content']: [],
        ['before-footer']: [],
        ['after-footer']: [],
        ['sidebar-left']: [],
        ['sidebar-right']: [],
    },
    alert: {
        message: '',
        type: '',
        component: null,
    },

    dimensions: {
        width: typeof document !== "undefined" ? document.documentElement.clientWidth : 0,
        height: typeof document !== "undefined" ? document.documentElement.clientHeight : 0,
    }
}

const getters = {}

const mutations = {
    toggleBG(state, open = null){
        if(open === null){
            state.bgOpen = !state.bgOpen
        } else {
            state.bgOpen = open
        }
    },
    toggleCityDropdown: (state) => {
        state.cityDropdownOpen = !state.cityDropdownOpen
        state.bgOpen = !!state.cityDropdownOpen
    },
    closeCityDropdown: (state) => {
        state.cityDropdownOpen = false;
        state.bgOpen = false;
    },
    toggleMenuCatalog: (state) => {
        state.menuCatalogOpen = !state.menuCatalogOpen
        state.bgOpen = !!state.menuCatalogOpen
    },
    toggleHeaderPhones: (state) => {
        state.headerPhonesOpen = !state.headerPhonesOpen
    },
    toggleHeaderSearch: (state) => {
        state.headerSearchOpen = !state.headerSearchOpen
    },
    setAlert: (state, {message = '', type = 'success', component = null}) => {
        state.alert = {message, type, component}
    },
    toggleCart: (state, open = null) => {
        if(open === null){
            state.headerCartOpen = !state.headerCartOpen
        } else {
            state.headerCartOpen = open
        }
    },
    setLayout: (state, {scripts, styles, menus, document, body, widgets, menuBanners}) => {
        state.scripts = scripts
        state.styles = styles
        if (menus) {
            state.menus = menus
        }
        if (menuBanners) {
            state.menuBanners = menuBanners;
        }
        state.document = document
        state.body = body
        state.widgets = widgets
    },
    setDimensions(state, {width, hieght}) {
        state.dimensions.width = width
        state.dimensions.height = hieght
    }
}

const actions = {
    alert(context, {message, type = 'success', component}) {
        context.commit('setAlert', {message, type, component})
        setTimeout(() => {
            context.commit('setAlert', {})
        }, alertTimeout)
    },

    handleErrors(context, errors) {
        let message = Object.keys(errors).length ? errors[Object.keys(errors).shift()] : ''
        message && context.dispatch('alert', {message, type: 'danger'});

    },

    scrollToTop(context) {
        window.scrollTo(0, 0);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
