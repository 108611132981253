<template>
    <div class="personal-page">
        <div class="personal-container">
            <div class="personal-support contacts-page">
                <h3>Клієнтська підтримка</h3>
                <div class="d-md-flex align-items-start justify-content-between">
                    <div class="contacts-container order-12">
                        <div class="contacts-title">Клієнтам</div>
                        <div class="contacts-item d-flex align-items-start">
                            <div class="item-icon"><span class="icon-inner d-flex align-items-center justify-content-center"><span class="ic icon-phone-3"></span></span></div>
                            <div class="item-info">
                                <div class="data">Гаряча лінія:</div>
                                <a v-for="phone in $store.state.settings.phones" :href="`tel:+${$store.getters['toRawPhone'](phone)}`" class="phone">{{ phone }}</a>
                            </div>
                        </div>
                        <div class="contacts-item grafik d-flex align-items-start">
                            <div class="item-icon"><span class="icon-inner d-flex align-items-center justify-content-center"><span class="ic icon-time"></span></span></div>
                            <div class="item-info">
                                <div class="data">Графік роботи:</div>
                                <div class="value">{{ $store.state.settings.work_time }}</div>
                            </div>
                        </div>
                        <div class="contacts-item tp d-flex align-items-start">
                            <div class="item-icon"><span class="icon-inner d-flex align-items-center justify-content-center"><span class="ic icon-walkman"></span></span></div>
                            <div class="item-info">
                                <div class="data">Служба підтримки:</div>
                                <a v-for="email in $store.state.settings.emails" :href="`mailto:${email}`" class="value">{{ email }}</a>
                            </div>
                        </div>
                        <div class="contacts-soc">
                            <div class="soc-title">eTabletka в соціальних мережах:</div>
                            <div class="d-flex  flex-wrap"
                                 :class="$store.state.settings.soc_links.length > 4 ? 'justify-content-start' : 'justify-content-between'">
                                <template v-for="socLink in $store.state.settings.soc_links">
                                    <a :href="socLink.url " target="_blank"
                                       class="d-flex align-items-center justify-content-center footer-social"
                                       :class="socLink.type"
                                    >
                                        <span class="social-icon" v-html="socLink.icon"></span>
                                    </a>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="support-form">
                        <div class="modal-form support">
                            <form @submit.prevent="sendForm" action="" class="form">
                                <div class="input-container empty_field">
                                    <label>Ваше ім’я</label>
                                    <input v-model="form.name" type="text" name="imya" class="input important" placeholder="">
                                    <div class="error" v-if="errors.name">{{ errors.name }}</div>
                                </div>
                                <div class="input-container empty_field">
                                    <label>Ваш email</label>
                                    <input v-model="form.email" type="email" name="email" class="input email important" placeholder="">
                                    <div class="error" v-if="errors.email">{{ errors.email }}</div>
                                </div>
                                <div class="input-container">
                                    <label>Ваше запитання</label>
                                    <textarea v-model="form.text" name="message" class="textarea"></textarea>
                                    <div class="error" v-if="errors.text">{{ errors.text }}</div>
                                </div>

                                <div v-if="form.image" class="input-container empty_field">
                                    <img v-if="form.image" :src="imagePreview" />
                                </div>

                                <div class="add-file d-flex align-items-start">
                                    <span class="ic icon-file-2"></span>
                                    <div class="add-file-container">
                                        <div>
                                            <div class="file-title">Додати зображення</div>
                                            <div class="file-anons">Додавайте до 10 зображень в форматах <br />.jpg, .gif, .png, розміром файлу до 5 МБ </div>
                                        </div>
                                        <div class="file-container">
                                            <label class="file-label ">
                                                <div class="file-text">Додати файл</div>

                                                <input @change="previewImage" accept=".jpg,.png,.gif,.jpeg"
                                                       class="input-file"
                                                       type="file" name="data[file]" placeholder="Прикрепите файл"
                                                       style="display: none;">
                                                <div class="js-labelFile has-file">
                                                    <span class="js-fileName"></span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="addrev-bottom d-sm-flex align-items-center justify-content-between">
                                    <button class="submit button" :disabled="isButtonDisabled">Надіслати запитання</button>
                                    <div class="checkbox">
                                        <input type="checkbox" name="povidom" id="povidom">
                                        <label for="povidom">Повідомляти про відповідь на електронну пошту</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRobotNoIndexFollow } from "../metaTags"
import { usePopLoader } from "../../scrollToTop";

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
useRobotNoIndexFollow()
</script>

<script>
import ProfileLayout from "./ProfileLayout.vue";

export default {

    layout: ProfileLayout,
    props: {
        errors: [Object, Array],
    },
    data() {
        return {
            form: {
                name: this.$store.state.user.profile.name,
                email: this.$store.state.user.profile.email,
                text: '',
                image: '',
            },
            imagePreview: '',
        }
    },

    mounted() {
        if (this.$store.state.user.profile.name !== undefined || this.$store.state.user.profile.name !== null)
            this.form.name = this.$store.state.user.profile.name

        if (this.$store.state.user.profile.email !== undefined || this.$store.state.user.profile.email !== null)
            this.form.email = this.$store.state.user.profile.email
    },

    computed: {
        isButtonDisabled() {
            return this.form.name === '' || this.form.email === '' || this.form.text === ''
        }
    },

    methods: {
        async sendForm() {
            let vm = this
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('email', this.form.email);
            formData.append('text', this.form.text);
            formData.append('image', this.form.image);
            await this.$inertia.post(this.route('frontend.profile.support.add'), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onSuccess() {
                    vm.imagePreview = null
                    vm.form = {
                        name: vm.form.name,
                        email: vm.form.email,
                        text: '',
                        image: '',
                    }
                }
            })
        },
        previewImage(event){
            this.form.image = event.target.files[0]
            this.imagePreview = URL.createObjectURL(event.target.files[0])
        }
    }
}
</script>

<style scoped>

</style>
