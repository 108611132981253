<template>
    <div class="modal-form">
        <div class="form-icon">
            <VIcon icon="form" />
        </div>
        <div class="form-title">Додати в аптечку</div>

        <form @submit.prevent="submitForm">

            <form-select v-model="form.aid_id"
                         :select-class="'select-default'"
                         :options="aids"
                         :select2="true"
            />

            <button class="submit button" @click="addToAid">Додати</button>
        </form>
    </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "AddProductToAid",
    components: {
        FormSelect: defineAsyncComponent(() => import("@/frontend/Components/Form/FormSelect.vue")),
    },
    props: {
        errors: {},
        product: {},
        aids: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            form: {
                aid_id: null,
            },
        }
    },
    mounted() {
        this.form.aid_id = this.aids[0]?.value
    },
    methods: {
        submitForm() {
            let vm = this
            this.$inertia.modal(this.route('frontend.aids.add', this.product.slug), 'post', this.form, {}, () => {
                vm.$store.dispatch('layout/alert', {message: vm.$t('Success')})
            })
        }
    },
}
</script>

<style scoped>

</style>
