<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Популярні товари</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>{{ $page.props.seo.h1 }}</h1>
        </div>

        <div class="catalog-list inner">
            <div class="catalog-items">

                <div class="row gutters-6">
                    <div v-for="product in products" class="col-12 col-md-6  col-lg-3">

                        <product-box :product="product" />

                    </div>
                </div>
            </div>
        </div>
        <seo-text />
    </div>
</template>

<script>

import {defineAsyncComponent} from "vue";

export default {
    name: "Popular",
    components: {
        SeoText: defineAsyncComponent(() => import("@/frontend/Components/Seo/SeoText.vue")),
      ProductBox: defineAsyncComponent(() => import("../../Components/Product/ProductBox.vue")),
    },
    props: {
        products: {
            type: Array,
            default() {
                return []
            }
        }
    }
}
</script>

<style scoped>

</style>
