<template>
    <div class="order-radios">

        <div v-if="error" class="alert alert-danger error">
            {{ error }}
        </div>

        <template v-for="(delivery, i) in deliveryTypes" :key="i">
            <div class="radio delivery-radio">
                <input v-model="delivery_type"
                       :value="delivery.slug" type="radio"
                       name="delivery"
                       :id="`delivery-${i}`"
                       :disabled="!isDeliveryAvailable(delivery.slug) || !delivery.prescription"
                       class="open-check">
                <label :for="`delivery-${i}`">
                    <div class="data">{{ delivery.name }}</div>
                    <div class="value">{{ delivery.description }}</div>
                    <div class="value text-danger" v-if="!isDeliveryAvailable(delivery.slug) || !delivery.prescription">
                        {{ deliveryTitles(delivery) }}
                    </div>
                    <div class="value text-danger" v-if="isDeliveryAvailable(delivery.slug) && !delivery.prescription && delivery.slug !== 'courier-delivery'">
                        {{ delivery.prescription_text }}
                    </div>
                    <div class="value"
                         v-if="isDeliveryAvailable(delivery.slug) && delivery.prescription">
                        Вартість доставки:
                        {{
                            delivery.min_price > 0 ? 'від ' + delivery.min_price + ' грн.' : delivery.price_description
                        }}
                    </div>
                </label>
            </div>

            <div class="container-radio" :class="{collapse: delivery_type !== delivery.slug}" :id="`cont-delivery-${i}`">
                <order-delivery-pickup v-if="delivery.slug === 'pickup'" />
                <order-delivery-courier v-if="delivery.slug === 'courier-delivery' && isAvailableCourier" />
                <order-delivery-express v-if="delivery.slug === 'express-delivery' && !hasPrescription" />
                <order-delivery-post v-if="delivery.slug === 'post' && !hasPrescription" />
            </div>

        </template>

        <div v-if="delivery_type && delivery_type !== 'pickup' && !canDelivery" class="text-danger danger-message mt-3">
            <div v-if="availablePartRemnants.length > 0">
                Нажаль зараз для доставки доступно лише
                <span v-for="remnant in availablePartRemnants">
                    <b>{{ remnant.available }} шт "<i>{{ remnant.product_name }}</i>"</b>
                </span>.
            </div>
            <div v-if="availableNoneRemnants.length > 0">
                Нажаль зараз ми не можемо доставити
                <span v-for="remnant in availableNoneRemnants">
                    <b>"<i>{{ remnant.product_name }}</i>"</b>
                </span>.
            </div>
            Ви можете змінити спосіб доставки або зменшити кількість товару в замовлені

        </div>
    </div>
</template>

<script>
import OrderDeliveryPickup from "./OrderDeliveryPickup.vue";
import OrderDeliveryCourier from "./OrderDeliveryCourier.vue";
import OrderDeliveryExpress from "./OrderDeliveryExpress.vue";
import OrderDeliveryPost from "./OrderDeliveryPost.vue";

export default {
    name: "OrderDeliveryType",
    components: {
        OrderDeliveryPost,
        OrderDeliveryExpress,
        OrderDeliveryCourier,
        OrderDeliveryPickup
    },
    data () {
        return {
            timerId: null,
            storesEmpty: false,
            courierDeliveryCities: [29273]
        }
    },
    mounted() {
        if (this.isCitySelected) {
            this.timerId = setTimeout(this.usePickupDelivery, 1000);
        }
    },
    computed: {
        cartItems() {
            return this.$store.getters['cart/cartItems'];
        },
        productTypes() {
            return this.$store.getters['cart/productTypes'];
        },
        hasPrescription() {
            return this.$store.getters['cart/isPrescription'];
        },
        isAvailableCourier() {
            return this.courierDeliveryCities.includes(this.deliveryCity)
        },
        deliveryCity() {
            return this.$store.getters['order/deliveryCityId']
        },
        deliveryTypes() {
            const deliveryTypes = this.$store.state.order.deliveryTypes;
            if (this.hasPrescription) {
                return deliveryTypes.filter(delivery => delivery.slug === 'pickup' || delivery.slug === 'courier-delivery')
            }

            const productTypes = this.productTypes

            return deliveryTypes.filter(item => {
                if (item.config && item.config.product_types) {
                    return item.config.product_types.some(type => productTypes.includes(type))
                }
                return false
            })
        },
        isSelectedDeliveryAvailable() {
            return this.$store.getters['order/isSelectedDeliveryAvailable'];
        },
        canDelivery() {
            return this.$store.getters['order/canDelivery'];
        },
        error() {
            return this.$store.state.order.errors['delivery.type'] ||
                this.$store.state.order.errors['delivery.store_id'] ||
                this.$store.state.order.errors['delivery.info'];
        },
        delivery_type: {
            get() {
                return this.$store.state.order.delivery.type;
            },
            set(value) {
                this.$store.commit('order/UPDATE_DELIVERY', {type: value})
            },
        },
        isCitySelected() {
            return this.$store.getters['order/deliveryCityId'] > 0;
        },
        availablePartRemnants() {
            return this.$store.getters['order/availablePartRemnants']
        },
        availableNoneRemnants() {
            return this.$store.getters['order/availableNoneRemnants']
        },
        availableDeliveryStores() {
            return this.$store.getters['order/availableDeliveryStores']
        },
    },
    methods: {
        deliveryTitles(delivery) {
            if (delivery.slug === 'pickup')
                return  'Жодного товару із кошику немає в наявності в аптеках вашого міста'

            if (delivery.slug === 'courier-delivery') {
                if (! this.isAvailableCourier) {
                    return 'На жаль, наша кур`єрська служба зараз працює лише у Вінниці. Але ми працюємо над тим, щоб у Вашому місті ця послуга теж була доступна.'
                }
                if (this.hasPrescription) {
                    return 'На жаль, наша кур`єрська служба не доставляє рецептурні ліки. Видаліть рецептурні ліки з замовлення або оберіть інший спосіб доставки.'
                }
            }

            return 'Нажаль зараз ми не можемо доставити до вашого міста'
        },
        isDeliveryAvailable(slug) {
            if (slug === 'courier-delivery' && !this.isAvailableCourier) return false
            const previousActiveDelivery = this.$store.getters['order/previousActiveDelivery']
            if (previousActiveDelivery.previous && previousActiveDelivery.previous === slug) return true
            return this.$store.getters['order/isDeliveryAvailable'](slug)
        },
        usePickupDelivery() {
            const pickupRadio = document.querySelector('input[type="radio"][name="delivery"][value="pickup"]');
            if (pickupRadio) {
                pickupRadio.click();
                this.$store.dispatch('order/updatePreviousActiveDelivery',  'pickup')
                if (this.timerId) {
                    clearTimeout(this.timerId);
                }
            }
        }
    },
    watch: {
        cartItems(val) {
            this.$store.dispatch('order/loadDeliveryTypes')
        },
        delivery_type(val)  {
            this.$store.dispatch('order/updatePreviousActiveDelivery',  val)
            this.emitter.emit('current-delivery-type', val)
        },
        availableDeliveryStores(val) {
            this.storesEmpty = val.length === 0
        }
    },
}
</script>

<style scoped lang="scss">
.danger-message {
    font-size: 13px;
    line-height: 1.2;
}
</style>
