<template>
    <div class="order-container" id="recipient">
        <div class="order-container-title">4. Отримувач</div>

        <div v-if="error" class="alert alert-danger error">
            {{ error }}
        </div>

        <div class="d-md-flex align-items-top person-info">
            <div class="order-radios d-sm-flex align-items-start">
                <div class="radio">
                    <input v-model="recipient_type" :value="0" type="radio" name="persona"
                           id="persona-1" class="open-check">
                    <label for="persona-1">
                        <div class="data">Я, отримувач</div>
                    </label>
                </div>
                <div class="radio">
                    <input v-model="recipient_type" :value="1" type="radio" name="persona"
                           id="persona-2" class="open-check">
                    <label for="persona-2">
                        <div class="data">Інша людина</div>
                    </label>
                </div>
            </div>
            <div class="checkbox">
                <input type="checkbox" name="nocall" id="nocall" v-model="nocall">
                <label for="nocall">Не потрібно телефонувати для <br />уточнення замовлення</label>
            </div>
        </div>
        <div :class="{collapse: recipient_type !== 1}" class="container-radio" id="cont-persona-2">
            <div class="radio-inner">
                <div class="order-auth">
                    <form-input v-model="firstname"
                                :error="errors['recipient.firstname']"
                                :label="$t('Ім’я')"
                                :required="recipient_type === 1"
                    />
                    <form-input v-model="lastname"
                                :error="errors['recipient.lastname']"
                                :label="$t('Прізвище')"
                    />
                    <form-input v-model="phone"
                                ref="phoneRecipient"
                                :id="'phoneRecipient'"
                                autocomplete="off"
                                :error="errors?.recipient?.phone"
                                :label="$t('Номер телефону отримувача')"
                                :is-phone="true"
                                :is-recipient="true"
                                :maxlength="19"
                                :required="recipient_type === 1"
                    />
                </div>
            </div>
        </div>
        <div class="add-comment" type="button" data-toggle="collapse"
             :class="{collapsed: !comment}"
             data-target="#order-comment" aria-expanded="false" aria-controls="order-comment">
            Додати коментар до замовлення
        </div>
        <div class="comment collapse" id="order-comment" :class="{show: !!comment}">
            <textarea class="textarea" v-model="comment"></textarea>
        </div>
    </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));

export default {
    name: "OrderRecipient",
    components: { FormInput },
    computed: {
        errors() {
            return this.$store.state.order.errors || {};
        },
        error() {
            return this.$store.state.order.errors?.recipient?.type || '';
        },
        recipient_type: {
            get() {
                return this.$store.state.order.recipient.type;
            },
            set(value) {
                this.$store.commit('order/UPDATE_RECIPIENT', {type: value});
            }
        },
        firstname: {
            get() {
                return this.$store.state.order.recipient.firstname;
            },
            set(value) {
                this.$store.commit('order/UPDATE_RECIPIENT', {firstname: value});
            }
        },
        lastname: {
            get() {
                return this.$store.state.order.recipient.lastname;
            },
            set(value) {
                this.$store.commit('order/UPDATE_RECIPIENT', {lastname: value});
            }
        },
        phone: {
            get() {
                return this.$store.state.order.recipient.phone
            },
            set(value) {
                this.$store.commit('order/UPDATE_RECIPIENT', {phone: value});
            }
        },
        comment: {
            get() {
                return this.$store.state.order.personal.comment;
            },
            set(value) {
                this.$store.commit('order/UPDATE_PERSONAL', {comment: value});
            }
        },
        nocall: {
            get() {
                return this.$store.state.order.personal.nocall;
            },
            set(value) {
                this.$store.commit('order/UPDATE_PERSONAL', {nocall: value});
            }
        }
    },

    mounted() {
        this.phone = ''
        this.firstname = ''
    }
}
</script>

<style scoped>

</style>
