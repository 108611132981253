import store from '../store'
import { proxyToArray } from "../../utils/array";

export default function HydrateStoreFromInertia(page) {
    // console.log('PAGE PROPS', page.props);
    if (page.props.isModal) {

    }

    if (page.props.locale) {
        store.commit('setLocale', page.props.locale);
    }

    if (page.props.city) {
        store.commit('setCity', page.props.city);
    }

    if (page.props.location.cities) {
        store.commit('setCities', page.props.location.cities);
    }

    if (page.props.settings){
        store.commit('setSettings', page.props.settings);
    }

    // if (page.props.errors){
    //     store.dispatch('layout/alert', {
    //         type: 'danger',
    //         message: Object.values(page.props.errors).join("\n")
    //     });
    // }

    if (page.props.success) {
        store.dispatch('layout/alert', {
            type: 'success',
            message: page.props.success
        })
    }

    if (page.props.languages) {
        store.commit('setLanguages', page.props.languages)
    }

    if(page.props.location){
        store.commit('location/setLocation', page.props.location);
    }

    if (page.props.layout) {
        store.commit('layout/setLayout', page.props.layout);
    }

    if (page.props.cart) {
        store.commit('cart/setCart', page.props.cart)
    }

    if (page.props.user !== undefined) {
        store.commit('user/setUser', page.props.user)
    }
}
