<template>
    <div class="radio-inner">
        <div class="order-apteki">
            <div class="pharmacy-tabs-container d-md-flex align-items-center justify-content-between">
                <div class="search-city order-12">
                    <div class="search-container">
                        <form v-if="tab === 'list'" class="form" ref="storeSearchForm">
                            <div class="input-container">
                                <input v-model="query"
                                       type="text" class="input search-input "
                                       placeholder="Пошук за адресою чи номером аптеки">
                                <span type="submit" class="submit">
                                    <span class="ic icon-ic_search"></span>
                                </span>
                            </div>
                        </form>
                        <div v-if="tab === 'map'" class="input-container">
                            <input v-model="query"
                                   @input="searchAddressOnMap($event.target.value)"
                                   type="text" class="input search-input "
                                   placeholder="Пошук за адресою аптеки">
                            <span type="submit" class="submit">
                                <span class="ic icon-ic_search"></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="pharmacy-tabs">
                    <ul id="tabs-pharmacy" class="nav tabs-ul">
                        <li class="nav-item">
                            <a href="#list" class="nav-link"
                               @click.prevent="tab = 'list'"
                               :class="{active: tab === 'list'}"
                            >Списком</a>
                        </li>
                        <li class="nav-item">
                            <a href="#map-items" class="nav-link"
                               @click.prevent="tab = 'map'"
                               :class="{active: tab === 'map'}"
                            >На карті</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="tabs-container">
                <div class="tab-pane fade" id="list" :class="{active: tab === 'list', show: tab === 'list'}">
                    <div class="apteki-list">
                        <div v-if="filteredStores.length > 0" class="list-scroll" ref="listScrollRef" :class="{selected: !!selectedStore}">
                            <div v-for="store in filteredStores"
                                 :key="'st-'+store.id"
                                 class="list-item">
                                <div class=" d-sm-flex align-items-start justify-content-between">
                                    <div class="apteka-info">
                                        <div class="name">{{ store.name }}</div>
                                        <div class="adres">{{ store.address }}</div>
                                        <div class="phone d-sm-flex">
                                            <span>{{ store.work_time }}</span>
                                            <span>{{ `store`.phone }}</span>
                                        </div>
                                    </div>

                                    <div v-if="store.available_remnants.length !== 0"
                                         @click="selectStore(store, store.available_remnants.length === 0)"
                                         :disabled="store.available_remnants.length === 0"
                                         class="button-2 sel-apteka vue"
                                         :class="{disabled: store.available_remnants.length === 0, active: store.id === storeId}"
                                    >
                                        {{ store.id === storeId ? 'Обрано' : 'Обрати' }}
                                    </div>
                                </div>
                                <template v-if="!selectedStore">
                                    <div v-for="remnant in store.available_remnants" class="available-remnants">
                                        <i>{{ remnant.product_name }}</i>
                                        доступно:
                                        <b :class="{'text-danger': remnant.available < remnant.totalQuantity}">
                                            {{ remnant.available }} шт
                                        </b>
                                    </div>
                                    <div v-for="remnant in store.not_available" class="available-remnants">
                                        <i>{{ remnant.product_name }}</i>
                                        <b class="text-danger"> немає в наявності</b>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div v-else>За вказаною адресою немає наших аптек</div>
                    </div>
                </div>

                <div class="tab-pane fade " id="map-items" :class="{active: tab === 'map', show: tab === 'map'}">
                    <div class="map-container">
                        <leaflet-map v-if="tab === 'map'" @marker-click="onMarkerClick" :only-current="true" :is-delivery="tab === 'map'" style="z-index: 10!important; margin-bottom: 10px"></leaflet-map>
                    </div>
                </div>
            </div>

            <div v-if="availablePartRemnants.length || availableNoneRemnants.length && !disableRefillCart"
                 class="text-danger danger-message mt-3">

                <div v-if="availablePartRemnants.length">
                    В аптеці доступно лише:
                    <span v-for="remnant in availablePartRemnants">
                        <b>{{ remnant.available }} шт<i style="color: #000000">&nbsp;-&nbsp;"{{ remnant.product_name }}";</i>&nbsp;</b>
                    </span>
                </div>
                <div v-if="availableNoneRemnants.length">
                    В аптеці відсутній товар:
                    <span v-for="remnant in availableNoneRemnants">
                        <b><i style="color: #000000">"{{ remnant.product_name }}";</i>&nbsp;</b>
                    </span>
                </div>
                Для підтвердження замовлення зробіть автокорекцію або оберіть іншу аптеку.
                <div style="margin-top: 10px;"
                     @click="updateOrder"
                     class="button-2 sel-apteka vue"
                     :disabled="disableRefillCart"
                >Автокорекція замовлення</div>
                <div style="margin-top: 5px">Автокорекція зменшить у Вашому замовлені кількість товару до тієї, що доступна в аптеці.</div>
            </div>
        </div>
    </div>

</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
    name: "OrderDeliveryPickup",
    components: {
        LeafletMap: defineAsyncComponent(() => import("@/frontend/Components/LeafletMap.vue")),
    },
    data() {
        return {
            tab: 'list',
            disableRefillCart: false,
        }
    },
    computed: {
        query: {
            get() {
                return this.$store.state.order.storeQuery;
            },
            set(value) {
                this.$store.commit('order/SET_STORE_QUERY', value);
            }
        },
        allStores() {
            return this.$store.getters['order/storesArray'];
        },
        filteredStores() {
            return this.$store.getters['order/filteredStores'];
        },
        filteredStoresMarkers() {
            return this.$store.getters['order/filteredStoresMarkers'];
        },
        selectedStore() {
            return this.$store.state.order.delivery.store;
        },
        storeId() {
            return this.$store.getters['order/deliveryStoreId']
        },
        availablePartRemnants() {
            return this.selectedStore ? this.selectedStore?.available_partially : [];
        },
        availableNoneRemnants() {
            return this.selectedStore ? this.selectedStore?.not_available : [];
        },
    },
    mounted() {
        this.disableRefillCart = false
    },
    methods: {
        searchAddressOnMap(value) {
            if (value.length >= 5) {
                const city = this.$store.getters['order/deliveryCity'] ?? this.$store.state.city
                console.log('city 0', city)
                this.$store.dispatch('markets/getPointByAddress', { value, city })
            }
        },
        selectStore(store, disable) {
            if (disable) return
            if (!this.selectedStore || this.selectedStore.id !== store.id) {
                this.$store.commit('order/UPDATE_DELIVERY', { store: store })
                this.$store.dispatch('order/storeUrl', { store: store, delivery: this.$store.state.order.delivery })
            } else {
                this.$store.commit('order/UPDATE_DELIVERY', { store: null })
            }
        },
        onMarkerClick(store) {
            if (!this.selectedStore || this.selectedStore.id !== store.id) {
                this.$store.commit('order/UPDATE_DELIVERY', { store: null })
                store = this.allStores.find(st => st.id === store.id)
                if (store) this.selectStore(store)
            } else {
                this.$store.commit('order/UPDATE_DELIVERY', { store: null })
            }
        },
        isStoreAvailable(storeId) {
            return this.$store.getters['order/isStoreAvailable'](storeId);
        },
        async updateOrder() {
            this.disableRefillCart = true

            axios.post(this.route('frontend.autocorrection'), {
                data: {
                    phone: this.$store.state.order.personal.phone,
                    party: this.availablePartRemnants,
                    notAvailable: this.availableNoneRemnants,
                }
            })

            let step = this.availablePartRemnants.length
            let timeout = step > 0 ? (step * 50) + 500 : 0;
            let available = this.availablePartRemnants.length
            const availablePart = this.availablePartRemnants
            while (available !== 0) {
                const keys = Object.keys(availablePart)
                const item = availablePart[keys[available-1]]
                if (item.available !== 0) {
                    await this.$store.dispatch('cart/setQuantity', item)
                    await this.$store.dispatch('order/updateStoreRemnants', item)
                    await this.delay(50);
                }
                available--;
            }

            await this.delay(timeout);

            step = this.availableNoneRemnants.length
            timeout = step > 0 ? (step * 50) + 500 : 0;
            available = this.availableNoneRemnants.length
            const notAvailable = this.availableNoneRemnants
            while (this.availableNoneRemnants.length !== 0) {
                const keys = Object.keys(notAvailable)
                const item = notAvailable[keys[available-1]]
                if (item.available === 0) {
                    this.$store.dispatch('cart/remove', item)
                    this.$store.dispatch('order/updateStoreRemnants', item)
                    await this.delay(50);
                }
                available--;
            }

            await this.delay(timeout);
            this.emitter.emit('check-order')
        },
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    }
}
</script>

<style scoped lang="scss">
.danger-message {
    line-height: 1.2;
    font-size: 13px;
}

.available-remnants {
    font-size: 12px;
    line-height: 1;
    margin-top: 10px;
}
</style>
