<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Доставка та оплата</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>Доставка та оплата</h1>
        </div>
        <div class="content-container d-lg-flex align-items-start">
            <div class="menu-left-container">
                <nav class="menu-left">
                    <button class="button-left d-flex d-lg-none align-items-center justify-content-center collapsed" data-toggle="collapse" href="#menu-left" role="button" aria-expanded="false" aria-controls="menu-left">
                        <span class="but-icon"></span>
                        <span class="value">Меню</span>
                    </button>
                    <ul class="collapse" id="menu-left">
                        <li><a href="#">Про компанію</a></li>
                        <li><a href="#" class="active">Доставка та оплата</a></li>
                        <li><a href="#">Наша команда</a></li>
                        <li><a href="#">Медичні експерти</a></li>
                        <li><a href="#">Контакти</a></li>
                        <li><a href="#">Відгуки про компанію</a></li>
                        <li><a href="#">Умови повернення</a></li>
                        <li><a href="#">Часті питання та відповіді</a></li>
                        <li><a href="#">Ліцензії</a></li>
                        <li><a href="#">Соціальні програми</a></li>
                        <li><a href="#">Редакційна політика</a></li>
                        <li><a href="#">Маркетингова політика</a></li>
                        <li><a href="#">Політика публікації відгуків</a></li>
                    </ul>
                </nav>
            </div>
            <div class="content-static">
                <div class="delivery-page">
                    <div class="delivery">
                        <h3>Доставка</h3>
                        <div class="delivery-item d-md-flex align-items-start">
                            <div class="delivery-icon d-flex align-items-center justify-content-center">
                                <VIcon icon="delivery-1" />
                            </div>
                            <div class="delivery-info">
                                <h4>Самовивіз з аптек мережі “КОНЕКС”</h4>
                                <p>Самовивіз товару з аптек мережі КОНЕКС<br />Ви маєте можливість оформити замовлення на товар, який зацікавив вас на нашому сайті та забрати свою покупку в <a href="#">найближчій аптеці мережи</a>. <br />При оформленні замовлення оператор контакт-центру eTabletka.com уточнює зручне для Вас місце і час приїзду за покупкою. Доставка нашого замовлення до найближчої аптеки мережі здійснюється безкоштовно*.</p>
                            </div>
                        </div>
                        <div class="delivery-item d-md-flex align-items-start">
                            <div class="delivery-icon d-flex align-items-center justify-content-center">
                                <img src="/images/delivery-2.png" alt="">
                            </div>
                            <div class="delivery-info">
                                <h4>“Нова пошта” (кур’єр та відділення)</h4>
                                <ul class="design">
                                    <li>Доставка у відділення «Нова пошта» - 35грн</li>
                                    <li>Доставка кур'єром «Нова пошта» - 55грн</li>
                                </ul>
                                <p>Терміни доставки до відділення «Нова пошта» 1 - 3 дні. </p>
                                <p>Після відправки замовлення ви отримаєте SMS-повідомлення з номером експрес-накладної. </p> <br />
                                <p>Уточнити конкретну дату отримання вашого замовлення ви можете на сайті компанії «Нова пошта» </p>
                                <p>Єдина вартість доставки в будь-яке відділення «Нова пошта»: при замовленні на суму до 1000 грн - 50 грн, від 1000 грн - 0,01 грн. </p>
                                <p>В обов'язковому порядку перевіряйте ваше замовлення на відповідність з товарним чеком перед оплатою у відділенні «Нова пошта». </p>
                                <p>Важливо! <br />Перед відправкою весь товар перевіряється і страхується за повною вартістю. При отриманні відправлення вам необхідно перевірити товар безпосередньо у відділенні компанії "Нова пошта" в присутності співробітника. У разі неповного комплекту, недостачі товару або відсутності документів (чек, гарантійний талон і т.д.), Необхідно скласти акт невідповідності. Якщо упаковка пошкоджена, або на товарі виявлені механічні пошкодження вам необхідно відмовитися від відправлення або пред'явити претензії компанії "Нова пошта". Компанія "Нова пошта" відшкодовує завдані збитки, якщо їх було завдано з вини компанії. </p>
                            </div>
                        </div>
                        <div class="delivery-item d-md-flex align-items-start">
                            <div class="delivery-icon d-flex align-items-center justify-content-center">
                                <img src="/images/delivery-3.png" alt="">
                            </div>
                            <div class="delivery-info">
                                <h4>До відділення «Укрпошта»</h4>
                                <p>Термін доставки до відділення «Укрпошта» 1 - 3 дні.<br /> Після відправки замовлення ви отримаєте SMS-повідомлення з номером експрес-накладної. <br /> Єдина вартість доставки в будь-яке відділення «Укрпошта» — 1 копійка або 0,01 грн.</p>
                                <p>Увага! Доставка до відділення «Укрпошта» доступна для замовлень на суму не більше 15 000 грн. </p>
                                <p>В обов'язковому порядку перевіряйте ваше замовлення на відповідність з товарним чеком перед оплатою у відділенні «Укрпошта». </p>
                                <p>Важливо! <br />Перед відправкою весь товар перевіряється і страхується за повною вартістю. При отриманні відправлення вам необхідно перевірити товар безпосередньо у відділенні "Укрпошта" в присутності співробітника. У разі неповного комплекту, недостачі товару або відсутності документів (чек, гарантійний талон і т.д.), Необхідно скласти акт невідповідності. Якщо упаковка пошкоджена, або на товарі виявлені механічні пошкодження вам необхідно відмовитися від відправлення або пред'явити претензії компанії "Укрпошта". Компанія "Укрпошта" відшкодовує завдані збитки, якщо їх було завдано з вини компанії. </p>
                                <p>Відслідкувати відправлення ви можете на сайті Укрпошти <a href="#">тут</a> »</p>
                            </div>
                        </div>
                        <div class="delivery-item d-md-flex align-items-start">
                            <div class="delivery-icon d-flex align-items-center justify-content-center">
                                <VIcon icon="delivery-4" />
                            </div>
                            <div class="delivery-info">
                                <h4>Адресна доставка кур`єром</h4>
                                <p>Тариф за доставку замовлень курьєром складає 55 грн. <br /><span class="important">Безкоштовна доставка замовлень на сумму від 500 грн!</span></p>
                                <div class="delivery-citys">
                                    <div class="delivery-citys-title">Досупно в містах</div>
                                    <ul class="design cols-3">
                                        <li>Вінниця;</li>
                                        <li>Хмельницьк;</li>
                                        <li>Чернівецьк;</li>
                                        <li>Черкаси;</li>
                                        <li>Кіровоград;</li>
                                        <li>Житомир;</li>
                                        <li>Івано-Франківськ;</li>
                                        <li>Київ;</li>
                                        <li>Рівне;</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pay">
                        <h3>Оплата</h3>
                        <div class="delivery-item d-md-flex align-items-start">
                            <div class="delivery-icon d-flex align-items-center justify-content-center">
                                <VIcon icon="delivery-5" />
                            </div>
                            <div class="delivery-info">
                                <h4>Оплата карткою онлайн</h4>
                                <div class="text-custom blue">При оплаті карткою на сайті доставка Новою поштою безкоштовна!</div>
                                <div class="text-custom red">Звертаємо вашу увагу, при наявності в замовленні рецептурних препаратів, а також для окремих точок самовивозу, оплата картою може бути недоступна.</div>
                                <div class="small-title">Переваги оплати онлайн</div>
                                <ul class="design">
                                    <li>Безпечно</li>
                                    <li>Відсутність комісій</li>
                                    <li>Не потрібно витрачати час на похід в банкомат</li>
                                </ul>
                                <p>Для вибору оплати товару за допомогою банківської карти на на відповідній сторінці в способах оплати необхідно вибрати «Оплата на сайті, LiqPay (Visa, MasterCard)».</p>
                                <p>Оплата відбувається через АТ КБ «ПРИВАТБАНК» з використанням випущених в Україні банківських карт наступних платіжних систем:</p>
                                <div class="pay-icons d-md-flex align-items-center">
                                    <img src="/images/visa.png" alt="">
                                    <img src="/images/master.png" alt="">
                                </div>
                                <p>Для оплати (введення реквізитів Вашої картки) Ви будете перенаправлені на платіжний шлюз LiqPay. З'єднання з платіжним шлюзом і передача інформації здійснюється в захищеному режимі з використанням протоколу шифрування SSL. У разі якщо Ваш банк підтримує технологію безпечного проведення інтернет-платежів Verified By Visa, MasterCard SecureCode, для проведення платежу також може знадобитися введення спеціального пароля.</p>
                                <p>Справжній сайт підтримує 256-бітове шифрування. Конфіденційність інформації, що повідомляється персональної інформації забезпечується АТ КБ «ПРИВАТБАНК». Введена інформація не буде надана третім особам за винятком випадків, передбачених законодавством України. Проведення платежів за банківськими картками здійснюється в суворій відповідності до вимог платіжних систем Visa Int., MasterCard Europe Sprl.</p>
                                <p>У разі скасування замовлення, повернення коштів на банківський рахунок зазвичай відбувається протягом 2-3 робочих днів, але іноді повернення може йти до 30 днів. Термін залежить від банку, який видав вашу банківську карту.</p>
                            </div>
                        </div>
                        <div class="delivery-item d-md-flex align-items-start">
                            <div class="delivery-icon d-flex align-items-center justify-content-center">
                                <VIcon icon="delivery-6" />
                            </div>
                            <div class="delivery-info">
                                <h4>Оплата при отриманні</h4>
                                <p>Оплата замовлення і доставки здійснюється безпосередньо кур'єру або касиру в аптеці при отриманні замовлення. Разом з товаром покупець отримує фіскальний документ: касовий чек.</p>
                                <p>Копії сертифікатів і ліцензій надаються за попереднім запитом.</p>
                                <p>Щоб оплатити замовлення при отриманні, при оформленні замовлення виберіть спосіб оплати «Оплатити в аптеці» (для самовивозу), або «Накладений платіж» (для доставки).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Delivery"
}
</script>

<style scoped>

</style>
