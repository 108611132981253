<template>
    <div :class="'text-'+status" class="modal-body">
        {{ message }}
    </div>
</template>

<script>
export default {
    name: "ResponseMessage",
    props: {
        status: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        }
    },
    computed: {}
}
</script>

<style scoped>

</style>