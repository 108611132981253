<template>
    <div>
        BrandList

        {{brands}}
    </div>
</template>

<script>
export default {
    name: "BrandList",
    props: {
        brands: {},
    },
}
</script>
