<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>

            <inertia-link v-for="category in categories" :key="category.id" :href="category.url">
                {{ category.name }}
            </inertia-link>

            <span>{{ product.name }}</span>
        </div>
        <div class="h1-containter d-lg-flex align-items-end" id="h1-container">
            <h1>{{ product.name }}</h1>
        </div>
        <div class="catalog-detail" id="catalog-detail">
            <div class="detail-top d-lg-flex align-items-center justify-content-between">
                <div
                    class="detail-top__left d-flex align-items-center justify-content-between justify-content-md-start">
                    <div class="raiting-container d-flex align-items-center">

                        <rating-stars v-if="product.rating" :rating="product.rating" />
                        <a href="#" class="raiting-chats d-flex align-items-center" tabindex="0">
                            <span class="value">{{ product.reviews_count }} відгуків</span>
                        </a>
                    </div>
                    <div class="d-flex">

                        <add-to-wishlist :product="product" />

<!--                        <add-to-aid v-if="$store.state.user.isLoggedIn" :product="product" />-->

                    </div>
                </div>
                <div class="detail-articul">
                    Артикул товару: <span class="semibold">{{ product.vendor_code }}</span>
                </div>
            </div>
            <div class="detail-info" id="info">
                <div class="detail-nav">
                    <ul class="nav">
                        <template v-for="tab in tabs">
                            <li v-if="!tab.hidden">
                                <a @click.prevent="switchTab(tab)"
                                   :href="tab.href || `#${tab.id}`"
                                   :class="{active: activeTab === tab.id || !activeTab, act: activeTab === tab.id || !activeTab}">{{
                                       tab.title
                                   }}</a>
                            </li>
                        </template>

                    </ul>
                </div>
                <div class="detail-reviews d-flex align-items-start">
                    <div class="detail-reviews-inner">
                        <h3>Відгуки {{ product.name }}</h3>
                        <product-review-info :summary="summary" />
                        <div class="reviews-items">
                            <product-review-item v-for="review in reviews" :review="review" :key="'ri'+review.id" />

                        </div>
                        <div class="detail-reviews-form">
                            <add-review :model="product" :parent_id="0" :disabled="submitted"
                                        @submitted="submitted = true" />

                        </div>
                    </div>
                    <div class="detail-reviews-product d-none d-lg-block">
                        <div class="catalog-list">
                            <product-info-card :product="product" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import { defineAsyncComponent } from "vue";
import { usePopLoader } from "../../scrollToTop";

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'catalog-detail');
const RatingStars = defineAsyncComponent(() => import("@/frontend/Components/Common/RatingStars.vue"));
const AddToWishlist = defineAsyncComponent(() => import("@/frontend/Components/Common/AddToWishlist.vue"));
//const AddToAid = defineAsyncComponent(() => import("@/frontend/Components/Common/AddToAid.vue"));
const ProductReviewInfo = defineAsyncComponent(() => import("@/frontend/Components/Product/ProductReviewInfo.vue"));
const ProductReviewItem = defineAsyncComponent(() => import("@/frontend/Components/Product/ProductReviewItem.vue"));
const ProductInfoCard = defineAsyncComponent(() => import("@/frontend/Components/Product/ProductInfoCard.vue"));
const AddReview = defineAsyncComponent(() => import("@/frontend/Components/Common/AddReview.vue"));
</script>

<script>
export default {
    name: "ProductReviews",
    props: {
        product: Object,
        categories: Array,
        reviews: Array,
        summary: Object,
    },

    data() {
        return {
            submitted: false,
            toCartQty: 1,
            selectedCity: 0,
            deliveryCity: 0,
            storeSearchQuery: '',
            remnants: {
                total: 0,
                data: [],
                links: [],
            },
            tabs: [
                {id: 'all', title: 'Все про товар', href: this.product.url},
                {
                    id: 'instruction',
                    title: 'Інструкція',
                    href: this.product.url + '#instruction'
                },
                {
                    id: 'characteristics',
                    title: 'Властивості',
                    href: this.product.url + '#characteristics'
                },
                {
                    id: 'reviews',
                    title: 'Відгуки' + (this.product.reviews_count ? `(${this.product.reviews_count})` : '')
                },
                {
                    id: 'questions',
                    title: 'Запитання' + (this.product.questions_count ? `(${this.product.questions_count})` : ''),
                    href: this.product.url + '#questions',
                    hidden: true
                },
                {
                    id: 'stores',
                    title: 'Наявність в аптеках' + (this.product.remnants_count ? `(${this.product.remnants_count})` : ''),
                    href: this.product.url + '#stores'
                },
                {
                    id: 'analogs',
                    title: 'Аналоги та замінники',
                    href: this.product.url + '#analogs',
                    hidden: true
                },
            ],
            activeTab: 'reviews',
            timeout: null
        }
    },
    methods: {
        switchTab(tab) {
            if (tab.href) {
                this.$inertia.get(tab.href);
            } else {
                this.activeTab = tab.id
            }

        },
    }
}
</script>

<style scoped>

</style>
