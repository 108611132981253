const state = {
    errors: {},
}

const getters = {
}

const actions = {
}

const mutations = {
    SET_ERROR: (state, data = {}) => {
        state.errors = {...state.errors, ...data};
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}