<template>
    <div class="personal-page">
        <div class="personal-container">
            <div class="personal-information">
                <div class="personal-info-top d-md-flex align-items-center justify-content-between">
                    <div class="personal-info">
                        <personal-name :fill-percent="fillPercent" />
                        <profile-cashback v-if="false" />
                    </div>
                    <div class="personal-info-text">
                        <span
                            v-if="false">Заповніть ваші дані, що в подальшому вам допоможе в оформленні замовлень</span>
                    </div>
                </div>
                <div class="personal-form">
                    <form @submit.prevent="profileSubmit" action="" class="form">
                        <div class="row d-block d-md-flex gutters-32">
                            <div class="col">
                                <div class="personal-title">Персональна інформація</div>

                                <form-input v-model="profileForm.first_name"
                                            :error="errors.first_name"
                                            :label="$t('Ім’я')"
                                            placeholder="Ім’я"
                                            :disabled="isViewState"
                                            :maxlength="30"
                                />

                                <form-input v-model="profileForm.last_name"
                                            :error="errors.last_name"
                                            :label="$t('Прізвище')"
                                            placeholder="Прізвище"
                                            :disabled="isViewState"
                                            :maxlength="30"
                                />

                                <form-input v-model="profileForm.second_name"
                                            :error="errors.second_name"
                                            :label="$t('По-батькові')"
                                            placeholder="По-батькові"
                                            :disabled="isViewState"
                                            :maxlength="30"
                                />

                                <div class="input-container">
                                    <label>Дата народження</label>
                                    {{ profileForm.date_of_birth }}
                                    <div class="row gutters-6" v-if="!isViewState">
                                        <div class="col-3 col-md-3">
                                            <model-list-select v-model="birth.day"
                                                               :list="dateOptions.day"
                                                               option-value="value"
                                                               option-text="text">
                                            </model-list-select>
                                        </div>
                                        <div class="col-5 col-md-5 col-lg-6">
                                            <model-list-select v-model="birth.month"
                                                               :list="dateOptions.month"
                                                               option-value="value"
                                                               option-text="text">
                                            </model-list-select>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-3">
                                            <model-list-select v-model="birth.year"
                                                               :list="dateOptions.year"
                                                               option-value="value"
                                                               option-text="text">
                                            </model-list-select>
                                        </div>
                                    </div>
                                </div>

                                <form-input v-model="profileForm.phone"
                                            :error="errors.phone"
                                            input-class="phone"
                                            :label="$t('Номер телефону')"
                                            placeholder="Номер телефону"
                                            :is-phone="'+38 (099) 999-99-99'"
                                            :disabled="true"
                                            :maxlength="30"
                                >
                                    <a @click.prevent href="#" class="phone-edit" v-if="false">Запит на зміну телефону</a>
                                </form-input>

                                <form-input v-model="profileForm.email"
                                            :error="errors.email"
                                            input-class="email"
                                            :label="$t('Email')"
                                            :disabled="isViewState"
                                            placeholder="Email"
                                />
                            </div>
                            <div class="col">
                                <div class="personal-title">Адреса доставки</div>
                                <div class="input-container">
                                    <label>Ваше місто</label>
                                    <model-list-select
                                        v-model="profileForm.city_id"
                                        :list="cities"
                                        :class="isViewState ? 'disabled' : ''"
                                        option-value="id"
                                        option-text="name"
                                    />
                                </div>

                                <form-input v-model="profileForm.street"
                                            :error="errors.street"
                                            :label="$t('Назва вулиці')"
                                            :disabled="isViewState"
                                            placeholder="Назва вулиці"
                                            minlength="1"

                                />

                                <div class="row gutters-6">
                                    <div class="col">
                                        <form-input v-model="profileForm.house"
                                                :error="errors.house"
                                                :label="$t('Будинок')"
                                                :disabled="isViewState"
                                                placeholder="Будинок"
                                                minlength="1"
                                        />
                                    </div>

                                    <div class="col">
                                        <form-input v-model="profileForm.apartment"
                                                :error="errors.apartment"
                                                placeholder="Квартира"
                                                :label="$t('Квартира')"
                                                :disabled="isViewState"
                                                minlength="1"
                                        />
                                    </div>

                                    <div class="col">
                                        <form-input v-model="profileForm.floor"
                                                    :error="errors.floor"
                                                    :wrapper-class="'poverh-container'"
                                                     placeholder="Поверх"
                                                    :label="$t('Поверх')"
                                                    :disabled="isViewState"
                                                    minlength="1"
                                                    maxlength="3"
                                                    @keypress="isNumber($event)"
                                        >
                                            <button @click.prevent="updateFloorValue(1)" class="poverh-but up" v-if="!isViewState">
                                                <span class="ic icon-ic_arrow"></span>
                                            </button>
                                            <button @click.prevent="updateFloorValue(-1)" class="poverh-but down" v-if="!isViewState">
                                                <span class="ic icon-ic_arrow"></span>
                                            </button>
                                        </form-input>
                                    </div>
                                </div>

                                <form-input v-model="profileForm.comment"
                                            type="textarea"
                                            :error="errors.comment"
                                            placeholder="Коментар"
                                            :label="$t('Коментар')"
                                            :disabled="isViewState"
                                />
                            </div>
                            <div class="col-12">
                                <div class="password-container d-flex align-items-left">
                                    <div class="personal-title">Пароль профілю</div>
                                    <a href="#" @click.prevent="changePassword()" class="edit-password">
                                        Змінити пароль
                                    </a>
                                </div>
                            </div>
                        </div>
                        <button class="button submit save">
                            {{isViewState ? 'Редагувати' : 'Зберегти зміни'}}
                            <VIcon height="20" width="20" v-if="$store.state.success" icon="CheckBox" />
                        </button>
                    </form>
                </div>
                <div v-if="false" class="personal-pay d-md-flex align-items-start justify-content-between">
                    <div class="personal-pay-text">
                        <div class="pay-title">Платіжні данні</div>
                        <div class="value">Швидше додайте свою картку, щоби більше не витрачати час на введення даних вручну та можливості створювати підписки на препарати</div>
                    </div>
                    <div class="personal-pay-info">
                        <div class="card-container">
                            <div class="card-top d-flex align-items-center justify-content-between">
                                <div class="card-title">Номер картки</div>
                                <div class="card-logo"><img src="/images/card.png" class="lozad" alt=""></div>
                            </div>
                            <div class="card-form">
                                <form @submit.prevent="addCard" action="" class="form">
                                    <div class="row gutters-10">
                                        <div class="col-12">
                                            <form-input v-model="cardForm.number"
                                                        :error="errors.number"
                                                        :input-class="'card'"
                                                        :placeholder="'1234 1234 1234 1234'"
                                                        :mask="'9999 9999 9999 9999'"
                                            />
                                        </div>
                                        <div class="col-6 col-md-7">
                                            <form-input v-model="cardForm.date"
                                                        :error="errors.date"
                                                        :input-class="exp"
                                                        :placeholder="'ММ/РР'"
                                                        :mask="'99/99'"
                                            />
                                        </div>
                                        <div class="col-6 col-md-5">
                                            <form-input v-model="cardForm.cvv"
                                                        :error="errors.cvv"
                                                        type="password"
                                                        :input-class="cvv"
                                                        :placeholder="'CVV/CVC2'"
                                                        maxlength="3"
                                            />
                                        </div>
                                        <div class="col-12 col-md-7">
                                            <div class="pay-garanty">
                                                <img src="/images/pci.png" alt="" class="lozad">
                                                Ми гарантуємо безпеку оплати
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-5">
                                            <button class="button submit">Додати</button>
                                        </div>
                                        <div class="col-12">
                                            <div class="pay-politika">
                                                Додаючи платіжну картку, ви приймаєте умови <a href="#">угоди користувача</a>. Для валідації картки буде знята і відразу повернута мінімальна сума.
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <table v-if="false" class="table">
                    <tr>
                        <th>№</th>
                        <th>Номер картки</th>
                        <th>Дата</th>
                        <th>CVV</th>
                        <th>Основна</th>
                        <th></th>
                    </tr>
                    <tr v-for="(card, i) in bankCards">
                        <td>{{ i+1 }}</td>
                        <td>{{ card.number }}</td>
                        <td>{{ card.date }}</td>
                        <td>{{ card.cvv }}</td>
                        <td>
                            <span v-if="card.is_main" class="ic icon-star"></span>
                        </td>
                        <td>
                            <button @click="setMainCard(card.id)" :title="$t('Зробити основною')"><span class="ic icon-edit"></span></button>

                            <button @click="deleteCard(card.id)" :title="$t('Видалити')"><span class="ic icon-delete"></span></button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="personal-action" v-if="false">
            <img src="/images/action-4.jpg" alt="" class="lozad">
        </div>
    </div>
</template>

<script setup>
import { defineAsyncComponent } from "vue";
import { useRobotNoIndexFollow } from "../metaTags"
import { usePopLoader } from "../../scrollToTop";

const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));
const PersonalName = defineAsyncComponent(() => import("@/frontend/Components/Profile/PersonalName.vue"));
const ProfileCashback = defineAsyncComponent(() => import("@/frontend/Components/Profile/ProfileCashback.vue"));

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
useRobotNoIndexFollow()
</script>

<script>
import "vue-search-select/dist/VueSearchSelect.css";
import ProfileLayout from "./ProfileLayout.vue";

import { ModelListSelect } from "vue-search-select";
import { nextTick } from 'vue';

import _ from "lodash";

export default {
    components: {
        ModelListSelect,
    },
    layout: ProfileLayout,
    props: {
        errors: {
            type: [Array, Object]
        },
        bankCards: {
            type: [Array, Object]
        },
        fillPercent: {
            type: [Object, Number, String]
        },
        address: {},
        dateOptions: {},
    },
    data() {
        return {
            cardForm: {
                number: '',
                date: '',
                cvv: '',
            },
            birth: {
                day: '',
                month: '',
                year: '',
            },
            profileForm: {
                first_name: this.$store.state.user.profile?.first_name,
                second_name: this.$store.state.user.profile?.second_name,
                last_name: this.$store.state.user.profile?.last_name,
                date_of_birth: this.$store.state.user.profile?.date_of_birth,
                phone: this.$store.state.user.profile?.phone,
                email: this.$store.state.user.profile?.email,
                city_id: this.$store.state.user.profile?.address?.city_id,
                street: this.$store.state.user.profile?.address?.street,
                house: this.$store.state.user.profile?.address?.house,
                apartment: this.$store.state.user.profile?.address?.apartment,
                floor: this.$store.state.user.profile?.address?.floor,
                comment: this.$store.state.user.profile?.address?.comment,
            },
            isViewState: true
        }
    },
    computed: {
        cities() {
            return this.$store.state.location.listCities;
        },
    },
    watch: {
        birth: {
            deep: true,
            handler(birth){
                birth.day = ('0'+birth.day).slice(-2)
                birth.month = ('0'+birth.month).slice(-2)
                birth.year = birth.year || '0000'
                this.profileForm.date_of_birth = [birth.day, birth.month, birth.year].join('.')
            }
        },
    },
    mounted() {
        this.$store.dispatch('location/getCityWithStores', { limit: 200, listOnly: true });

        let matches = this.$store.state.user.profile?.date_of_birth?.match(/([\d+]{2})\.([\d+]{2})\.([\d+]{4})/) || ['', '', '', ''];

        this.birth.day = matches[1] || '';
        this.birth.month = matches[2] || '';
        this.birth.year = matches[3] || '';

        nextTick(() => {
            if (this.address) {
                for (const key in this.address) {
                    this.profileForm[key] = this.address[key];
                }
            }
        });
    },
    methods: {
        addCard(){
            this.$inertia.post(this.route('frontend.profile.add-card'), this.cardForm, {
                preserveScroll: false,
                only: ['bankCards']
            })
        },
        setMainCard(id){
            if(confirm(this.$t('Ви впевнені?'))) {
                this.$inertia.post(this.route('frontend.profile.set-main-card'), {id}, {
                    preserveScroll: false,
                    only: ['bankCards']
                })
            }
        },
        deleteCard(id){
            if(confirm(this.$t('Ви впевнені?'))){
                this.$inertia.delete(this.route('frontend.profile.delete-card', id), {
                    preserveScroll: false,
                    only: ['bankCards']
                })
            }
        },
        profileSubmit() {
            if(this.isViewState) {
                this.isViewState = false;
                return;
            }

            const self = this;

            this.$inertia.post(this.route('frontend.profile.info.post'), this.profileForm, {
                preserveScroll: false,
                // only: ['user'],
                onSuccess(e) {
                    self.isViewState = true;
                },
            })
        },
        changePassword() {
            this.$inertia.modal(this.route('frontend.profile.password.index'));
        },
        isNumber(event) {
            if (!isNaN(parseInt(event?.key))) return true;
            event.preventDefault();
        },
        updateFloorValue(incrementValue = 1) {
            if(this.isViewState) return;

            this.profileForm.floor = Math.max(
                (parseInt(this.profileForm.floor) || 1) + incrementValue, 1
            );
        }
    }
}
</script>

<style scoped lang="scss">
.personal-info {
    max-width: 100% !important;
    width: 100%;
}
.personal-avatar {
  flex-direction: column;
   margin: 15px auto;
   .avatar-title {
     margin-bottom: 10px;
     font-weight: 600;
     font-size: 22px;
     line-height: 130%;
     color: #212E42;
   }
}
</style>
