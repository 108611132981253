<template>
    <div class="container-fluid" id="main-container">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>{{ pageData.title }}</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>{{ pageData.seo_h1 || pageData.title }}</h1>
        </div>
        <div class="content-container d-lg-flex align-items-start">
            <div class="menu-left-container">
                <nav class="menu-left">
                    <button class="button-left d-flex d-lg-none align-items-center justify-content-center collapsed" data-toggle="collapse" href="#menu-left" role="button" aria-expanded="false" aria-controls="menu-left">
                        <span class="but-icon"></span>
                        <span class="value">{{ sidebarTitle }}</span>
                    </button>
                    <ul class="collapse" id="menu-left">
                        <li v-for="page in sidebarMenu">
                            <inertia-link :href="page.url" :class="{active: isActive(page)}">
                                {{ page.text }}
                            </inertia-link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="content-static">
                <div class="faq-page">
                    <div class="faq-title">Якщо ви не знайшли відповідь на ваше запитання, <br />поставте його нашим фахівцям</div>
                    <button class="button add-faq" @click.prevent="askQuestion()">Поставити запитання</button>
                    <div class="faq-items accordion" id="accordionFaq">
                        <FaqItem v-for="faqItem in items" :key="'faq-item-'+faqItem.id" :faq-item="faqItem" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import {defineAsyncComponent} from "vue";
import { usePopLoader } from '../../scrollToTop.js';

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
const FaqItem = defineAsyncComponent(() => import("../../Components/Page/FaqItem.vue"))
</script>

<script>

export default {
    name: "Faq",
    props: {
        mainImage: Object,
        pageData: Object,
        items: [Array, Object]
    },
    methods: {
        isActive(page) {
            return typeof document !== "undefined" && page.url === document.location.pathname;
        },
        askQuestion() {
            this.$inertia.modal('/ask_provisor')
        },
    },
    computed: {
        sidebarTitle() {
            return this.$page.props.layout?.menus?.sidebar?.title || this.$store.state.layout.menus?.sidebar?.title || 'Меню';
        },
        sidebarMenu() {
            return this.$page.props.layout?.menus?.sidebar?.items || this.$store.state.layout.menus?.sidebar?.items || [];
        }
    }
}
</script>

<style scoped>

</style>
