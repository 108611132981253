import axios from "axios";
import { proxyToArray } from "../../utils/array";

const state = {
    initMap: false,
    anotherPoints: [],
    searchPoint: {
        lat: 0,
        lng: 0,
    },
    regions: [],
    cities: [],
    markets: [],
    remnantsMarkets: [],
}

const getters = {
    getInitMap: state => state.initMap,
    getClusterInfo: state => state.anotherPoints.filter(point => point.id === 1)[0],
    getMeInfo: state => state.anotherPoints.filter(point => point.id === 2)[0],
    getSearchPoint: state => state.searchPoint,
    getMarkets: state => proxyToArray(state.markets),
    getMarketsForCity: (state) => (id) => state.markets.filter(item => item.city_id === id),
    getRemnantsMarkets: state => proxyToArray(state.remnantsMarkets),
    getCities: state => proxyToArray(state.cities),
    getRegions: state => proxyToArray(state.regions),
    getRegionById: (state) => (id)  => state.regions.filter(item => item.id === id)[0],
    getRegionIds: (state) => {
        return [...new Set(state.cities.map(city => city.region_id))];
    },
    getCitiesByRegionId: (state) => (regionId) => state.cities.filter((city) => city.region_id === regionId).map((city) => city.id),
    getCityCenter: (state) => (regionId) => state.cities.filter((city) => city.region_id === regionId && city.status === 3)[0],
}

const actions = {
    async getPoints({commit}, city) {
        let data = {}
        if (city) {
            data.city = city.id
        }
        await axios.post(this.$inertia.route('frontend.stores.another-points'), data, { hideLoader: true })
            .then(({ data }) => {
                commit('GET_ANOTHER_POINTS', data.points)
            })
    },
    async getMarkets({commit},) {
        await axios.post(this.$inertia.route('frontend.stores.all'), {}, { hideLoader: true })
            .then(({ data }) => {
                commit('GET_MARKETS', data.markets)
                commit('GET_CITIES', data.cities)
                commit('GET_REGIONS', data.regions)
            })
    },
    async getRemnantsMarkets({commit}, {product, city}) {
        await axios.get(`/product/${product.slug}/${city.id}/remnants?paginate=false`, { hideLoader: true })
            .then(({ data }) => {
                commit('REMNANTS_MARKETS', data.remnants)
            })
    },
    async getPointByAddress({commit}, { value, city = null}) {
        let url = 'https://api.visicom.ua/data-api/5.0/uk/geocode.json?key=4f3718ef392a8d103bbbc5507443fc68&ci=adr_street'
        if (city?.name) {
            url += `&n=${city.lng},${city.lat}&wt=${city.name}&t=${city.name} ${value}`
        } else {
            url += `&t=${value}`
        }

        await axios.get(url)
            .then((res) => {
                // console.log(res?.data?.features)
                if (res?.data?.geo_centroid) {
                    commit('SET_SEARCH_POINT', res.data.geo_centroid)
                    return
                }
                if (res?.data?.features) {
                    commit('SET_SEARCH_POINT', res.data.features[0]?.geo_centroid)
                    return
                }
            })
    },
    resetPointByAddress({commit}) {
        commit('RESET_SEARCH_POINT')
    },
    initMapStatus({commit}, value) {
        commit('INIT_MAP', value)
    },
}

const mutations = {
    INIT_MAP(state, data) {
        state.initMap = data
    },
    GET_ANOTHER_POINTS(state, data) {
        state.anotherPoints = proxyToArray(data)
    },
    GET_MARKETS(state, data) {
        state.markets = proxyToArray(data)
    },
    GET_CITIES(state, data) {
        state.cities = proxyToArray(data)
    },
    GET_REGIONS(state, data) {
        state.regions = proxyToArray(data)
    },
    REMNANTS_MARKETS(state, data) {
        state.remnantsMarkets = proxyToArray(data)
    },
    SET_SEARCH_POINT(state, data) {
        state.searchPoint.lat = data.coordinates[1]
        state.searchPoint.lng = data.coordinates[0]
    },
    RESET_SEARCH_POINT(state) {
        state.searchPoint = {
            lat: 0,
            lng: 0,
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}