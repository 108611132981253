<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Кошик</span>
        </div>
        <div class="order-page">
            <div class="h1-containter d-lg-flex align-items-end">
                <h3>Ми піклуємось про вас і просимо перевірити правильність вказаних даних в замовленні</h3>
            </div>

            <div v-if="Object.keys(errors).length" class="alert alert-danger">
                <div v-for="error in errors">
                    {{ error }}
                </div>

            </div>

            <div class="order-inner">
                <form @submit.prevent="submit" action="" class="form">
                    <div class="d-xl-flex align-items-start">
                        <div class="order-info order-12">
                            <div class="order-info-header d-flex align-item-center justify-content-between">
                                <div class="title">Ваше замовлення</div>
                            </div>
                            <div class="order-items">

                                <div v-for="item in $store.state.cart.items" class="order-item d-flex align-items-start">

                                    <a :href="$store.getters['cart/getProduct'](item, 'url')" class="item-image">
                                        <img :src="$store.getters['cart/getProduct'](item, 'image_url')" alt="" class="lozad">
                                    </a>

                                    <div class="item-info">
                                        <a :href="$store.getters['cart/getProduct'](item, 'url')" class="item-name">
                                            {{ $store.getters['cart/getProduct'](item, 'name') }}
                                        </a>
                                        <div class="d-flex align-item-center justify-content-between">
                                            <div class="item-quant">{{ item.qty }} шт.</div>
                                            <div class="item-price">{{ $store.getters['cart/getSubTotal'](item) }} грн</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="order-prices">
                                <!--                                <div class="prices-item d-flex align-items-center justify-content-between">-->
                                <!--                                    <div class="data">Знижка з урахування кешбеку:</div>-->
                                <!--                                    <div class="value">24 грн</div>-->
                                <!--                                </div>-->
                                <div class="prices-item d-flex align-items-center justify-content-between">
                                    <div class="data">Сума замовлення:</div>
                                    <div class="value">{{ $store.getters['cart/getTotal'] }} грн</div>
                                </div>
                                <div class="prices-item d-flex align-items-center justify-content-between">
                                    <div class="data">Вартість доставки:</div>
                                    <div class="value">{{ deliveryType.min_price }} грн</div>
                                </div>
                                <div class="prices-item summ d-flex align-items-center justify-content-between">
                                    <div class="data">Загальна сума:</div>
                                    <div class="value">{{ getTotal }} грн</div>
                                </div>
                            </div>
                        </div>
                        <div class="order-form">
                            <div class="order-apply">
                                <div class="apply-info">
                                    <div class="info-item d-sm-flex align-item-start">
                                        <div class="data">Данні отримувача:</div>
                                        <div class="value">{{ recipient.firstname }} {{ recipient.lastname }}</div>
                                    </div>
                                    <div class="info-item d-sm-flex align-item-start">
                                        <div class="data">Номер телефону:</div>
                                        <div class="phone">{{ recipient.phone }}</div>
                                    </div>
                                    <div class="info-item d-sm-flex align-item-start">
                                        <div class="data">Email:</div>
                                        <div class="value">{{ recipient.email }}</div>
                                    </div>
                                    <div class="info-item d-sm-flex align-item-start">
                                        <div class="data">Оплата:</div>
                                        <div class="value">{{ paymentType.name }}</div>
                                    </div>
                                    <div class="info-item d-sm-flex align-item-start">
                                        <div class="data">Доставка</div>
                                        <div class="delivery">
                                            {{ deliveryType.name }}, {{ deliveryCity.name }}
                                            <span v-if="deliveryType.slug === 'pickup'">{{ deliveryStore.name }}</span>
                                            <span v-if="deliveryType.slug !== 'pickup' && sessionOrder.delivery.info">
                                                {{ sessionOrder.delivery.info }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="info-item d-sm-flex align-item-start">
                                        <div class="data">До сплати:</div>
                                        <div class="summ">{{ getTotal }} грн</div>
                                    </div>
                                    <div class="info-item d-sm-flex align-item-start" v-if="false">
                                        <div class="data">Регулярне замовлення</div>
                                        <div v-if="sessionOrder.regular" class="reg">Активовано</div>
                                        <div v-else>Ні</div>
                                    </div>
                                </div>
                                <div class="apply-bottom">
                                    <div class="checkbox">
                                        <div v-if="errors.confirm" class="input-container error">
                                            <div class="error">{{ errors.confirm }}</div>
                                        </div>
                                        <input v-model="form.confirm" type="checkbox" name="umovi" id="umovi">
                                        <label for="umovi">
                                            Підтверджуючи замовлення, я приймаю умови
                                            <a target="_blank" href="/page/ugoda-koristuvaca">угоди користувача</a>
                                            і договору
                                            <a target="_blank" :href="offerUrl">публічної оферти</a>
                                        </label>
                                    </div>
                                    <div class="d-sm-flex">
                                        <button class="button" type="submit" :disabled="!form.confirm">
                                            {{ $t('Підтвердити замовлення') }}
                                        </button>
                                        <inertia-link :href="route('frontend.order.index')"
                                                      class="d-flex align-items-center edit-order">
                                            <span class="ic icon-edit"></span>
                                            <span class="value">Редагувати</span>
                                        </inertia-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        errors: {
            type: Object,
        },
        sessionOrder: {
            type: Object,
        },
        deliveryType: {
            type: Object,
        },
        deliveryCity: {
            type: Object,
        },
        deliveryStore: {
            type: Object,
        },
        paymentType: {
            type: Object,
        },
        offerUrl: {
            type: String,
        },
    },
    data() {
        return {
            form: {
                confirm: false
            }
        }
    },
    created() {
        this.$store.commit('order/INIT', {
            errors: this.errors || {},
            order: this.sessionOrder,
        })
    },
    computed: {
        deliveryPrice() {
            return parseFloat(this.deliveryType?.min_price || 0)
        },
        getTotal() {
            return parseFloat(((this.$store.getters['cart/getTotal'] || 0) - (this.sessionOrder.cashback_used || 0) + (this.deliveryPrice || 0)).toFixed(2))
        },
        recipient() {
            return this.$store.getters['order/recipient'];
        }
    },
    methods: {
        submit(){
            this.$inertia.post(this.route('frontend.order.apply'), this.form)
        }
    }
}
</script>

<style scoped>

</style>
