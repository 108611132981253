import {createStore} from 'vuex'
import cart from './cart'
import catalog from './catalog'
import layout from './layout'
import location from './location'
import markets from './markets'
import order from './order'
import user from './user'
import utility from './utility'
import wishlist from './wishlist'

const store = createStore({

    modules: {
        cart,
        catalog,
        layout,
        location,
        markets,
        order,
        user,
        utility,
        wishlist,
    },

    state() {
        return {
            loading: false,
            locale: null,
            city: null,
            cities: [],
            languages: [],
            menuCatalog: [],
            settings: {
                phones: [],
                emails: [],
                work_time: '',
                cashback: 0,
                soc_links: [],
                widget_options: {
                    header_banner_text: '',
                    header_banner_url: '',
                    ask_provisor_howto_page: '',
                    order_by_reciept_howto_page: '',
                }
            },
        }
    },

    getters: {
        toRawPhone(state) {
            return phone => phone.replace('+38', '').replace(/[\D\W]+/g, '')
        },
    },

    mutations: {
        setLoading(state, value) {
            state.loading = value
        },
        setLocale(state, value) {
            state.locale = value
        },
        setCity(state, value) {
            state.city = value
        },
        setCities(state, value) {
            state.cities = value
        },
        setSettings(state, value) {
            state.settings = value
        },
        setLanguages(state, value) {
            state.languages = value
        },
        setMenuCatalog(state, value) {
            state.menuCatalog = value
        },
    },

    actions: {
        setLocale(context, locale) {
            this.$inertia.get(this.$inertia.route('frontend.set-locale', locale))
        },
    }
})

export default store
