<template>
    <div class="wrapper">

        <slot name="before-header" />

        <AppHeader />

        <slot name="after-header" />

        <div ref="app-content" class="content">
            <slot name="before-content" />

            <slot />

            <slot name="after-content" />
        </div>


        <slot name="before-footer" />

        <AppFooter />

        <slot name="after-footer" />

        <AppBackground :loading="loading"/>

        <AppModal ref="app-modal" />
    </div>
</template>

<script setup>

import {useStore} from "vuex";
import {computed, defineAsyncComponent} from "vue";

//const AppHeader = defineAsyncComponent(() => import('./AppHeader.vue'))
const AppFooter = defineAsyncComponent(() => import('./AppFooter.vue'))
const AppBackground = defineAsyncComponent(() => import('./AppBackground.vue'))
const AppModal = defineAsyncComponent(() => import('./AppModal.vue'))

const store = useStore();

const loading = computed(() => store.state.loading || store.state.layout.bgOpen)
</script>

<script>
import AppHeader from './AppHeader.vue';
import {nextTick} from "vue";

export default {
    components: {
        AppHeader
    },
    data() {
        return {
            renderComponent: true,
        };
    },
    mounted() {
        // this.emitter.on("render-catalog", () => {
        //     this.$forceUpdate()
        //     nextTick(() => {
        //         window.scrollTo({
        //             bottom: 10,
        //             left: 0,
        //             behavior: "smooth",
        //         })
        //     })
        // });
    },
}
</script>
