<template>
    <header class="header">

        <HeaderBanner v-if="$store.state.settings.widget_options.header_banner_enable === 'true'"
                      :text="$store.state.settings.widget_options.header_banner_text"
                      :url="$store.state.settings.widget_options.header_banner_url"
        />

        <div class="container-fluid" id="header-container">
            <div class="header-top d-flex align-items-center justify-content-between">

                <inertia-link href="/" class="logo" rel="nofollow">
                    <img width="216" height="56" src="/img/logo.svg" alt="etabletka логотип">
                </inertia-link>

                <div class="nav-container d-flex align-items-center justify-content-between">
                    <MenuTop ul-class="nav align-items-center justify-content-between"/>
                </div>
                <div class="header-right d-flex align-items-center justify-content-end">
                    <LanguageDropdown v-if="false" />

                    <HeaderPhones />
                </div>
            </div>
        </div>

        <div ref="header-bottom" id="header-bottom" class="header-bottom">
            <div class="container-fluid">
                <div class="row gutters-10">
                    <div class="col-12 col-md-1 col-lg-1 col-xl-3">
                        <HeaderCatalog />
                    </div>
                    <div class="col-12 col-md-11 col-lg-11 col-xl-9">
                        <div class="header-bottom-right d-flex align-items-center justify-content-end">
                            <HeaderSearch />
                            <div class="header-icons d-flex align-items-center justify-content-between justify-content-md-end">
                                <template v-if="$store.state.user.isLoggedIn">
                                    <inertia-link v-if="$store.state.layout.width <= 767"
                                                :href="route('frontend.profile.index')"
                                                class="header-auth mr-4 d-flex align-items-center" rel="nofollow">
                                        <span class="ic ic-cashback">
                                            <v-icon icon="cashback" />
                                        </span>
                                        <span v-if="false" class="value">
                                            Кешбек: {{ $store.state.user.profile?.cashback_balance }} грн
                                        </span>
                                    </inertia-link>
                                    <inertia-link :href="route('frontend.profile.index')" class="header-auth d-flex align-items-center" rel="nofollow">
                                        <span class="ic icon-user-1"></span>
                                        <span class="value">Кабінет</span>
                                    </inertia-link>

                                    <inertia-link :href="route('frontend.profile.favorites')" class="header-favorites" rel="nofollow">
                                        <span class="ic icon-heart-straight-1"></span>
                                        <span v-if="$store.state.wishlist.products.length" class="quant">{{ $store.state.wishlist.products.length }}</span>
                                    </inertia-link>
                                </template>
                                <template v-else>
                                    <a
                                        @click.prevent="$inertia.modal(route('frontend.auth.login'))" href="#" rel="nofollow"
                                        class="header-auth d-flex align-items-center" 
                                    >
                                        <span class="ic icon-user-1"></span>
                                        <span class="value">Вхід<br>Реєстрація</span>
                                    </a>

                                    <a
                                        @click.prevent="$inertia.modal(route('frontend.auth.login'))" href="#" rel="nofollow"
                                        class="header-favorites" 
                                    >
                                        <span class="ic icon-heart-straight-1"></span>
                                        <span v-if="$store.state.wishlist.products.length" class="quant">{{ $store.state.wishlist.products.length }}</span>
                                    </a>
                                </template>
                                <HeaderCart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CityDropdown />
    </header>
</template>

<script setup>
import { defineAsyncComponent } from "vue";

const HeaderCart = defineAsyncComponent(() => import("../Header/HeaderCart.vue"));
const HeaderCatalog = defineAsyncComponent(() => import("../Header/HeaderCatalog.vue"));
const HeaderSearch = defineAsyncComponent(() => import("../Header/HeaderSearch.vue"));
const HeaderBanner = defineAsyncComponent(() => import("../Header/HeaderBanner.vue"));
const HeaderPhones = defineAsyncComponent(() => import("../Header/HeaderPhones.vue"));
const MenuTop = defineAsyncComponent(() => import("../Menu/MenuTop.vue"));
const CityDropdown = defineAsyncComponent(() => import("../Common/CityDropdown.vue"));
const LanguageDropdown = defineAsyncComponent(() => import("../Common/LanguageDropdown.vue"));
</script>

<script>
import { nextTick } from "vue";

export default {
    mounted() {
        this.emitter.on("render-catalog", () => {
            this.$forceUpdate();

            nextTick(() => {
                window.scrollTo(0, 10)
            });
        });
    }
}
</script>
