<template>
    <div class="personal-page">
        <div class="my-farmacy">
            <ul id="tabs-aptechka" class="nav tabs-ul">
                <li class="nav-item"><a href="" data-target="#e-aptechka" data-toggle="tab" class="nav-link active">еАптечка</a></li>
                <li class="nav-item"><a href="" data-target="#my-aptechka" data-toggle="tab" class="nav-link">Мої Аптечки</a></li>
                <li class="nav-item"><a href="" data-target="#subscribe" data-toggle="tab" class="nav-link">Підписки</a></li>
            </ul>
            <div class="tab-pane fade active show" id="e-aptechka">
                <div class="e-aptechka">
                    <div class="row gutters-11">
                        <aid-box v-for="aid in adminAids" :key="aid.id" :aid="aid" type="admin" />
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="my-aptechka">
                <div class="e-aptechka add">
                    <div class="row gutters-11">
                        <div class="col-12 col-md-6">
                            <div class="add-aptechka d-flex align-items-center justify-content-center">
                                <div class="add-inner">

                                    <template v-if="!aidFormOpen">
                                        <div class="add-icon"><VIcon icon="health" />
                                            <button @click.prevent="toggleAidForm" class="button-2 add-button">Створити власну</button>
                                        </div></template>

                                    <template v-if="aidFormOpen">
                                        <div class="image-file">
                                            <input @change="previewImage" type="file" style="display: none;">
                                        </div>
                                        <div class="add-icon">
                                            <img v-if="aidForm.image" :src="imagePreview" />
                                          <VIcon v-else icon="health"/>
                                        </div>
                                        <div class="row d-block d-md-flex gutters-32">
                                            <div class="col">
                                                <form-input v-model="aidForm.name"
                                                            :error="errors.name"
                                                            :label="$t('Назва')"
                                                />

                                                <button @click.prevent="addUserAid" class="button submit save">
                                                    Додати
                                                  <VIcon height="20" width="20" v-if="$store.state.success"
                                                         icon="CheckBox"/>
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>


                        </div>

                        <aid-box v-for="aid in userAids" :aid="aid" type="user" />

                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="subscribe">
                <div class="e-aptechka add">
                    <div class="row gutters-11">
                        <div class="col-12 col-md-6">
                            <div class="add-aptechka d-flex align-items-center justify-content-center">
                                <div class="add-inner">
                                    <div class="add-icon"><VIcon icon="health" />
                                        <button class="button-2 add-button">Створити власний список</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <subscription-block />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div></template>

<script>
import {defineAsyncComponent} from "vue";
import ProfileLayout from "./ProfileLayout.vue";

const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));
const SubscriptionBlock = defineAsyncComponent(() => import("@/frontend/Components/SubscriptionBlock.vue"));
const AidBox = defineAsyncComponent(() => import("@/frontend/Components/Aid/AidBox.vue"));

export default {
    components: {FormInput, SubscriptionBlock, AidBox},
    layout: ProfileLayout,
    props: {
        errors: {},
        adminAids: {},
        userAids: {},
    },
    data(){
        return {
            aidFormOpen: false,
            aidForm: {
                name: '',
                description: '',
                image: '',
            },
            imagePreview: '',
        }
    },

    methods: {
        toggleAidForm(){
            this.aidFormOpen = !this.aidFormOpen
        },
        addUserAid(){
            let vm = this
            let formData = new FormData();
            formData.append('name', this.aidForm.name);
            formData.append('description', this.aidForm.description);
            formData.append('image', this.aidForm.image);
            this.$inertia.post(this.route('frontend.profile.aids.add'), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onSuccess(){
                    vm.imagePreview = null
                    vm.aidFormOpen = false
                    vm.aidForm = {
                        name: '',
                        description: '',
                        image: '',
                    }
                }
            })
        },
        previewImage(event){
            this.aidForm.image = event.target.files[0]
            this.imagePreview = URL.createObjectURL(event.target.files[0])
        }
    }
}
</script>

<style scoped>
.image-file {
    display: block !important;
    width: 38px;
    height: 38px;
  background: #FFFFFF url('$svg/file.svg') no-repeat center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    position: absolute;
    z-index: 2;
    border-radius: 50px;
    cursor: pointer;
}

.image-file input {
    display: block !important;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 38px;
    font-size: 0;
}

.image-file input::-webkit-file-upload-button {
    cursor: pointer;
}

.add-icon {
    margin: 20px auto!important;
    max-height: 200px;
    overflow: hidden;
}
</style>
