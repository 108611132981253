<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Особистий кабінет</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h2><span class="name">{{ $store.state.user.profile?.name }}</span>, вітаємо в Особистому кабінеті!</h2>
        </div>
        <div class="content-container d-lg-flex align-items-start">

            <profile-side-menu ref="profile-menu" />

            <div class="personal-page">

                <slot></slot>

            </div>

        </div>
    </div>

</template>

<script>

import ProfileSideMenu from "../../Components/Profile/ProfileSideMenu.vue";

export default {
    components: {ProfileSideMenu},
    updated() {
        this.$refs['profile-menu'].$forceUpdate()
    }
}
</script>
