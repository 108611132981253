import axios from "axios";

export default {
    mounted() {
        $('.detail-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.gallery-thumbs-slider',
            dots: false,
            arrows: false,
            centerMode: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings:
                        {
                            dots: true,
                        }
                },
            ]

        });

        $('.gallery-thumbs-slider').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.detail-slider',
            dots: false,
            arrows: true,
            vertical: true,
            centerMode: false,
            focusOnSelect: true,
        });

        $('.more-about').click(function(){
            if (!$(this).data('status')) {
                $(this).html('Показати меньше');
                $(this).parent().addClass('open');
                $(this).data('status', true);
            }
            else {
                $(this).html('Показати більше');
                $(this).parent().removeClass('open');
                $(this).data('status', false);
            }
            return false;

        });

        if ($("#info").length){
            var $element = $('#info');
            let counter = 0;
            $(window).scroll(function() {
                var scroll = $(window).scrollTop() + $(window).height();

                var offset = $element.offset().top + $element.height();

                if (scroll > offset) {
                    $('.detail-fixed').addClass('active');

                    counter = 1;
                } else {
                    $('.detail-fixed').removeClass('active');

                }
            });
        }
    },
}
