import { router } from '@inertiajs/vue3'
import { serialize } from '../../utils/url';
import axios from "../bootstrap/axios";


const state = {
    filterLoading: false,
    category: {
        name: '',
        category_url: '',
        url: '',
    },
    parents: [],
    filters: {
        categories: [],
        discounts: [],
        brands: [],
        sell_types: [],
        characteristics: [],
        price: {
            min: 0,
            max: 0,
        },
    },
    brandSearch: '',
    characteristicSearch: {},
    sortOptions: [
        {value: 'default:asc', text: 'За замовчуванням'},
        {value: 'popular:desc', text: 'За популярністю'},
        {value: 'rating:desc', text: 'За рейтингом'},
        {value: 'name:asc', text: 'За назвою (А-Я)'},
        {value: 'name:desc', text: 'За назвою (Я-А)'},
        {value: 'price:asc', text: 'За зростанням ціни'},
        {value: 'price:desc', text: 'За зменшенням ціни'},
    ],
    filterForm: {
        q: '',
        categories: [],
        brands: [],
        discounts: [],
        badges: [],
        characteristics: {},
        price: {
            min: 0,
            max: 0,
        },
        sortBy: 'default:asc',
    },
    loadMoreItems:  16,
    paginator: {
        current_page: 1,
        last_page: 1,
        per_page: 15,
        total: 0,
        prev_page_url: null,
        next_page_url: null,
        links: [],
    },
    items: [],
}

const mutations = {
    SET_LOAD_MORE_ITEMS: (state, value) => state.loadMoreItems = value,
    SET_FILTER_LOADING: (state, value) => state.filterLoading = value,
    SET_FILTERS: (state, data = {}) => {
        state.filters = data;
    },
    SET_CATEGORY: (state, data = {}) => {
        state.category = data;
    },
    SET_PARENTS: (state, data = []) => {
        state.parents = data;
    },
    INIT_FILTER_FORM: (state, request = {}) => {
        let characteristics = {}
        let price = {
            min: request.price?.min || state.filters?.price?.min || 0,
            max: request.price?.max || state.filters?.price?.max || 0,
        }
        let characteristicSearch = {}
        for (let i in state.filters.characteristics) {
            let characteristic = state.filters.characteristics[i]
            characteristics[characteristic.id] = request.characteristics && request.characteristics[characteristic.id]
                ? request.characteristics[characteristic.id] : [];
            characteristicSearch[characteristic.id] = '';
        }
        state.filterForm = {
            q: request.q || '',
            categories: request.categories || [],
            brands: request.brands || [],
            badges: request.badges || [],
            sell_types: request.sell_types || [],
            characteristics: characteristics,
            discounts: request.discounts || [],
            price: price,
            sortBy: request.sortBy || 'default:asc',
        }
        state.characteristicSearch = characteristicSearch;
    },
    UPDATE_FILTER_FORM: (state, data) => {
        Object.assign(state.filterForm, data)
    },
    UPDATE_SORT_BY: (state, data) => {
        state.filterForm.sortBy = data
    },
    SET_PAGINATOR: (state, paginator) => {
        delete paginator.items;
        state.paginator = paginator;
    },
    SET_ITEMS: (state, items = []) => {
        state.items = items;
    },
    UPDATE_BRAND_SEARCH: (state, term) => {
        state.brandSearch = term;
    },
    UPDATE_CHARACTERISTIC_SEARCH: (state, data) => {
        Object.assign(state.characteristicSearch, data);
    }
}

const getters = {
    loadMoreItems: (state) => state.loadMoreItems || [],
    paginator: (state) => state.paginator || [],
    items: (state) => state.paginator?.data || [],
    paginatorLinks: (state) => state.paginator?.links || [],
    next_page_url: (state) => state.paginator?.next_page_url,
    current_page: (state) => state.paginator?.current_page,
    total: (state) => state.paginator?.total || 0,
    filterForm: (state) => {
        const form = {
            characteristics: state.filterForm.characteristics
        };
        if (state.filterForm.q) {
            form.q = state.filterForm.q;
        }
        if (state.filterForm.categories && state.filterForm.categories.length) {
            form.categories = state.filterForm.categories;
        }
        if (state.filters.price && (parseInt(state.filterForm.price.min) !== parseInt(state.filters.price?.min) || parseInt(state.filterForm.price.max) !== parseInt(state.filters.price.max))) {
            form.price = state.filterForm.price;
        }
        if (state.filterForm.brands && state.filterForm.brands.length) {
            form.brands = state.filterForm.brands;
        }
        if (state.filterForm.badges && state.filterForm.badges.length) {
            form.badges = state.filterForm.badges;
        }
        if (state.filterForm.discounts && state.filterForm.discounts.length) {
            form.discounts = state.filterForm.discounts;
        }
        if (state.filterForm.sortBy !== 'default:asc') {
            form.sortBy = state.filterForm.sortBy;
        }

        return form;
    }
}

const actions = {
    updateSortBy({commit}, data) {
        console.log("updateSortBy",data)
        commit('UPDATE_SORT_BY', data);
    },

    resetFilters({commit, dispatch}, payload = {}) {

        dispatch('loadFilters', payload);
        commit('INIT_FILTER_FORM', payload);
        dispatch('loadProducts');
    },

    loadProducts({commit, state, getters}, link) {
        commit('SET_LOAD_MORE_ITEMS',  16);
        let baseUrl = link?.url || link || state.category.url || document.location.pathname;
        //console.log({filters: param(getters.filterForm)});
        router.get(baseUrl, {filters: getters.filterForm}, {
            preserveScroll: false,
            preserveState: true,
            replace: false,
            only: ['products'],
            onSuccess: (page) => {
                commit('SET_ITEMS', page.props.products.data);
                commit('SET_PAGINATOR', page.props.products);
            }
        })
    },

    loadMore({commit, state, getters}) {
        if (getters.paginator) {
            const updatedUrl = getters.paginator?.first_page_url.replace(/(page=)[^&]*/, `$1${getters.paginator?.current_page}`);
            const perPage = getters.loadMoreItems + 16
            commit('SET_LOAD_MORE_ITEMS', perPage)
            router.get(updatedUrl, {filters: getters.filterForm, items: getters.loadMoreItems }, {
                preserveScroll: false,
                preserveState: true,
                replace: false,
                only: ['products'],
                onSuccess: (page) => {
                    commit('SET_ITEMS',  page.props.products.data)
                    commit('SET_PAGINATOR', page.props.products)
                }
            })
        }
    },

    async loadFilters({commit, state, getters}, requestFilters = {}) {
        requestFilters.category_id = state.category?.id || 0;
        requestFilters.sales = state.category?.sales || false;
        commit('SET_FILTER_LOADING', true);
        const response = await axios
            .get('/api/v1/catalog/filters?' + serialize(requestFilters))
            .catch(e => {
                console.log('Error load filters:', e.response);
                commit('SET_FILTER_LOADING', false);
            });

        if (response) {
            commit('SET_FILTERS', response.data || {});
            commit('INIT_FILTER_FORM', requestFilters)
            commit('SET_FILTER_LOADING', false)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
