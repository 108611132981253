<template>
    <app-head v-bind="$page.props.seo || {}" />

    <app-wrapper class="overflow-y-auto" scroll-region>
        <slot></slot>

        <slot name="after-header">

            <div v-if="$store.state.layout.alert.message" id="header-alerts" class="sticky">
                <div class="alert" :class="`alert-${$store.state.layout.alert.type}`"
                     v-html="$store.state.layout.alert.message">
                </div>
            </div>

        </slot>

        <div class="dev-block sticky" v-if="!isProd">
            <a class="link" target="_blank" href="/admin">admin</a>
        </div>

        <transition name="fade">
            <div id="pagetop" v-show="scY > 300" @click="toTop">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
                     stroke="#4a5568"
                     stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
                    <path d="M18 15l-6-6-6 6" />
                </svg>
            </div>
        </transition>
    </app-wrapper>
</template>

<script>
import AppWrapper from './Components/Layout/AppWrapper.vue'
import AppHead from "./Components/Layout/AppHead.vue";
import lozad from "lozad";

export default {
    name: 'MainApp',
    components: {
        AppHead,
        AppWrapper,
    },
    props: {},
    data() {
        return {
            scTimer: 0,
            scY: 0,
        }
    },
    computed: {

    },
    beforeCreate() {

    },
    mounted() {
        window.observer = lozad('.lozad', {});
        window.observer.observe();
        this.handleClicks()
        this.handleResize()
        this.handleScroll()
    },
    beforeUnMount() {
    },
    methods: {
        toTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        handleClicks(){
            if (typeof window !== "undefined") {
                const $ = jQuery = window.jQuery;
                $(".menu-button").on('click tap', function () {
                    $(this).toggleClass("is-active");
                    $(":root").find('.menu-top').toggleClass("open");
                    $(":root").find('body').toggleClass("menu-open");
                });
                $(".phone-mobile").on('click tap', function () {
                    $(this).parent().toggleClass("open");
                });
                $(".sel-apteka").on('click tap', function () {
                    $(this).toggleClass("active");
                });
                $(".cart-but").on('click tap', function () {
                    $(this).parent().toggleClass("open");
                });

                jQuery(function ($) {
                    $(document).mouseup(function (e) { // событие клика по веб-документу
                        var div = $(".header-cart"); // тут указываем ID элемента
                        if (!div.is(e.target) // если клик был не по нашему блоку
                            && div.has(e.target).length === 0) { // и не по его дочерним элементам
                            div.removeClass("open");
                        }
                    });
                });

                $(".nav-drop").on('click tap', function () {
                    $(this).parent().parent().toggleClass("open");
                    $(this).toggleClass("open");
                });


                // $(".city-inner-button").on('click tap', function (e) {
                //     e.preventDefault();
                //     $(":root").find('.city-dropdown').toggleClass("open");
                //     $(":root").find('body').toggleClass("city-open");
                //
                //     $(":root").find('.catalog-button .but-icon').removeClass("is-active");
                //     $(":root").find('.catalog-nav').removeClass("open");
                //     $(":root").find('body').removeClass("menu-open");
                // });

                $(document).on('click tap', ".city-inner-button", function (e) {
                    e.preventDefault();
                    $(":root").find('.city-dropdown').toggleClass("open");
                    $(":root").find('body').toggleClass("city-open");
                    $(":root").find('.catalog-button .but-icon').removeClass("is-active");
                    $(":root").find('.catalog-nav').removeClass("open");
                    $(":root").find('body').removeClass("menu-open");
                    return false;
                });

                $('.city-dropdown .city-item').on('click tap', function () {
                    let city = $(this).find('.value').text();
                    $(this).parent().parent().find('.active').removeClass('active');
                    $(this).addClass('active');
                    $(':root').find('.city .city-container').text(city);
                    $(':root').find('.city-dropdown').removeClass('open');
                    $(":root").find('body').removeClass("city-open");
                    if ($(".city-inner-button").length) {
                        $(':root').find('.city-inner-button .value').text(city);
                    }
                });

                $('.close-city').on('click tap', function () {
                    $(':root').find('.city-dropdown').removeClass('open');
                    $(":root").find('body').removeClass("city-open");
                });


                $(".pop-bg").on('click tap', function () {
                    if (!$(this).hasClass('loading')) {
                        $(":root").find('.catalog-button .but-icon').removeClass("is-active");
                        $(this).removeClass("open");
                        $(":root").find('.menu-catalog').removeClass("open");
                        $(":root").find('.catalog-nav').removeClass("open");
                        $(":root").find('body').removeClass("menu-open");
                        $(':root').find('.city-dropdown').removeClass('open');
                        $(":root").find('.catalog-filter').removeClass("open");
                    }
                });


                $('.slick-slider').on('beforeChange', function (e, {slideCount: c}, curr, next) {
                    observer.observe();
                });

            }
        },

        handleResize() {

        },

        handleScroll() {
            let vm = this
            let headerB = document.querySelector('.header-bottom'), wrp = null;
            window.addEventListener('scroll', Ascroll, { passive: true });
            document.body.addEventListener('scroll', Ascroll, { passive: true });
            function Ascroll() {
                // console.log('Ascroll')
                if (!headerB) return;
                const dropdown = document.querySelector('.city-dropdown');
                if (dropdown && dropdown.classList.contains('open')) {
                    let scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                    if (scrollPosition > 260) {
                        const buttonClose =  document.querySelector('.close-city')
                        buttonClose.click();
                    }
                }

                if (wrp == null) {
                    let Sa = getComputedStyle(headerB, '')
                    let s = '';
                    for (let i = 0; i < Sa.length; i++) {
                        if (Sa[i].indexOf('overflow') == 0 || Sa[i].indexOf('padding') == 0 || Sa[i].indexOf('border') == 0 || Sa[i].indexOf('outline') == 0 || Sa[i].indexOf('box-shadow') == 0 || Sa[i].indexOf('background') == 0) {
                            s += Sa[i] + ': ' + Sa.getPropertyValue(Sa[i]) + '; '
                        }
                    }
                    wrp = document.createElement('div');
                    wrp.style.cssText = s + ' box-sizing: border-box; width: ' + headerB.offsetWidth + 'px;';
                    headerB.insertBefore(wrp, headerB.firstChild);
                    let l = headerB.childNodes.length;
                    for (let i = 1; i < l; i++) {
                        wrp.appendChild(headerB.childNodes[i]);
                    }
                    headerB.style.height = wrp.getBoundingClientRect().height + 'px';
                    headerB.style.padding = '0';
                    headerB.style.border = '0';
                }

                const slider = document.querySelector('.main-slider');
                const sliderHeight = slider ? slider.offsetHeight : 0;

                if (headerB.getBoundingClientRect().top <= 0) {
                    wrp.className = 'sticky';
                } else {
                    wrp.className = '';
                }

                if (vm.$page.component === 'Home/Index') {
                    const menuCat = document.querySelector('#menu-catalog');

                    if (headerB.getBoundingClientRect().top + sliderHeight + 55 <= 0) {
                        document.body.classList.contains('main') && document.body.classList.toggle('main')
                        if (menuCat) menuCat.style.marginTop = '0px';
                    } else {
                        !document.body.classList.contains('main') && document.body.classList.toggle('main')
                        if (menuCat) menuCat.style.marginTop = (headerB.getBoundingClientRect().top > 0 ? 0 : headerB.getBoundingClientRect().top) + 'px';
                    }
                }

                if (vm.$page.component === "Ecommerce/Order") {
                    let scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
                    let lastElement = document.getElementById('footer')
                    if (lastElement) {
                        if (scrollPosition <= (lastElement.offsetTop-lastElement.offsetHeight) + 100) {
                            vm.emitter.emit('scroll-position', scrollPosition)
                        }
                    }
                }

                if (vm.$page.component === "Ecommerce/Product") {

                }

                window.addEventListener('resize', function () {
                    headerB.children[0].style.width = getComputedStyle(headerB, '').width
                }, false);
            }
        }
    }
}
</script>

<style>
    .dev-block{
    }
    .dev-block a{
        color: white;
    }
    #pagetop {
        position: fixed;
        bottom: 40px;
        right: 15px;
        cursor: pointer;
    }
</style>
