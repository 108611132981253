import emitter from 'tiny-emitter/instance'

function Bus(){
    return {
        $on: (...args) => emitter.on(...args),
        $once: (...args) => emitter.once(...args),
        $off: (...args) => emitter.off(...args),
        $emit: (...args) => emitter.emit(...args)
    }
}

export const EventBus = new Bus()

export const createEventBus = function (app, options){
    app.config.globalProperties.$eventBus = EventBus
}


export {EventBus as default}
