<template>
    <div class="error-page">
        <div class="items">
            <div class="code">{{ title }}</div>
            <div class="description">{{ description }}</div>
            <p>Радимо <a href="/" rel="nofollow">перейти на головну сторінку</a>  або скористатися пошуком вище</p>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({ status: Number })

// const title = computed(() => {
//     return {
//         503: '503: Service Unavailable',
//         500: '500: Server Error',
//         404: '404: Page Not Found',
//         403: '403: Forbidden',
//     }[props.status]
// })

const title = computed(() => {
  return props.status
})

const description = computed(() => {
    return {
        503: 'Sorry, we are doing some maintenance. Please check back soon.',
        500: 'Whoops, something went wrong on our servers.',
        404: 'Упс... Такої сторінки немає',
        // 404: 'Sorry, the page you are looking for could not be found.',
        403: 'Sorry, you are forbidden from accessing this page.',
    }[props.status]
})
</script>

<style scoped lang="scss">

</style>
