<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Відгуки про компанію</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>Відгуки про компанію</h1>
        </div>
        <div class="content-container d-lg-flex align-items-start">
            <div class="menu-left-container">
                <nav class="menu-left">
                    <button class="button-left d-flex d-lg-none align-items-center justify-content-center collapsed" data-toggle="collapse" href="#menu-left" role="button" aria-expanded="false" aria-controls="menu-left">
                        <span class="but-icon"></span>
                        <span class="value">Меню</span>
                    </button>
                    <ul class="collapse" id="menu-left">
                        <li><a href="#">Про компанію</a></li>
                        <li><a href="#">Доставка та оплата</a></li>
                        <li><a href="#">Наша команда</a></li>
                        <li><a href="#">Медичні експерти</a></li>
                        <li><a href="#">Контакти</a></li>
                        <li><a href="#" class="active">Відгуки про компанію</a></li>
                        <li><a href="#">Умови повернення</a></li>
                        <li><a href="#">Часті питання та відповіді</a></li>
                        <li><a href="#">Ліцензії</a></li>
                        <li><a href="#">Соціальні програми</a></li>
                        <li><a href="#">Редакційна політика</a></li>
                        <li><a href="#">Маркетингова політика</a></li>
                        <li><a href="#">Політика публікації відгуків</a></li>
                    </ul>
                </nav>
            </div>
            <div class="content-static">
                <div class="reviews-page">
                    <div class="reviews-buttons d-md-flex align-items-center justify-content-between">
                        <button class="button add-rev" data-toggle="modal" data-target="#modal-addreview">Залишити відгук</button>
                        <button class="d-flex add-skarga align-items-center"><span class="ic icon-skarga"></span><span class="value">Подати скаргу</span></button>
                    </div>
                    <div class="reviews-items">
                        <div class="review-item">
                            <div class="d-sm-flex align-items-center justify-content-between">
                                <div class="review-item__name">Юлія</div>
                                <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
                                    <rating-stars rating="3" />
                                    <div class="date">10 Травня 2021</div>
                                </div>
                            </div>
                            <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-sm-flex">
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                    <button class="more-answer" data-toggle="collapse" href="#answers-1" role="button" aria-expanded="false" aria-controls="answers-1"><span class="value">Показати відповіді</span> (2)</button>
                                </div>
                                <div class="review-likes d-flex align-items-center">
                                    <span class="dislike d-flex align-items-center">
                                        <span class="ic icon-diselike"><span class="path1"></span><span class="path2"></span></span>
                                        <span class="value">2</span>
                                    </span>
                                    <span class="like d-flex align-items-center">
                                        <span class="ic icon-like"><span class="path1"></span><span class="path2"></span></span>
                                        <span class="value">6</span>
                                    </span>
                                </div>
                            </div>
                            <div class="review-answers collapse" id="answers-1">
                                <div class="review-answers-item">
                                    <div class="review-answers-top d-sm-flex align-items-center justify-content-between">
                                        <div class="review-answers-name d-flex align-items-center">
                                            <div class="review-answers-icon">
                                                <VIcon icon="rev" />
                                            </div>
                                            <div class="review-answers-names">
                                                <div class="name">Представник компанії</div>
                                            </div>
                                        </div>
                                        <div class="date">10 Травня 2021</div>
                                    </div>
                                    <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                </div>
                            </div>
                        </div>
                        <div class="review-item">
                            <div class="d-sm-flex align-items-center justify-content-between">
                                <div class="review-item__name">Юлія</div>
                                <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
                                    <rating-stars rating="3" />
                                    <div class="date">10 Травня 2021</div>
                                </div>
                            </div>
                            <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-sm-flex">
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                    <button class="more-answer" data-toggle="collapse" href="#answers-2" role="button" aria-expanded="false" aria-controls="answers-1"><span class="value">Показати відповіді</span> (2)</button>
                                </div>
                                <div class="review-likes d-flex align-items-center">
                                    <span class="dislike d-flex align-items-center">
                                        <span class="ic icon-diselike"><span class="path1"></span><span class="path2"></span></span>
                                        <span class="value">2</span>
                                    </span>
                                    <span class="like d-flex align-items-center">
                                        <span class="ic icon-like"><span class="path1"></span><span class="path2"></span></span>
                                        <span class="value">6</span>
                                    </span>
                                </div>
                            </div>
                            <div class="review-answers collapse" id="answers-2">
                                <div class="review-answers-item">
                                    <div class="review-answers-top d-sm-flex align-items-center justify-content-between">
                                        <div class="review-answers-name d-flex align-items-center">
                                            <div class="review-answers-icon"><VIcon icon="rev" /></div>
                                            <div class="review-answers-names">
                                                <div class="name">Представник компанії</div>
                                            </div>
                                        </div>
                                        <div class="date">10 Травня 2021</div>
                                    </div>
                                    <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                </div>
                            </div>
                        </div>
                        <div class="review-item">
                            <div class="d-sm-flex align-items-center justify-content-between">
                                <div class="review-item__name">Юлія</div>
                                <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
                                    <rating-stars rating="3" />
                                    <div class="date">10 Травня 2021</div>
                                </div>
                            </div>
                            <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-sm-flex">
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                    <button class="more-answer" data-toggle="collapse" href="#answers-3" role="button" aria-expanded="false" aria-controls="answers-1"><span class="value">Показати відповіді</span> (2)</button>
                                </div>
                                <div class="review-likes d-flex align-items-center">
                                    <span class="dislike d-flex align-items-center">
                                        <span class="ic icon-diselike"><span class="path1"></span><span class="path2"></span></span>
                                        <span class="value">2</span>
                                    </span>
                                    <span class="like d-flex align-items-center">
                                        <span class="ic icon-like"><span class="path1"></span><span class="path2"></span></span>
                                        <span class="value">6</span>
                                    </span>
                                </div>
                            </div>
                            <div class="review-answers collapse" id="answers-3">
                                <div class="review-answers-item">
                                    <div class="review-answers-top d-sm-flex align-items-center justify-content-between">
                                        <div class="review-answers-name d-flex align-items-center">
                                            <div class="review-answers-icon"><VIcon icon="rev" /></div>
                                            <div class="review-answers-names">
                                                <div class="name">Представник компанії</div>
                                            </div>
                                        </div>
                                        <div class="date">10 Травня 2021</div>
                                    </div>
                                    <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                </div>
                            </div>
                        </div>
                        <div class="review-item">
                            <div class="d-sm-flex align-items-center justify-content-between">
                                <div class="review-item__name">Юлія</div>
                                <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
                                    <rating-stars rating="3" />
                                    <div class="date">10 Травня 2021</div>
                                </div>
                            </div>
                            <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-sm-flex">
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                    <button class="more-answer" data-toggle="collapse" href="#answers-4" role="button" aria-expanded="false" aria-controls="answers-1"><span class="value">Показати відповіді</span> (2)</button>
                                </div>
                                <div class="review-likes d-flex align-items-center">
                                    <span class="dislike d-flex align-items-center">
                                        <span class="ic icon-diselike"><span class="path1"></span><span class="path2"></span></span>
                                        <span class="value">2</span>
                                    </span>
                                    <span class="like d-flex align-items-center">
                                        <span class="ic icon-like"><span class="path1"></span><span class="path2"></span></span>
                                        <span class="value">6</span>
                                    </span>
                                </div>
                            </div>
                            <div class="review-answers collapse" id="answers-4">
                                <div class="review-answers-item">
                                    <div class="review-answers-top d-sm-flex align-items-center justify-content-between">
                                        <div class="review-answers-name d-flex align-items-center">
                                            <div class="review-answers-icon"><VIcon icon="rev" /></div>
                                            <div class="review-answers-names">
                                                <div class="name">Представник компанії</div>
                                            </div>
                                        </div>
                                        <div class="date">10 Травня 2021</div>
                                    </div>
                                    <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                </div>
                            </div>
                        </div>
                        <div class="review-item">
                            <div class="d-sm-flex align-items-center justify-content-between">
                                <div class="review-item__name">Юлія</div>
                                <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
                                    <rating-stars rating="3" />
                                    <div class="date">10 Травня 2021</div>
                                </div>
                            </div>
                            <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-sm-flex">
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                    <button class="more-answer" data-toggle="collapse" href="#answers-5" role="button" aria-expanded="false" aria-controls="answers-1"><span class="value">Показати відповіді</span> (2)</button>
                                </div>
                                <div class="review-likes d-flex align-items-center">
                                    <span class="dislike d-flex align-items-center">
                                        <span class="ic icon-diselike"><span class="path1"></span><span class="path2"></span></span>
                                        <span class="value">2</span>
                                    </span>
                                    <span class="like d-flex align-items-center">
                                        <span class="ic icon-like"><span class="path1"></span><span class="path2"></span></span>
                                        <span class="value">6</span>
                                    </span>
                                </div>
                            </div>
                            <div class="review-answers collapse" id="answers-5">
                                <div class="review-answers-item">
                                    <div class="review-answers-top d-sm-flex align-items-center justify-content-between">
                                        <div class="review-answers-name d-flex align-items-center">
                                            <div class="review-answers-icon"><VIcon icon="rev" /></div>
                                            <div class="review-answers-names">
                                                <div class="name">Представник компанії</div>
                                            </div>
                                        </div>
                                        <div class="date">10 Травня 2021</div>
                                    </div>
                                    <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="more-items d-inline-flex align-items-center">
                            <span class="value">Показати ще</span>
                            <span class="ic icon-more"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {defineAsyncComponent} from "vue";

const RatingStars = defineAsyncComponent(() => import("@/frontend/Components/Common/RatingStars.vue"));
export default {
    components: {RatingStars}
}
</script>

<style scoped>

</style>

