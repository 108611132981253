<template>
    <div class="order-city">

        <form-city-autocomplete v-model="query"
                                :label="$t('Ваше місто')"
                                placeholder="Оберіть місто"
                                :cities="cities"
                                required
                                @select="selectCity($event)"
        />


        <div class="city-results">
            <a href="#" v-if="userCity" class="result-city" @click.prevent="selectCity(userCity)">
                {{ userCity.name }}
            </a>
        </div>

    </div>
</template>

<script>
import _ from "lodash";
import FormCityAutocomplete from "../Form/FormCityAutocomplete.vue";

export default {
    name: "OrderDeliveryCity",
    components: { FormCityAutocomplete },
    props: {
        address: {
            type: [Array, Object]
        },
    },
    computed: {
        cities() {
            return this.$store.state.order.cities;
        },
        query: {
            get() {
                return this.$store.state.order.cityQuery;
            },
            set(value) {
                this.$store.commit('order/SET_CITY_QUERY', value);
            }
        },
        userCity() {
            return this.$store.state.city;
        },
        searchRequest() {
            return this.$store.state.order.cityRequest;
        },
    },
    mounted() {
        if (this.address.city_name) {
            this.selectCity({id: this.address.city_id, name: this.address.city_name})
        } else if (this.$store.state.city.name) {
            this.selectCity(this.$store.state.city)
        }
    },
    methods: {
        selectCity(city) {
            this.$store.commit('order/UPDATE_DELIVERY', { store: null });
            this.$store.commit('order/SET_CITY_QUERY', city.name || '')
            this.$store.commit('order/UPDATE_DELIVERY', { city: city })

            this.$store.dispatch('order/loadDeliveryTypes')

            const deliveryType = this.$store.state.order.delivery.type;
            if (deliveryType) {
                this.$store.dispatch('order/availableStores', {
                    cityId: city.id,
                    deliveryType: deliveryType,
                })
            }

            this.$store.dispatch('order/availableStores', {
                cityId: city.id,
            })
        },
    },
    watch: {
        query: _.debounce(function (value) {
            this.$store.dispatch('order/searchCities');
        }, 200),
    }
}
</script>

<style scoped>

</style>
