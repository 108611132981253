<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Акційні пропозиції</span>
        </div>
        <div class="action-detail ">
            <div class="h1-containter d-md-flex">
                <h1 class="h1-small">Від запалення нирок та циститу Канефрон за приємною ціною</h1>
            </div>
            <div class="action-container d-md-flex justify-content-between align-items-start">
                <div class="action-detail__image">
                    <img src="/images/action.jpg" alt="" class="lozad">
                </div>
                <div class="action-detail__info">
                    <div class="action-date d-inline-flex">Акція діє до 31 Травня 2021</div>
                    <div class="action-termin">
                        <div class="data ">До кінця акції :</div>
                        <div class="value">залишилось <span>7</span> днів</div>
                    </div>
                    <div class="action-share">
                        <div class="data">Поділитись акцією:</div>
                        <div class="value d-flex">
                            <a href="#" class="fb d-flex align-items-center justify-content-center"><span class="ic icon-facebook"></span></a>
                            <a href="#" class="tw d-flex align-items-center justify-content-center"><span class="ic icon-twitter"></span></a>
                            <a href="#" class="tg d-flex align-items-center justify-content-center"><span class="ic icon-telegram"><span class="path1"></span><span class="path2"></span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="catalog-slider__container">
            <div class="slider-top d-flex align-items-center justify-content-between">
                <div class="title small-title">Товари які беруть участь в акції</div>
                <div class="navs catalog-slider__navs d-flex"></div>
            </div>
            <div class="catalog-list">
                <div class="catalog-slider">
                    <div class="slide">
                        <div class="catalog-item">
                            <div class="shilds">
                                <div class="shild-action">Акція</div>
                            </div>
                            <button class="to-fav"><span class="ic icon-heart"></span></button>
                            <a href="#" class="item-image"><img src="/images/t6.png" alt="" class="lozad"></a>
                            <div class="raiting-container d-flex align-items-center">
                                <rating-stars rating="3" />
                                <a href="#" class="raiting-chats d-flex align-items-center">
                                    <VIcon icon="chat-text" />
                                    <span class="value">12</span>
                                </a>
                            </div>
                            <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                            <div class="buy-block d-flex align-items-end justify-content-between">
                                <div class="price-block">
                                    <div class="d-flex align-items-center">
                                        <div class="old-price"><span>300</span></div>
                                        <div class="sale">-15%</div>
                                    </div>
                                    <div class="price">1 322 <span>грн</span></div>
                                </div>
                                <button class="buy button">В кошик</button>
                            </div>
                            <div class="cashback">
                                Кешбек: <span class="value">+3.88 ₴</span>
                            </div>
                        </div>
                    </div>
                    <div class="slide">
                        <div class="catalog-item">
                            <div class="shilds">
                                <div class="shild-action">Акція</div>
                            </div>
                            <button class="to-fav active"><span class="ic icon-heart"></span></button>
                            <a href="#" class="item-image"><img src="/images/t7.png" alt="" class="lozad"></a>
                            <div class="raiting-container d-flex align-items-center">
                                <rating-stars rating="3" />
                                <a href="#" class="raiting-chats d-flex align-items-center">
                                    <VIcon icon="chat-text" />
                                    <span class="value">12</span>
                                </a>
                            </div>
                            <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                            <div class="buy-block d-flex align-items-end justify-content-between">
                                <div class="price-block">
                                    <div class="d-flex align-items-center">
                                        <div class="old-price"><span>300</span></div>
                                        <div class="sale">-15%</div>
                                    </div>
                                    <div class="price">1 322 <span>грн</span></div>
                                </div>
                                <button class="buy button">В кошик</button>
                            </div>
                            <div class="cashback">
                                Кешбек: <span class="value">+3.88 ₴</span>
                            </div>
                        </div>
                    </div>
                    <div class="slide">
                        <div class="catalog-item">
                            <div class="shilds">
                                <div class="shild-action">Акція</div>
                            </div>
                            <button class="to-fav"><span class="ic icon-heart"></span></button>
                            <a href="#" class="item-image"><img src="/images/t6.png" alt="" class="lozad"></a>
                            <div class="raiting-container d-flex align-items-center">
                                <rating-stars rating="3" />
                                <a href="#" class="raiting-chats d-flex align-items-center">
                                    <VIcon icon="chat-text" />
                                    <span class="value">12</span>
                                </a>
                            </div>
                            <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                            <div class="buy-block d-flex align-items-end justify-content-between">
                                <div class="price-block">
                                    <div class="d-flex align-items-center">
                                        <div class="old-price"><span>300</span></div>
                                        <div class="sale">-15%</div>
                                    </div>
                                    <div class="price">1 322 <span>грн</span></div>
                                </div>
                                <button class="buy button">В кошик</button>
                            </div>
                            <div class="cashback">
                                Кешбек: <span class="value">+3.88 ₴</span>
                            </div>
                        </div>
                    </div>
                    <div class="slide">
                        <div class="catalog-item">
                            <div class="shilds">
                                <div class="shild-action">Акція</div>
                            </div>
                            <button class="to-fav"><span class="ic icon-heart"></span></button>
                            <a href="#" class="item-image"><img src="/images/t7.png" alt="" class="lozad"></a>
                            <div class="raiting-container d-flex align-items-center">
                                <rating-stars rating="3" />
                                <a href="#" class="raiting-chats d-flex align-items-center">
                                    <VIcon icon="chat-text" />
                                    <span class="value">12</span>
                                </a>
                            </div>
                            <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                            <div class="buy-block d-flex align-items-end justify-content-between">
                                <div class="price-block">
                                    <div class="d-flex align-items-center">
                                        <div class="old-price"><span>300</span></div>
                                        <div class="sale">-15%</div>
                                    </div>
                                    <div class="price">1 322 <span>грн</span></div>
                                </div>
                                <button class="buy button">В кошик</button>
                            </div>
                            <div class="cashback">
                                Кешбек: <span class="value">+3.88 ₴</span>
                            </div>
                        </div>
                    </div>
                    <div class="slide">
                        <div class="catalog-item">
                            <div class="shilds">
                                <div class="shild-action">Акція</div>
                            </div>
                            <button class="to-fav"><span class="ic icon-heart"></span></button>
                            <a href="#" class="item-image"><img src="/images/t6.png" alt="" class="lozad"></a>
                            <div class="raiting-container d-flex align-items-center">
                                <rating-stars rating="3" />
                                <a href="#" class="raiting-chats d-flex align-items-center">
                                    <VIcon icon="chat-text" />
                                    <span class="value">12</span>
                                </a>
                            </div>
                            <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                            <div class="buy-block d-flex align-items-end justify-content-between">
                                <div class="price-block">
                                    <div class="d-flex align-items-center">
                                        <div class="old-price"><span>300</span></div>
                                        <div class="sale">-15%</div>
                                    </div>
                                    <div class="price">1 322 <span>грн</span></div>
                                </div>
                                <button class="buy button">В кошик</button>
                            </div>
                            <div class="cashback">
                                Кешбек: <span class="value">+3.88 ₴</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "ActionDetail"
}
</script>

<style scoped>

</style>
