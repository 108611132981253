<template>
    <div class="modal-form addrev">
        <div class="form-title feed">{{!!parent_id ? 'Напишіть свою відповідь' : 'Залиште ваш відгук'}}</div>
        <form @submit.prevent="submit" action="" class="form">

            <div v-if="!isProd">
                <a @click.prevent="fillForm" class="" href="#">Fill</a>

                {{ parent_id }}
            </div>

            <div v-if="!!!parent_id" class="raitings d-sm-flex align-items-center">
                <div class="raiting-title">Ваша оцінка:</div>
                <div class="add-raiting" ref="add-raiting">
                    <span v-for="i in 5" @click="setRating(i)" class="item icon-star"
                          :class="{set_active: form.rating >= i}"></span>
                </div>
            </div>

            <form-input v-model="form.name"
                        :error="errors.name"
                        :label="'Ваше ім’я'"
                        :required="true"
            />

            <form-input v-model="form.email"
                        :error="errors.email"
                        input-class="email"
                        type="email"
                        :label="'Ваш email'"
                        :required="form.notify"
            />

            <form-input v-model="form.text"
                        :error="errors.text"
                        input-class="textarea"
                        type="textarea"
                        :label="!!parent_id ? 'Ваша відповідь' : 'Ваш коментар'"
                        :required="true"
            />

            <div v-if="imagePreview && false" class="image-previews">
                <img :src="imagePreview">
            </div>

            <div v-if="false" class="add-file d-flex align-items-start">
                <span class="ic icon-file-2"></span>
                <div class="add-file-container">
                    <div class="file-title">Додати зображення</div>
                    <div class="file-anons">Перетягніть файли сюди чи натисніть на кнопку. Додавайте до 10 зображень в
                        форматах .jpg, .gif, .png, розміром файлу до 5 МБ
                    </div>
                    <div class="file-container">
                        <label class="file-label d-sm-flex align-items-center">
                            <div class="file-text">Додати файл</div>

                            <input @change="attachFile" class="input-file" type="file" name="data[file]"
                                   placeholder="Прикрепите файл" style="display: none;">
                            <div class="js-labelFile has-file">
                                <span class="js-fileName"></span>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="d-sm-flex align-items-center justify-content-between">
                <button class="submit button" :disabled="disabled">{{ !!parent_id ? 'Відповісти' : 'Залишити відгук'}}</button>
                <div class="checkbox">
                    <input type="checkbox" name="notify" v-model="form.notify" id="povidom">
                    <label for="povidom">Повідомляти про відповідь на електронну пошту</label>
                </div>
            </div>
        </form>
        <modal-message ref="messageRef">{{ message }}</modal-message>
    </div>

</template>

<script>
import { defineAsyncComponent } from "vue";

const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));
const ModalMessage = defineAsyncComponent(() => import("@/frontend/Components/ModalMessage.vue"));

export default {
    components: { ModalMessage, FormInput },
    props: {
        errors: {
            type: Object,
        },
        model: {
            type: Object,
        },
        type: {
            type: String,
            default: 'product'
        },
        parent_id: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        userName: {
            type: Object,
        }
    },
    emits: ['submitted'],
    data() {
        return {
            form: {
                model_type: this.type || null,
                model_id: this.model?.id || null,
                parent_id: this.parent_id || null,
                rating: null,
                notify: false,
                name: this.userName.name,
                email: this.userName.email,
                text: '',
                image: null,
            },
            imagePreview: null,
            message: ''
        }
    },
    methods: {
        setRating(rating) {
            this.form.rating = Math.ceil(rating)
        },
        attachFile(e) {
            let file = e.target.files[0] || false

            if (file) {
                this.form.image = file

                this.imagePreview = URL.createObjectURL(file)
            }
        },
        submit() {
            let formData = new FormData();

            formData.append('model_type', this.form.model_type);
            formData.append('model_id', this.form.model_id);
            formData.append('parent_id', this.form.parent_id);
            formData.append('name', this.form.name);
            formData.append('email', this.form.email);
            formData.append('text', this.form.text);
            formData.append('image', this.form.image);
            formData.append('notify', this.form.notify ? '1' : '0');
            if(this.form.rating) formData.append('rating', this.form.rating);

            this.$inertia.modal(this.route('frontend.review.store', [this.type, this.model.id]), 'post', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }, (resp) => {
                this.message = resp.message;
                this.$refs.messageRef.show();
                this.$emit('submitted');
            });
        },

        fillForm() {
            this.setRating(Number.randomInt(1, 5))
            this.form.name = this.$store.user?.name || 'Кіндрат'
            this.form.email = this.$store.user?.email || 'kindrat@email.com'
            this.form.text = String.random();
        }
    }
}
</script>
