<template>
    <div class="farmacy-item d-lg-flex align-items-start">

        <div class="item-image d-flex align-items-center">

            <add-to-wishlist :store="store" />

            <div class="image">
                <img :src="store.image_url" alt="" class="lozad">
            </div>
        </div>

        <div class="item-tds d-md-flex d-lg-block d-xl-flex align-items-start">
            <div class="item-name">
                <rating-stars v-if="store.rating" :rating="store.rating" />

                <div class="name">{{ store.name }}</div>
                <a href="#" class="adres">{{ store.address }}</a>

                <div class="item-title d-inline-flex align-items-center">
                    <span class="ic icon-time"></span>
                    <span class="value">Графік роботи</span>
                </div>
                <div class="item-cont">
                    <div class="itm">{{ store.work_time }}</div>
                </div>

                <div v-if="store.cashless" class="item-card d-inline-flex align-items-center">
                    <span class="ic icon-card"></span>
                    <span class="value">Безготівковий розрахунок</span>
                </div>
            </div>
            <div class="item-phones d-inline-block d-sm-block">
                <div class="item-title d-inline-flex align-items-center">
                    <span class="ic icon-phone"></span>
                    <span class="value">Консультація за телефоном</span>
                </div>

                <div class="item-cont">
                    <a v-for="contact in store.contacts.split('\n')" class="itm" :href="'tel:'+contact">{{ contact }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

const RatingStars = defineAsyncComponent(() => import("@/frontend/Components/Common/RatingStars.vue"));
const AddToWishlist = defineAsyncComponent(() => import("@/frontend/Components/Common/AddToWishlist.vue"));


export default {
    components: {AddToWishlist, RatingStars},
    props: ['store']
}
</script>
