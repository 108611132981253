<script>
import { defineAsyncComponent } from "vue";

const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));
const ModalMessage = defineAsyncComponent(() => import("@/frontend/Components/ModalMessage.vue"));

export default {
    name: "Password",
    components: { ModalMessage, FormInput },
    props: {
        errors: [Array, Object],
    },
    emits: ['submitted'],
    data() {
        return {
            message: '',
            form: {
                password: '',
                password_confirmation: '',
            },
            formValid: false
        }
    },
    watch: {
        'form.password'() {
            this.validatePasswords();
        },
        'form.password_confirmation'() {
            this.validatePasswords();
        }
    },
    methods: {
        submitForm() {
            if(this.formValid) {
                this.$inertia.modal(this.route('frontend.profile.password.update'), 'post', this.form, {}, (resp) => {
                    this.message = resp.message;
                    this.$refs.messageRef.show();
                    this.$emit('submitted');
                });
            }
        },
        validatePasswords() {
            let valid = !!this.form.password && !!this.form.password_confirmation;

            if (this.form.password.length < 8) {
                this.errors.password = 'Має бути мінімум 8 символів';
                valid = false;
            } else {
                delete this.errors.password;
            }

            if (this.form.password !== this.form.password_confirmation && !!this.form.password_confirmation) {
                this.errors.password = 'Пароль має співпадати';
                this.errors.password_confirmation = 'Пароль має співпадати';
                valid = false;
            } else {
                delete this.errors.password_confirmation;
                if (this.errors.password === 'Пароль має співпадати') {
                    delete this.errors.password;
                }
            }

            this.formValid = valid;
        }
    }
}
</script>

<template>
    <div class="modal-form">
        <div class="form-title feed">Придумайте новий пароль</div>

        <form @submit.prevent="submitForm" class="form">
            <form-input v-model="form.password"
                        :error="errors.password"
                        :placeholder="'Пароль'"
                        :required="true"
                        type="password"
            />
            <form-input v-model="form.password_confirmation"
                        :error="errors.password_confirmation"
                        :placeholder="'Повторіть пароль'"
                        :required="true"
                        type="password"
            />
            <button class="submit button" :disabled="!formValid">Зберегти</button>
        </form>
        <modal-message ref="messageRef">{{ message }}</modal-message>
    </div>
</template>

<style scoped>

</style>
