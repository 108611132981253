<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Про компанію</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>Про компанію</h1>
        </div>
        <div class="content-container d-lg-flex align-items-start">
            <div class="menu-left-container">
                <nav class="menu-left">
                    <button class="button-left d-flex d-lg-none align-items-center justify-content-center collapsed" data-toggle="collapse" href="#menu-left" role="button" aria-expanded="false" aria-controls="menu-left">
                        <span class="but-icon"></span>
                        <span class="value">Меню</span>
                    </button>
                    <ul class="collapse" id="menu-left">
                        <li><a href="#" class="active">Про компанію</a></li>
                        <li><a href="#">Доставка та оплата</a></li>
                        <li><a href="#">Наша команда</a></li>
                        <li><a href="#">Медичні експерти</a></li>
                        <li><a href="#">Контакти</a></li>
                        <li><a href="#">Відгуки про компанію</a></li>
                        <li><a href="#">Умови повернення</a></li>
                        <li><a href="#">Часті питання та відповіді</a></li>
                        <li><a href="#">Ліцензії</a></li>
                        <li><a href="#">Соціальні програми</a></li>
                        <li><a href="#">Редакційна політика</a></li>
                        <li><a href="#">Маркетингова політика</a></li>
                        <li><a href="#">Політика публікації відгуків</a></li>
                    </ul>
                </nav>
            </div>
            <div class="content-static">
                <p><img src="/images/about.jpg" alt="" class="lozad"></p>
                <p>КОНЕКС – одна з найбільших фармацевтичних компаній України. Наша професійна команда ось уже чверть століття допомагає людям бути здоровими.</p>
                <p>Колектив підприємства — це понад 1200 співробітників, майже 800 з яких мають фармацевтичну і медичну освіту високого рівня. </p>
                <div class="about-citys">
                    Аптечна мережа компанії налічує понад 160 аптек в Вінницькій, Хмельницькій, Чернівецькій, Черкаській, Кіровоградській, Житомирській, Івано-Франківській, Київській та Рівненській областях.
                </div>
                <p class="text-center"><img src="/images/map.png" alt="" class="lozad"></p>
                <h4>Основні напрямки діяльності:</h4>
                <ul class="design-2">
                    <li>оптова та роздрібна торгівля лікарськими засобами;</li>
                    <li>медична практика;</li>
                    <li>власне виробництво фітопродукції;</li>
                    <li>будівництво та оренда приміщень.</li>
                </ul>
                <p>В аптеках мережі КОНЕКС кожен покупець може завжди придбати необхідну продукцію. Достатній вміст і безперервний режим роботи складських приміщень дозволяє забезпечити великим асортиментом продукції не лише аптеки нашої компанії, а й оптових клієнтів: лікувальні заклади, аптеки, аптечні пункти в різних регіонах України.</p>
                <p>Для оптових клієнтів завжди оперативно і якісно працює команда менеджерів-професіоналів, які спеціалізуються на оптовій торгівлі лікарських засобів і товарів медичного призначення.</p>
                <p>Відділ логістики забезпечує своєчасність і стабільність постачань в будь-яку точку України.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "About"
}
</script>

<style scoped>

</style>
