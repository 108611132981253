<template>
    <div class="order-container">
        <div class="order-container-title">3. Спосіб оплати</div>
        <div class="order-radios">

            <div v-if="error" class="alert alert-danger error">
                {{ error }}
            </div>

            <template v-for="(payment, i) in paymentTypes" :key="i">

                <div class="radio">
                    <input v-model="payment_type" :value="payment.slug"
                           type="radio"
                           name="pay"
                           :id="`pay-${i}`"
                           :disabled="isDisabledPayment(payment) || isCodDisabled(payment)"
                           :checked="0 === i || paymentTypes.length === 1"
                           class="open-check">
                    <label :for="`pay-${i}`">
                        <span class="data">{{ payment.name }}</span>
                        <span class="value" v-if="isAvailablePayment(payment) && !isCodDisabled(payment)"
                              v-html="payment.description"></span>
                        <span class="value" v-if="availablePayments.indexOf(payment.slug) === -1">
                            Цей тип оплати не доступний для обраної аптеки
                        </span>
                        <span class="value text-danger" v-if="isCodDisabled(payment)">
                            Цей тип оплати доступний тільки у пунктах самовивозу
                        </span>
                        <span class="value text-danger" v-if="isPrescription && payment.slug !== 'cod'">
                            {{ payment.prescription_text }}
                        </span>
                    </label>
                </div>

                <payment-type-loan v-if="payment.slug === 'pb_loan'"
                                   :class="{collapse: payment_type !== payment.slug}" />

            </template>
        </div>
    </div>
</template>

<script>
import PaymentTypeLoan from "./PaymentTypeLoan.vue";

export default {
    name: "OrderPayment",
    components: { PaymentTypeLoan },
    mounted() {
        this.$store.commit('order/UPDATE_PAYMENT', {type: 'cod'});
    },
    computed: {
        deliveryType() {
            return this.$store.state.order.delivery.type
        },
        isPrescription() {
            return this.$store.getters['cart/isPrescription'];
        },
        productTypes() {
            return this.$store.getters['cart/productTypes'];
        },
        paymentTypes() {
            const paymentTypes = this.$store.state.order.paymentTypes;
            if (this.isPrescription) {
                return paymentTypes.filter(payment => payment.prescription === 1)
            }

            const productTypes = this.productTypes

            return paymentTypes.filter((item) => {
                if (item.config && item.config.product_types) {
                    return productTypes.every((type) => item.config.product_types.includes(type))
                }
                return false
            });
        },
        error() {
            return this.$store.state.order.errors['payment.type'] || this.$store.state.order.errors['payment.info'] || '';
        },
        payment_type: {
            get() {
                return this.$store.state.order.payment.type;
            },
            set(value) {
                this.$store.commit('order/UPDATE_PAYMENT', {type: value});
            }
        },
        selectedStore() {
            return this.$store.state.order.delivery.store;
        },
        availablePayments() {
            if (this.selectedStore) {
                return this.paymentTypes.filter(item => {
                    if (item.config && item.config.sell_types) {
                        return item.config.sell_types.includes(this.selectedStore.sell_type);
                    }
                    return false;
                }).map(pt => pt.slug);
            }
            return this.paymentTypes.map(pt => pt.slug);
        },
    },
    methods: {
        isAvailablePayment(payment) {
            return this.availablePayments.indexOf(payment.slug) !== -1 && !(this.isPrescription && payment.slug !== 'cod')
        },
        isDisabledPayment(payment) {
            if(this.deliveryType === 'courier-delivery') {
                return this.availablePayments.indexOf(payment.slug) === -1 && payment.slug === 'card'
            }
            return this.availablePayments.indexOf(payment.slug) === -1 || (this.isPrescription && payment.slug !== 'cod')
        },
        isCodDisabled(payment) {
            if(this.deliveryType === 'courier-delivery') {
                return payment.slug === 'card' && this.deliveryType && this.deliveryType === 'courier-delivery'
            }
            return payment.slug === 'cod' && this.deliveryType && this.deliveryType !== 'pickup'
        },
    },
}
</script>

<style scoped>

</style>
