<template>
    <div class="input-container autocomplete" :class="{focus: active}" v-click-outside="close">
        <label v-if="label" :class="labelClass">
            {{ label }}
            <span v-if="required">*</span>
        </label>
        <input ref="input"
               :value="modelValue"
               @input="$emit('update:modelValue', $event.target.value)"
               :name="name"
               :required="required"
               :placeholder="placeholder"
               :disabled="disabled"
               :class="inputClass"

               @focus="onFocus()"
               @blur="onBlur()"
        >
        <div class="list-group">
            <a href="#" @click.prevent="selectItem(city)" v-for="city in cities" class="list-group-item">
                {{ city.name }}
                <small v-if="city.region">({{ city.region }}, {{ city.district }})</small>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormCityAutocomplete",
    props: {
        modelValue: {},
        cities: Array,
        name: {
            type: String,
            default: null
        },
        required: Boolean,
        disabled: Boolean,
        placeholder: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        labelClass: {
            type: String,
            default: null
        },
        inputClass: {
            type: String,
            default: null
        },
    },
    emits: ['update:modelValue', 'focus', 'blur', 'select'],
    data() {
        return {
            active: false,
        }
    },
    methods: {
        onFocus() {
            this.active = true;
            this.$emit('focus');
        },
        onBlur() {
            if (!this.isCitySelected && this.modelValue) {
                this.$emit('update:modelValue', '');
            } else if (this.isCitySelected && this.modelValue !== this.deliveryCity) {
                this.$emit('update:modelValue', this.deliveryCity);
            }
            this.$emit('blur');
        },
        close() {
            this.active = false;
        },
        selectItem(city) {
            this.active = false;
            this.$emit('select', city);
        }
    },
    computed: {
        isCitySelected() {
            return this.$store.getters['order/deliveryCityId'] > 0;
        },
        deliveryCity() {
            return this.$store.getters['order/deliveryCity']?.name;
        }
    },
    watch: {
        cities() {
            if (this.$refs.input === document.activeElement && !this.active) {
                this.active = true;
            }
        }
    }
}
</script>

<style scoped>

</style>