<template>
    <div class="modal-form">
        <div class="form-title feed">Запитай професійного фармацевта</div>
        <form @submit.prevent="submit" action="" class="form">

            <form-input v-model="form.name"
                        :error="errors.name"
                        :label="'Ваше ім’я'"
                        :required="true"
            />

            <form-input v-model="form.email"
                        :error="errors.email"
                        input-class="email"
                        type="email"
                        :label="'Ваш email'"

            />

            <form-input v-model="form.phone"
                        :disabled="isLoggedIn"
                        :default-phone="form.phone"
                        :error="errors.phone"
                        :input-class="{ phone: true }"
                        :label="$t('Номер телефону')"
                        :is-add="true"
                        :is-phone="true"
                        :maxlength="19"
                        :required="true"

            />

            <form-input v-model="form.text"
                        :error="errors.text"
                        input-class="textarea"
                        type="textarea"
                        :label="'Ваш коментар'"
                        :required="true"
            />

            <button class="submit button">Задати питання</button>

        </form>

        <modal-message ref="messageRef">{{ message }}</modal-message>
    </div>

</template>

<script>
import {defineAsyncComponent} from "vue";

const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));
const ModalMessage = defineAsyncComponent(() => import("@/frontend/Components/ModalMessage.vue"));

export default {
    components: { ModalMessage, FormInput },
    props: {
        productId: [String, Number],
    },
    data() {
        return {
            form: {
                name: '',
                email: '',
                phone: '',
                text: '',
            },
            message: ''
        }
    },
    computed: {
        errors() {
            return this.$store.state.utility.errors;
        },
        isLoggedIn() {
            return this.$store.state.user.isLoggedIn;
        },
    },
    mounted() {
        if (this.isLoggedIn) {
            this.form.phone = this.$store.state.user?.profile.phone;
        }
    },
    methods: {
        submit(e){
            let formData = new FormData();

            formData.append('name', this.form.name);
            if (this.form.email) {
                formData.append('email', this.form.email);
            }
            if (this.form.phone) {
                formData.append('phone', this.form.phone.replace(/[()\-\s]/g, ''));
            }

            formData.append('text', this.form.text);
            formData.append('product_id', this.productId);

            this.$inertia.modal(this.route('frontend.ask_provisor.ask'), 'post', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }, (resp) => {
                this.message = resp.message;
                this.$refs.messageRef.show();
                this.$emit('submitted');
            })
        },
    }
}
</script>
