<template>
    <div class="order-container">
        <div class="order-container-title">2. Спосіб і адреса доставки</div>

        <div v-if="error" class="alert alert-danger error">
            {{ error }}
        </div>

        <order-delivery-city :address="address" />

        <div class="divider" v-if="isCitySelected"></div>

        <order-delivery-type v-if="isCitySelected" />

    </div>
</template>

<script>
import OrderDeliveryCity from "./OrderDeliveryCity.vue";
import OrderDeliveryType from "./OrderDeliveryType.vue";

export default {
    name: "OrderDelivery",
    components: { OrderDeliveryType, OrderDeliveryCity },
    props: {
        address: {
            type: [Array, Object]
        },
    },
    computed: {
        error() {
            return this.$store.state.order.errors.city || '';
        },
        isCitySelected() {
            return this.$store.getters['order/deliveryCityId'] > 0;
        },
    }
}
</script>

<style scoped>

</style>
