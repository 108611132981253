<template>
    <div class="modal-form addrev">
        <div class="form-title feed">Швидка покупка</div>

        <inertia-link :href="product.url" class="item-name">{{ product.name }}</inertia-link>


        <form @submit.prevent="submit" action="" class="form">

            <div v-if="!isProd">
                <a @click.prevent="fillForm" class="" href="#">Fill</a>

                {{ parent_id }}
            </div>

            <form-input v-model="form.name"
                        :error="errors.name"
                        :label="'Ваше ім’я'"
                        :required="true"
            />

            <form-input v-model="form.phone"
                        :error="errors.phone"
                        :input-class="'phone'"
                        :label="'Ваш Телефон'"
                        :mask="'+38 (099) 999-99-99'"
                        placeholder="+38 (0"
                        :required="true"
            />

            <div class="d-sm-flex align-items-center justify-content-between">
                <button class="submit button">Оформити замовлення</button>
            </div>
        </form>
    </div>

</template>

<script>
import {defineAsyncComponent} from "vue";

const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));

export default {
    name: "FastOrder",
    components: {FormInput},
    props: {
        errors: {},
        product: {},
    },
    data(){
        return {
            form: {
                name: '',
                phone: '',
            },
        }
    },
    mounted() {

    },

    methods: {
        setRating(rating) {
            this.form.rating = Math.ceil(rating)
        },
        submit(e) {
            let vm = this
            let formData = new FormData();

            formData.append('name', this.form.name);
            formData.append('phone', this.form.phone);

            this.$inertia.modal(this.route('frontend.product.fast-order.store', this.product.slug), 'post', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
        },

        fillForm() {
            this.setRating(Number.randomInt(1, 5))
            this.form.name = this.$store.user?.name || 'Кіндрат'
            this.form.phone = this.$store.user?.phone || '0999999999'
        }
    }
}
</script>

<style scoped>

</style>
