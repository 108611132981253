<template>
    <div class="container-fluid" id="main-container">
        <div class="main-top">
            <div class="row gutters-10">
                <div class="col-12 col-md-12 col-xl-9 offset-xl-3">

                    <slick-slider :slider="slider" />

                    <main-recept v-if="mainReceiptEnable" />

                </div>
            </div>
        </div>

        <banner-list v-if="middleBannersEnable" :banners="middleBanners" />

        <product-carousel-new v-if="popularProductsEnable && !isMobile"
            :title="$t('Популярні товари')"
            :products="popularProducts"
            :show-all-button="route('frontend.catalog.popular')"
        />

        <products-mobile
            v-if="popularProductsEnable && isMobile"
            :title="$t('Популярні товари')"
            :products="popularProducts"
            :show-all-button="route('frontend.catalog.popular')"
        />

<!--        <product-list v-if="discountProductsEnable"-->
<!--            :title="$t('Товари зі знижкою')"-->
<!--            :products="discountProducts"-->
<!--            :show-all-link="route('frontend.catalog.sales.all')"-->
<!--        />-->

        <question-block v-if="askProvisorEnable" />

        <product-carousel-new v-if="recommendedProductsEnable && !isMobile"
            :title="$t('Акційні пропозиції')"
            :products="randomSaleProducts"
            :show-all-button="route('frontend.catalog.sales.all')"
        />

        <products-mobile
            v-if="recommendedProductsEnable && isMobile"
            :title="$t('Акційні пропозиції')"
            :products="randomSaleProducts"
            :show-all-button="route('frontend.catalog.sales.all')"
        />

        <review-carousel v-if="latestReviewsEnable" :reviews="latestReviews" />

        <blog-widget v-if="blogWidgetEnable" :blog-posts="blogPosts" />

        <doctor-reviews v-if="doctorReviewsEnable" :reviews="provisorReviews" />

        <brand-carousel v-if="popularBrandsEnable" :brands="popularBrands" />

        <div class="main-bottom">
            <div class="main-bottom__inner">
                <div class="container-fluid">

                    <store-search v-if="searchStoreEnable" />

                    <store-icons v-if="storeIconsEnable" />

                    <store-info v-if="storeInfoEnable" />
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>

import { computed, defineAsyncComponent, onMounted, ref } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useStore } from "vuex";

const ProductsMobile = defineAsyncComponent(() => import("../../Components/Widgets/ProductsMobile.vue"));
const ReviewCarousel = defineAsyncComponent(() => import("../../Components/ReviewCarousel.vue"));
const ProductCarouselNew = defineAsyncComponent(() => import("../../Components/Widgets/ProductCarouselNew.vue"));
const StoreInfo = defineAsyncComponent(() => import("../../Components/Store/StoreInfo.vue"));
const StoreIcons = defineAsyncComponent(() => import("../../Components/Store/StoreIcons.vue"));
const StoreSearch = defineAsyncComponent(() => import("../../Components/Store/StoreSearch.vue"));
const BrandCarousel = defineAsyncComponent(() => import("../../Components/BrandCarousel.vue"));
const DoctorReviews = defineAsyncComponent(() => import("../../Components/DoctorReviews.vue"));
const BlogWidget = defineAsyncComponent(() => import("../../Components/Widgets/BlogWidget.vue"));
const QuestionBlock = defineAsyncComponent(() => import("../../Components/QuestionBlock.vue"));
const ProductList = defineAsyncComponent(() => import("../../Components/ProductList.vue"));
const BannerList = defineAsyncComponent(() => import("../../Components/BannerList.vue"));
const SlickSlider = defineAsyncComponent(() => import("../../Components/Slider/SlickSlider.vue"));
const MainRecept = defineAsyncComponent(() => import("../../Components/MainRecept.vue"));

const props = defineProps({
    slider: {
        type: Object,
    },
    middleBanners: {
        type: Object,
    },
    sideBanners: {
        type: Object,
    },
    popularProducts: {
        type: Object,
    },
    discountProducts: {
        type: Object,
    },
    recommendedProducts: {
        type: Object,
    },
    randomSaleProducts: {
        type: Object,
    },
    popularBrands: {
        type: Object,
    },
    latestReviews: {
        type: Object,
    },
    provisorReviews: {
        type: Object,
    },
    blogPosts: [Object, Array]
})

const page = usePage();
const store = useStore();

const isMobile = ref(page.props?.isMobile);

const widgetOptions = computed(() => page.props?.settings?.widget_options || store.state?.settings?.widget_options || {})
// const discountProductsEnable = computed(() => widgetOptions.value.discount_product_enable === 'true')
const popularProductsEnable = computed(() => widgetOptions.value.popular_product_enable === 'true')
const middleBannersEnable = computed(() => widgetOptions.value.middle_banners_enable === 'true')
const mainReceiptEnable = computed(() => widgetOptions.value.order_by_reciept_enable === 'true')
const latestReviewsEnable = computed(() => widgetOptions.value.last_reviews_enable === 'true')
const popularBrandsEnable = computed(() => widgetOptions.value.brands_carousel_enable === 'true')
const doctorReviewsEnable = computed(() => widgetOptions.value.doctor_reviews_enable === 'true')
const blogWidgetEnable = computed(() => widgetOptions.value.blog_widget_enable === 'true')
const askProvisorEnable = computed(() => widgetOptions.value.ask_provisor_enable === 'true')
const recommendedProductsEnable = computed(() => widgetOptions.value.recommended_product_enable === 'true')
const searchStoreEnable = computed(() => widgetOptions.value.search_store_enable === 'true')
const storeIconsEnable = computed(() => widgetOptions.value.store_icons_enable === 'true')
const storeInfoEnable = computed(() => widgetOptions.value.store_info_enable === 'true')


onMounted(() => {
    isMobile.value = window.screen.width < 576;
    window.addEventListener('resize', () => {
        isMobile.value = window.screen.width < 576;
    })
})
</script>

<script>
export default {
    data () {
        return {
            timerId: null,
        };
    },
    beforeUnmount() {
        clearTimeout(this.timerId)
        const tagScript = document.getElementById("ld-json-main")
        tagScript.remove()
    },
    mounted() {
        let tagScript = document.createElement('script')
        tagScript.setAttribute('type', "application/ld+json")
        tagScript.setAttribute('id', "ld-json-main")
        tagScript.text = `{
             "@context": "https://schema.org/",
             "@type": "Pharmacy",
             "legalName": "eTabletka - онлайн аптека",
             "url": "https://etabletka.ua/",
             "logo": "https://etabletka.ua/img/logo.svg",
             "priceRange": "UAH",
             "contactPoint": {
             "@type": "ContactPoint",
             "contactType": "customer support",
             "telephone": "0 800 35 50 50",
             "email": "support@etabletka.ua"
            }
          }`
        document.head.appendChild(tagScript)

        !document.body.classList.contains('main') && document.body.classList.toggle('main')
        this.emitter.emit("render-catalog")
        this.timerId = setTimeout(this.scroll, 300);
    },
    methods: {
        scroll() {
            const el = document.getElementById('main-container')
            if (el) {
                window.scrollTo(0,0)
                if (this.timerId) {
                    clearTimeout(this.timerId)
                    !document.body.classList.contains('main') && document.body.classList.toggle('main')
                }
            }
        },
    },
}
</script>

<style scoped>
</style>
