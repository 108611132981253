<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <a href="#">Ліки</a>
            <a href="#">Нервова система</a>
            <span>Цереглиа капсулы мягкие 400 мг №30</span>
        </div>
        <div class="h1-containter d-lg-flex align-items-end">
            <h1>Цереглиа капсулы мягкие 400 мг №30</h1>
        </div>
        <div class="catalog-detail">
            <div class="detail-top d-lg-flex align-items-center justify-content-between">
                <div class="detail-top__left d-flex align-items-center justify-content-between justify-content-md-start">
                    <div class="raiting-container d-flex align-items-center">
                        <rating-stars rating="3" />
                        <a href="#" class="raiting-chats d-flex align-items-center" tabindex="0">
                            <span class="value">12 відгуків</span>
                        </a>
                    </div>
                    <div class="d-flex">
                        <button class="to-fav d-flex align-items-center">
                            <span class="ic icon-heart"></span>
                            <span class="value">Додати в обране</span>
                        </button>
                        <button class="to-apteka d-flex align-items-center">
                            <span class="value">Додати в аптечку</span>
                        </button>
                    </div>
                </div>
                <div class="detail-articul">Артикул товару: <span class="semibold">2165461</span></div>
            </div>
            <div class="detail-info" id="info">
                <div class="detail-nav">
                    <ul class="nav">
                        <li><a href="#info">Все про товар</a></li>
                        <li><a href="#instruction">Інструкція</a></li>
                        <li><a href="#properties">Властивості</a></li>
                        <li><a href="#reviews-small" class="active">Відгуки <span>(23)</span></a></li>
                        <!--                        <li><a href="/gg/">Запитання <span>(12)</span></a></li>-->
                        <li><a href="#apteki">Наявність в аптеках</a></li>
                        <!--                        <li><a href="#analogi">Аналоги та замінники</a></li>-->
                    </ul>
                </div>
                <div class="detail-reviews d-flex align-items-start">
                    <div class="detail-reviews-inner">
                        <h3>Відгуки Цереглиа капсулы мягкие 400 мг №30</h3>
                        <div class="reviews-info-container">
                            <div class="reviews-info">
                                <div class="reviews-info-item d-flex align-items-center">
                                    <div class="d-flex align-items-center reviews-ocenka">
                                        <span class="ic icon-star"></span>
                                        <span class="value">5</span>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div class="revs-quant">4 відгук</div>
                                </div>
                                <div class="reviews-info-item d-flex align-items-center">
                                    <div class="d-flex align-items-center reviews-ocenka">
                                        <span class="ic icon-star"></span>
                                        <span class="value">4</span>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar w-50" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div class="revs-quant">1 відгук</div>
                                </div>
                                <div class="reviews-info-item d-flex align-items-center">
                                    <div class="d-flex align-items-center reviews-ocenka">
                                        <span class="ic icon-star"></span>
                                        <span class="value">3</span>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar w-0" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div class="revs-quant">0 відгуків</div>
                                </div>
                                <div class="reviews-info-item d-flex align-items-center">
                                    <div class="d-flex align-items-center reviews-ocenka">
                                        <span class="ic icon-star"></span>
                                        <span class="value">2</span>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar w-0" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div class="revs-quant">0 відгуків</div>
                                </div>
                                <div class="reviews-info-item d-flex align-items-center">
                                    <div class="d-flex align-items-center reviews-ocenka">
                                        <span class="ic icon-star"></span>
                                        <span class="value">1</span>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar w-0" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div class="revs-quant">0 відгуків</div>
                                </div>
                            </div>
                        </div>
                        <div class="reviews-items">
                            <div class="review-item">
                                <div class="d-sm-flex align-items-center justify-content-between">
                                    <div class="review-item__name">Юлія</div>
                                    <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
                                        <rating-stars rating="3" />
                                        <div class="date">10 Травня 2021</div>
                                    </div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-sm-flex">
                                        <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                        <button class="more-answer" data-toggle="collapse" href="#answers-1" role="button" aria-expanded="false" aria-controls="answers-1"><span class="value">Показати відповіді</span> (2)</button>
                                    </div>
                                    <div class="review-likes d-flex align-items-center">
                                        <span class="dislike d-flex align-items-center">
                                            <span class="ic icon-diselike"><span class="path1"></span><span class="path2"></span></span>
                                            <span class="value">2</span>
                                        </span>
                                        <span class="like d-flex align-items-center">
                                            <span class="ic icon-like"><span class="path1"></span><span class="path2"></span></span>
                                            <span class="value">6</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="review-answers collapse" id="answers-1">
                                    <div class="review-answers-item">
                                        <div class="review-answers-top d-sm-flex align-items-center justify-content-between">
                                            <div class="review-answers-name d-flex align-items-center">
                                                <div class="review-answers-icon"><img src="/images/answer.jpg" alt="" class="lozad"></div>
                                                <div class="review-answers-names">
                                                    <div class="name-anons">Представник магазину</div>
                                                    <div class="name">Вікторія Дем’яненко</div>
                                                </div>
                                            </div>
                                            <div class="date">10 Травня 2021</div>
                                        </div>
                                        <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                        <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                    </div>
                                    <div class="review-answers-item">
                                        <div class="review-answers-top d-flex align-items-center justify-content-between">
                                            <div class="review-answers-name d-sm-flex align-items-center">
                                                <div class="review-answers-icon"><img src="/images/answer.jpg" alt="" class="lozad"></div>
                                                <div class="review-answers-names">
                                                    <div class="name-anons">Представник магазину</div>
                                                    <div class="name">Вікторія Дем’яненко</div>
                                                </div>
                                            </div>
                                            <div class="date">10 Травня 2021</div>
                                        </div>
                                        <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                        <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                    </div>
                                </div>
                            </div>
                            <div class="review-item">
                                <div class="d-sm-flex align-items-center justify-content-between">
                                    <div class="review-item__name">Вікторія</div>
                                    <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
                                        <rating-stars rating="3" />
                                        <div class="date">10 Травня 2021</div>
                                    </div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                <div class="review-images row no-gutters">
                                    <div class="col">
                                        <a href="/images/rev1.jpg" class="review-image" data-fancybox="rev2"><img src="/images/rev1.jpg" alt="" class="lozad"></a>
                                    </div>
                                    <div class="col">
                                        <a href="/images/rev2.jpg" class="review-image" data-fancybox="rev2"><img src="/images/rev2.jpg" alt="" class="lozad"></a>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                    <div class="review-likes d-flex align-items-center">
                                        <span class="dislike d-flex align-items-center">
                                            <span class="ic icon-diselike"><span class="path1"></span><span class="path2"></span></span>
                                            <span class="value">2</span>
                                        </span>
                                        <span class="like d-flex align-items-center">
                                            <span class="ic icon-like"><span class="path1"></span><span class="path2"></span></span>
                                            <span class="value">6</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="review-item">
                                <div class="d-sm-flex align-items-center justify-content-between">
                                    <div class="review-item__name">Вікторія</div>
                                    <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
                                        <rating-stars rating="3" />
                                        <div class="date">10 Травня 2021</div>
                                    </div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться....</div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                    <div class="review-likes d-flex align-items-center">
                                        <span class="dislike d-flex align-items-center">
                                            <span class="ic icon-diselike"><span class="path1"></span><span class="path2"></span></span>
                                            <span class="value">2</span>
                                        </span>
                                        <span class="like d-flex align-items-center">
                                            <span class="ic icon-like"><span class="path1"></span><span class="path2"></span></span>
                                            <span class="value">6</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="review-item">
                                <div class="d-sm-flex align-items-center justify-content-between">
                                    <div class="review-item__name">Вікторія</div>
                                    <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
                                        <rating-stars rating="3" />
                                        <div class="date">10 Травня 2021</div>
                                    </div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться....</div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                                    <div class="review-likes d-flex align-items-center">
                                        <span class="dislike d-flex align-items-center">
                                            <span class="ic icon-diselike"><span class="path1"></span><span class="path2"></span></span>
                                            <span class="value">2</span>
                                        </span>
                                        <span class="like d-flex align-items-center">
                                            <span class="ic icon-like"><span class="path1"></span><span class="path2"></span></span>
                                            <span class="value">6</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="detail-reviews-form">
                            <div class="modal-form addrev">
                                <div class="form-title feed">Залиште ваш відгук</div>
                                <form action="" class="form">
                                    <div class="raitings d-sm-flex align-items-center">
                                        <div class="raiting-title">Ваша оцінка:</div>
                                        <div class="add-raiting">
                                            <span class="item icon-star set_active"></span>
                                            <span class="item icon-star"></span>
                                            <span class="item icon-star"></span>
                                            <span class="item icon-star"></span>
                                            <span class="item icon-star"></span>
                                        </div>
                                    </div>
                                    <div class="input-container empty_field">
                                        <label>Ваше Им’я</label>
                                        <input type="text" name="imya" class="input important" placeholder="">
                                        <div class="error">Не вірний формат телефону</div>
                                    </div>
                                    <div class="input-container empty_field">
                                        <label>Ваш email</label>
                                        <input type="text" name="email" class="input email important" placeholder="">
                                        <div class="error">Не вірний формат телефону</div>
                                    </div>
                                    <div class="input-container">
                                        <label>Ваш коментарій</label>
                                        <textarea name="message" class="textarea"></textarea>
                                    </div>
                                    <div class="add-file d-flex align-items-start">
                                        <span class="ic icon-file-2"></span>
                                        <div class="add-file-container d-md-flex">
                                            <div>
                                                <div class="file-title">Додати зображення</div>
                                                <div class="file-anons">Перетягніть файли сюди чи натисніть на кнопку. Додавайте до 10 зображень в форматах .jpg, .gif, .png, розміром файлу до 5 МБ </div>
                                            </div>
                                            <div class="file-container">
                                                <label class="file-label ">
                                                    <div class="file-text">Додати файл </div>

                                                    <input class="input-file" type="file" name="data[file]" placeholder="Прикрепите файл" style="display: none;">
                                                    <div class="js-labelFile has-file">
                                                        <span class="js-fileName"></span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="addrev-bottom d-sm-flex align-items-center justify-content-between">
                                        <button class="submit button disabled">Залишити відгук</button>
                                        <div class="checkbox">
                                            <input type="checkbox" name="povidom" id="povidom">
                                            <label for="povidom">Повідомляти про відповідь на електронну пошту</label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="detail-reviews-product d-none d-lg-block">
                        <div class="catalog-list">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t88.png" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Цереглиа капсулы мягкие 400 мг №30</a>
                                <a href="#" class="get-now d-inline-flex align-items-center">
                                    <span class="ic icon-check"></span>
                                    <span class="value">Забрати сьогодні в 117 аптеках</span>
                                </a>
                                <div class="buy-block">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                            <div class="price">1 322 <span>грн</span></div>
                                        </div>
                                        <div class="cashback">
                                            Кешбек: <span class="value">+3.88 ₴</span>
                                        </div>
                                        <div class="buy-text">Ціна дійсна тільки при замовленні на сайті!</div>
                                    </div>
                                    <div class="d-flex buttons">
                                        <button class="buy button">В кошик</button>
                                        <button v-if="false" class="button-2 one-click">Швидка покупка</button>
                                    </div>
                                    <div class="buy-ostatok">Залишилось 4 шт.</div>
                                    <a href="#" class="download d-flex align-items-center">
                                        <span class="ic icon-file"></span>
                                        <span class="value">Завантажити інструкцію</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="catalog-slider__container" id="analogi">
                <div class="slider-top d-flex align-items-center justify-content-between">
                    <div class="title">Аналоги та замінники</div>
                    <div class="navs catalog-slider__navs d-flex"></div>
                </div>
                <div class="catalog-list">
                    <div class="catalog-slider">
                        <div class="slide">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t1.png" srcset="/images/t1.png 206w, /images/t1_2x.png 2x" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                                <div class="buy-block d-flex align-items-end justify-content-between">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                        </div>
                                        <div class="price">1 322 <span>грн</span></div>
                                    </div>
                                    <button class="buy button">В кошик</button>
                                </div>
                                <div class="cashback">
                                    Кешбек: <span class="value">+3.88 ₴</span>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav active"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t2.png" srcset="/images/t2.png 206w, /images/t2_2x.png 2x" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                                <div class="buy-block d-flex align-items-end justify-content-between">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                        </div>
                                        <div class="price">1 322 <span>грн</span></div>
                                    </div>
                                    <button class="buy button">В кошик</button>
                                </div>
                                <div class="cashback">
                                    Кешбек: <span class="value">+3.88 ₴</span>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t3.png" srcset="/images/t3.png 206w, /images/t3_2x.png 2x" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                                <div class="buy-block d-flex align-items-end justify-content-between">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                        </div>
                                        <div class="price">1 322 <span>грн</span></div>
                                    </div>
                                    <button class="buy button">В кошик</button>
                                </div>
                                <div class="cashback">
                                    Кешбек: <span class="value">+3.88 ₴</span>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t4.png" srcset="/images/t4.png 206w, /images/t4_2x.png 2x" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                                <div class="buy-block d-flex align-items-end justify-content-between">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                        </div>
                                        <div class="price">1 322 <span>грн</span></div>
                                    </div>
                                    <button class="buy button">В кошик</button>
                                </div>
                                <div class="cashback">
                                    Кешбек: <span class="value">+3.88 ₴</span>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t5.png" srcset="/images/t5.png 206w, /images/t5_2x.png 2x" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                                <div class="buy-block d-flex align-items-end justify-content-between">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                        </div>
                                        <div class="price">1 322 <span>грн</span></div>
                                    </div>
                                    <button class="buy button">В кошик</button>
                                </div>
                                <div class="cashback">
                                    Кешбек: <span class="value">+3.88 ₴</span>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t1.png" srcset="/images/t1.png 206w, /images/t1_2x.png 2x" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                                <div class="buy-block d-flex align-items-end justify-content-between">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                        </div>
                                        <div class="price">1 322 <span>грн</span></div>
                                    </div>
                                    <button class="buy button">В кошик</button>
                                </div>
                                <div class="cashback">
                                    Кешбек: <span class="value">+3.88 ₴</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="catalog-slider__container">
                <div class="slider-top d-flex align-items-center justify-content-between">
                    <div class="title">З цим товаром купують</div>
                    <div class="navs catalog-slider__navs d-flex"></div>
                </div>
                <div class="catalog-list">
                    <div class="catalog-slider">
                        <div class="slide">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t1.png" srcset="/images/t1.png 206w, /images/t1_2x.png 2x" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                                <div class="buy-block d-flex align-items-end justify-content-between">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                        </div>
                                        <div class="price">1 322 <span>грн</span></div>
                                    </div>
                                    <button class="buy button">В кошик</button>
                                </div>
                                <div class="cashback">
                                    Кешбек: <span class="value">+3.88 ₴</span>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav active"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t2.png" srcset="/images/t2.png 206w, /images/t2_2x.png 2x" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                                <div class="buy-block d-flex align-items-end justify-content-between">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                        </div>
                                        <div class="price">1 322 <span>грн</span></div>
                                    </div>
                                    <button class="buy button">В кошик</button>
                                </div>
                                <div class="cashback">
                                    Кешбек: <span class="value">+3.88 ₴</span>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t3.png" srcset="/images/t3.png 206w, /images/t3_2x.png 2x" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                                <div class="buy-block d-flex align-items-end justify-content-between">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                        </div>
                                        <div class="price">1 322 <span>грн</span></div>
                                    </div>
                                    <button class="buy button">В кошик</button>
                                </div>
                                <div class="cashback">
                                    Кешбек: <span class="value">+3.88 ₴</span>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t4.png" srcset="/images/t4.png 206w, /images/t4_2x.png 2x" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                                <div class="buy-block d-flex align-items-end justify-content-between">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                        </div>
                                        <div class="price">1 322 <span>грн</span></div>
                                    </div>
                                    <button class="buy button">В кошик</button>
                                </div>
                                <div class="cashback">
                                    Кешбек: <span class="value">+3.88 ₴</span>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t5.png" srcset="/images/t5.png 206w, /images/t5_2x.png 2x" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                                <div class="buy-block d-flex align-items-end justify-content-between">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                        </div>
                                        <div class="price">1 322 <span>грн</span></div>
                                    </div>
                                    <button class="buy button">В кошик</button>
                                </div>
                                <div class="cashback">
                                    Кешбек: <span class="value">+3.88 ₴</span>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="catalog-item">
                                <div class="shilds">
                                    <div class="shild-hit">Хіт продажу</div>
                                </div>
                                <button class="to-fav"><span class="ic icon-heart"></span></button>
                                <a href="#" class="item-image"><img src="/images/t1.png" srcset="/images/t1.png 206w, /images/t1_2x.png 2x" alt="" class="lozad"></a>
                                <div class="raiting-container d-flex align-items-center">
                                    <rating-stars rating="3" />
                                    <a href="#" class="raiting-chats d-flex align-items-center">
                                        <VIcon icon="chat-text" />
                                        <span class="value">12</span>
                                    </a>
                                </div>
                                <a href="#" class="item-name">Амизон таблетки от гриппа и простуды 0.25 г </a>
                                <div class="buy-block d-flex align-items-end justify-content-between">
                                    <div class="price-block">
                                        <div class="d-flex align-items-center">
                                            <div class="old-price"><span>300</span></div>
                                            <div class="sale">-15%</div>
                                        </div>
                                        <div class="price">1 322 <span>грн</span></div>
                                    </div>
                                    <button class="buy button">В кошик</button>
                                </div>
                                <div class="cashback">
                                    Кешбек: <span class="value">+3.88 ₴</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {

}
</script>

<style scoped>

</style>
