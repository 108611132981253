<template>
    <div class="container-fluid">

        <div class="bredscrumps">
            <inertia-link href="/">{{ $t('Головна сторінка') }}</inertia-link>
            <inertia-link href="/catalog">{{ $t('Каталог') }}</inertia-link>
            <span v-if="category">{{ category.name }}</span>
            <span v-else>{{ $t('Категорії') }}</span>
        </div>

        <div class="h1-containter d-md-flex">
            <h1>{{ $page.props.seo.h1 }}</h1>
        </div>

        <category-list :categories="categories" />

        <brand-carousel v-if="popularBrandsEnable" :brands="popularBrands" />

        <ask-question-catalog v-if="questionsEnable" />

        <product-carousel v-if="popularEnable" :title="$t('Популярні товари')" :products="popularProducts" />

<!--        <discount-list v-if="promoEnable" :discounts="discounts" />-->

        <discount-group :discountGroups="discountGroups" />

        <div v-if="$page.props.seo.text" class="bottom-description">
            <div class="text-collapse">
                <div class="text-container" v-html="$page.props.seo.text"></div>
                <a href="#" class="link text-see">Показати все</a>
            </div>
        </div>
    </div>

</template>


<script setup>
import { defineAsyncComponent } from "vue";
import { usePopLoader } from "../../scrollToTop";

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
const AskQuestionCatalog = defineAsyncComponent(() => import("@/frontend/Components/Widgets/AskQuestionCatalog.vue"))
const ProductCarousel = defineAsyncComponent(() => import("@/frontend/Components/Widgets/ProductCarousel.vue"))
const BrandCarousel = defineAsyncComponent(() => import("@/frontend/Components/BrandCarousel.vue"))
// const DiscountList = defineAsyncComponent(() => import("@/frontend/Components/Widgets/DiscountList.vue"))
const DiscountGroup = defineAsyncComponent(() => import("../../Components/Widgets/DiscountGroup.vue"))
const CategoryList = defineAsyncComponent(() => import("@/frontend/Components/Catalog/CategoryList.vue"))
</script>

<script>

export default {
    props: {
        category: {
            type: Object,
        },
        categories: {
            type: [Object, Array],
        },
        popularBrands: {
            type: [Object, Array],
        },
        popularProducts: {
            type: [Object, Array],
        },
        discounts: {
            type: [Object, Array],
        },
        discountGroups: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            temp: {
                actions: []
            }
        }
    },
    computed: {
        widgetOptions() {
            return this.$store.state.settings.widget_options || {};
        },
        popularBrandsEnable() {
            return this.widgetOptions.catalog_brands_enabled === 'true';
        },
        promoEnable() {
            return this.widgetOptions.catalog_promo_enabled === 'true';
        },
        popularEnable() {
            return this.widgetOptions.catalog_popular_enabled === 'true';
        },
        questionsEnable() {
            return this.widgetOptions.catalog_questions_enabled === 'true';
        },
    }
}
</script>

<style scoped>

</style>
