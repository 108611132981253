<template>
    <div class="container-fluid">
        <div class="bredscrumps" id="breadcrumbs">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Кошик</span>
        </div>
        <div class="h1-containter d-lg-flex align-items-end">
            <h1>Кошик ({{ itemsLength }})</h1>
        </div>
        <div class="cart-page" id="cart-page">
            <div class="row gutters-10">
                <div class="col-md-12 col-lg-8">
                    <div v-if="cartItems.length" class="cart-items">
                        <template v-if="isProductLoaded">
                            <cart-page-item v-for="item in cartItems" v-model="itemsSelected" :item="item"
                                            :key="'cart-item-'+item.product_id" />
                        </template>
                    </div>
                    <div v-if="!cartItems.length" class="cart-items">
                        <br>
                        <p>Кошик порожній, для оформлення замовлення спочатку <a href="/">додайте товар</a></p>
                    </div>
                </div>
                <div class="col-md-12 col-lg-4">
                    <cart-total :submit-disabled="cartItems.length === 0" />
                </div>
            </div>
        </div>

        <product-carousel v-if="false" :title="$t('З цим товаром купують')"
                          :products="popularProducts" />

    </div>

</template>

<script setup>
import { usePopLoader } from "../../scrollToTop";
import { defineAsyncComponent } from "vue";

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
const CartTotal = defineAsyncComponent(() => import("../../Components/Cart/CartTotal.vue"));
const CartPageItem = defineAsyncComponent(() => import("../../Components/Cart/CartPageItem.vue"));
const ProductCarousel = defineAsyncComponent(() => import("../../Components/Widgets/ProductCarousel.vue"));
</script>

<script>
const pluck = (arr, key) => arr.map(i => i[key]);

export default {
    props: {
        cart: {
            type: Array,
            default() {
                return []
            }
        },
        cartProducts: {
            type: Array,
            default() {
                return []
            }
        },
        popularProducts: {
            type: Array,
        },
    },

    data() {
        return {
            selectAll: false,
            itemsSelected: [],
            timerId: null
        }
    },

    created() {
        this.$store.commit('cart/setCart', this.cart)
    },

    watch: {
        "selectAll": function (val) {
            if (val) {
                this.itemsSelected = pluck(this.cartProducts, 'id')
            } else {
                this.itemsSelected = []
            }
        },
    },

    mounted() {
        $('#all-sel').change(function () {
            if (this.checked) {
                $(this).parent().parent().parent().find('.cart-item .chck').attr("checked", "checked");
            } else {
                $(this).parent().parent().parent().find('.cart-item .chck').removeAttr("checked", "checked");
            }
        });

        $('h1').click();

        let tagNoIndexFollow = document.createElement('meta')
        tagNoIndexFollow.setAttribute('id', 'robot-noindex-nofollow')
        tagNoIndexFollow.setAttribute('name', 'robots')
        tagNoIndexFollow.setAttribute('content', 'noindex, nofollow')
        document.head.appendChild(tagNoIndexFollow)

        if (this.itemsLength > 0) {
            const cartItems = this.cartItems
            const items = []
            cartItems.forEach((e) => {
                items.push({
                    item_id: e.product_id,
                    item_name: e.name,
                })
            })

            this.$gtag.event('view_cart', { "ecommerce":
                {
                    currency: "UAH",
                    value: this.cartTotal,
                    items: items
                }
            });
        }
    },

    beforeUnmount() {
        let tagNoIndexFollow = document.getElementById('robot-noindex-nofollow')
        tagNoIndexFollow.remove()
    },

    methods: {
        changeQty(item, e) {
            let value = parseInt(e.target.value.replace(',', '.'));
            if (!value) {
                value = 1;
            }
            if (e.target.value !== value) {
                e.target.value = value;
            }

            item.qty = value
        },

        removeAll() {
            if (this.itemsSelected.length) {
                for (let product_id of this.itemsSelected) {
                    this.$store.dispatch('cart/remove', {product_id})
                }
            }
        }
    },

    computed: {
        itemsLength() {
            return this.$store.state.cart.items.length;
        },
        isProductLoaded() {
            return this.$store.state.cart.productsLoaded;
        },
        cartItems() {
            return this.$store.state.cart.items;
        },
        cartTotal() {
            return this.$store.getters['cart/getTotal']
        },
    }

}
</script>

<style scoped>

</style>
