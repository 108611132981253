<template>
    <div class="modal-form">
        {{ review }}
        <div class="form-title feed">Додаты коментар</div>
        <form action="" class="form">
            <div class="input-container">
                <label>Ваше ім’я</label>
                <input type="text" name="imya" class="input important" placeholder="">
                <div class="error">Не вірний формат телефону</div>
            </div>
            <div class="input-container">
                <label>Ваш email</label>
                <input type="text" name="email" class="input email important" placeholder="">
                <div class="error">Не вірний формат телефону</div>
            </div>
            <div class="input-container">
                <label>Ваш коментар</label>
                <textarea name="message" class="textarea"></textarea>
            </div>
            <button class="submit button">Додати коментар</button>
        </form>
    </div>
</template>

<script>
export default {
    props: {
        errors: {},
        review: {},
    },

    data(){
        return {
            form: {
                review_id: this.review,
                model_type: this.type || null,
                model_id: this.model?.id || null,
                rating: 0,
                name: '',
                email: '',
                text: '',
                image: null,
            }
        }
    },


}
</script>
