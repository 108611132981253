<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <!--            <inertia-link :key="'parent-' + category.id" :href="category.category_url">-->
            <!--                {{ category.name_short || category.name }}-->
            <!--            </inertia-link>-->
            <span>Акційні пропозиції</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>{{ category.name }}</h1>
        </div>

        <div class="catalog-list inner" id="sale-catalog-items">

            <div class="catalog-list-top d-lg-flex align-items-center justify-content-between">
                <div>
                    <a href="#" @click.prevent="resetFilters">{{ $t('Скинути фільтри') }}</a>
                </div>

                <div class="catalog-sort d-md-flex align-items-center">
                    <span class="data">{{ $t('Сортувати') }}:</span>

                    <form-select v-model="sortBy"
                                 :select-class="'select-default'"
                                 :options="sortOptions"
                                 @change="sendFilterForm()"
                                 :select2="true"
                    />
                </div>
            </div>
            <div class="row gutters-6">
                <div class="col col-filter">
                    <CatalogFilterAside expanded />
                </div>
                <div class="col">
                    <div class="catalog-items">

                        <div class="row gutters-6">
                            <template v-if="items?.length">
                                <div v-for="product in items" class="col-12 col-md-6  col-lg-3">

                                    <product-box :product="product" :key="'product-'+product.id" />

                                </div>
                            </template>
                            <template v-else>
                                <div class="col-12 col-md-6  col-lg-3">
                                    {{ $t('На жаль за вашим запитом товарів немає') }}
                                </div>
                            </template>


                        </div>

                        <pagination :paginator="products" :on-link-click="sendFilterForm" @load-more="loadMore" />


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { useRobotNoIndexFollow } from "../metaTags"
import { usePopLoader } from "../../scrollToTop";
import { defineAsyncComponent } from "vue";

const FormSelect = defineAsyncComponent(() => import("../../Components/Form/FormSelect.vue"))
const CatalogFilterAside = defineAsyncComponent(() => import("../../Components/Catalog/CatalogFilterAside.vue"))
const ProductBox = defineAsyncComponent(() => import("../../Components/Product/ProductBox.vue"))
const Pagination = defineAsyncComponent(() => import("../../Components/Pagination.vue"))

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
useRobotNoIndexFollow()
</script>


<script>

import { nextTick } from "vue";

export default {
    name: "AllSales",
    props: {
        category: Object,
        products: [Object, Array],
        requestFilters: {
            type: Object,
            default() {
                return {
                    categories: [],
                    discounts: [],
                    price: {
                        min: 0,
                        max: 0,
                    },
                    sorBy: 'default:asc',
                }
            }
        },
        filters: {
            type: Object,
            default() {
                return {
                    categories: [],
                    discounts: [],
                }
            }
        },
    },

    created() {
        this.$store.commit('catalog/SET_CATEGORY', this.category || {});
        this.$store.dispatch('catalog/loadFilters', this.requestFilters || {});
        this.$store.commit('catalog/SET_ITEMS', this.products?.data || []);
        this.$store.commit('catalog/SET_PAGINATOR', this.products || {});
    },
    computed: {
        sortBy: {
            get() {
                return this.$store.state.catalog.filterForm.sortBy;
            },
            set(value) {
                this.$store.commit('catalog/UPDATE_FILTER_FORM', {sortBy: value});
            }
        },
        total() {
            return this.$store.getters['catalog/total'];
        },
        sortOptions() {
            return this.$store.state.catalog.sortOptions;
        },
        items() {
            return this.$store.state.catalog?.items || [];
        }
    },
    methods: {
        scrollToTop() {
            nextTick(() => {
                const element = document.getElementById('sale-catalog-items');

                if (element) {
                    element.scrollIntoView({behavior: "smooth"})
                }
            })
        },
        resetFilters() {
            this.$store.dispatch('catalog/resetFilters')
            this.scrollToTop()
        },
        sendFilterForm(url = null) {
            this.$store.dispatch('catalog/loadProducts', url)
            this.scrollToTop()
        },
        loadMore() {
            this.$store.dispatch('catalog/loadMore');
        }
    },
    watch: {
        products() {
            this.$store.commit('catalog/SET_ITEMS', this.products?.data || []);
            this.$store.commit('catalog/SET_PAGINATOR', this.products || {});
        },
        category() {
            this.$store.commit('catalog/SET_CATEGORY', this.category || {});
        },
        parents() {
            this.$store.commit('catalog/SET_PARENTS', this.parents || {});
        },
    }
}
</script>

<style scoped>

</style>
