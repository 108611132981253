<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Контакти</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>Контакти</h1>
        </div>
        <div class="content-container d-lg-flex align-items-start">
            <div class="menu-left-container">
                <nav class="menu-left">
                    <button class="button-left d-flex d-lg-none align-items-center justify-content-center collapsed" data-toggle="collapse" href="#menu-left" role="button" aria-expanded="false" aria-controls="menu-left">
                        <span class="but-icon"></span>
                        <span class="value">Меню</span>
                    </button>
                    <ul class="collapse" id="menu-left">
                        <li><a href="#">Про компанію</a></li>
                        <li><a href="#">Доставка та оплата</a></li>
                        <li><a href="#">Наша команда</a></li>
                        <li><a href="#">Медичні експерти</a></li>
                        <li><a href="#" class="active">Контакти</a></li>
                        <li><a href="#">Відгуки про компанію</a></li>
                        <li><a href="#">Умови повернення</a></li>
                        <li><a href="#">Часті питання та відповіді</a></li>
                        <li><a href="#">Ліцензії</a></li>
                        <li><a href="#">Соціальні програми</a></li>
                        <li><a href="#">Редакційна політика</a></li>
                        <li><a href="#">Маркетингова політика</a></li>
                        <li><a href="#">Політика публікації відгуків</a></li>
                    </ul>
                </nav>
            </div>
            <div class="content-static">
                <div class="contacts-page">
                    <div class="d-xl-flex justify-content-between">
                        <div class="contacts-map-container order-12">
                            <div class="contacts-item d-flex align-items-start">
                                <div class="item-icon"><span class="icon-inner d-flex align-items-center justify-content-center"><span class="ic icon-bl"></span></span></div>
                                <div class="item-info">
                                    <div class="data">Головний офіс</div>
                                    <div class="value">Україна, м. Вінниця, вул. Київська, 136-Г</div>
                                </div>
                            </div>
<!--                            <div class="contacts-map" id="contacts-map">-->
<!--                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2603.698955264418!2d28.474629292791274!3d49.26315167190898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472d5cad682e60db%3A0x56f80f766104154!2z0YPQuy4g0JrQuNC10LLRgdC60LDRjywgMTM2LCDQktC40L3QvdC40YbQsCwg0JLQuNC90L3QuNGG0LrQsNGPINC-0LHQu9Cw0YHRgtGMLCAyMTAwMA!5e0!3m2!1sru!2sua!4v1628842840777!5m2!1sru!2sua" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>-->
<!--                            </div>-->
                        </div>
                        <div class="contacts-container">
                            <div class="contacts-title">Клієнтам</div>
                            <div class="contacts-item d-flex align-items-start">
                                <div class="item-icon"><span class="icon-inner d-flex align-items-center justify-content-center"><span class="ic icon-phone-3"></span></span></div>
                                <div class="item-info">
                                    <div class="data">Гаряча лінія:</div>
                                    <a v-for="phone in $store.state.settings.phones"
                                       :href="`tel:+${$store.getters['toRawPhone'](phone)}`" class="phone">
                                        {{ phone }}
                                    </a>
                                </div>
                            </div>
                            <div class="contacts-item grafik d-flex align-items-start">
                                <div class="item-icon"><span class="icon-inner d-flex align-items-center justify-content-center"><span class="ic icon-time"></span></span></div>
                                <div class="item-info">
                                    <div class="data">Графік роботи:</div>
                                    <div class="value">{{ $store.state.settings.work_time }}</div>
                                </div>
                            </div>
                            <div class="contacts-item tp d-flex align-items-start">
                                <div class="item-icon"><span class="icon-inner d-flex align-items-center justify-content-center"><span class="ic icon-walkman"></span></span></div>
                                <div class="item-info">
                                    <div class="data">Служба підтримки:</div>
                                    <a href="mailto:eTabletka@apteka.ua" class="value">eTabletka@apteka.ua</a>
                                </div>
                            </div>
                            <div class="contacts-soc">
                                <div class="soc-title">eTabletka в соціальних мережах:</div>
                                <div class="d-flex  flex-wrap"
                                     :class="$store.state.settings.soc_links.length > 4 ? 'justify-content-start' : 'justify-content-between'">
                                    <template v-for="socLink in $store.state.settings.soc_links">
                                        <a :href="socLink.url " target="_blank"
                                           class="d-flex align-items-center justify-content-center footer-social"
                                           :class="socLink.type"
                                        >
                                            <span class="social-icon" v-html="socLink.icon"></span>
                                        </a>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contacts-container bottom">
                        <div class="d-md-flex">
                            <div class="contacts-bottom">
                                <div class="contacts-title">Приймальна директора</div>
                                <div class="contacts-item d-flex align-items-center">
                                    <div class="item-icon"><span class="icon-inner d-flex align-items-center justify-content-center"><span class="ic icon-phone-3"></span></span></div>
                                    <div class="item-info">
                                        <a href="tel:+380672343356" class="value">+38 (067) 234 33 56</a>
                                    </div>
                                </div>
                                <div class="contacts-item email d-flex align-items-center">
                                    <div class="item-icon"><span class="icon-inner d-flex align-items-center justify-content-center"><span class="ic icon-email-2"></span></span></div>
                                    <div class="item-info">
                                        <a href="mailto:office@konex.com.ua" class="value">office@konex.com.ua</a>
                                    </div>
                                </div>
                            </div>
                            <div class="contacts-bottom">
                                <div class="contacts-title">Відділ оптової торгівлі</div>
                                <div class="contacts-item d-flex align-items-center">
                                    <div class="item-icon"><span class="icon-inner d-flex align-items-center justify-content-center"><span class="ic icon-phone-3"></span></span></div>
                                    <div class="item-info">
                                        <a href="tel:+380672343356" class="value">+38 (067) 234 33 56</a>
                                    </div>
                                </div>
                                <div class="contacts-item email d-flex align-items-center">
                                    <div class="item-icon"><span class="icon-inner d-flex align-items-center justify-content-center"><span class="ic icon-email-2"></span></span></div>
                                    <div class="item-info">
                                        <a href="mailto:office@konex.com.ua" class="value">office@konex.com.ua</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contacts-bottom">
                            <div class="contacts-title">Оренда приміщень</div>
                            <div class="contacts-item d-flex align-items-center">
                                <div class="item-icon"><span class="icon-inner d-flex align-items-center justify-content-center"><span class="ic icon-phone-3"></span></span></div>
                                <div class="item-info">
                                    <a href="tel:+380672343356" class="value">+38 (067) 234 33 56</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Contacts"
}
</script>

<style scoped>

</style>
