<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Ліцензії</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>Ліцензії</h1>
        </div>
        <div class="content-container d-lg-flex align-items-start">
            <div class="menu-left-container">
                <nav class="menu-left">
                    <button class="button-left d-flex d-lg-none align-items-center justify-content-center collapsed" data-toggle="collapse" href="#menu-left" role="button" aria-expanded="false" aria-controls="menu-left">
                        <span class="but-icon"></span>
                        <span class="value">Меню</span>
                    </button>
                    <ul class="collapse" id="menu-left">
                        <li><a href="#">Про компанію</a></li>
                        <li><a href="#">Доставка та оплата</a></li>
                        <li><a href="#">Наша команда</a></li>
                        <li><a href="#">Медичні експерти</a></li>
                        <li><a href="#">Контакти</a></li>
                        <li><a href="#">Відгуки про компанію</a></li>
                        <li><a href="#">Умови повернення</a></li>
                        <li><a href="#">Часті питання та відповіді</a></li>
                        <li><a href="#" class="active">Ліцензії</a></li>
                        <li><a href="#">Соціальні програми</a></li>
                        <li><a href="#">Редакційна політика</a></li>
                        <li><a href="#">Маркетингова політика</a></li>
                        <li><a href="#">Політика публікації відгуків</a></li>
                    </ul>
                </nav>
            </div>
            <div class="content-static">
                <div class="certs-page">
                    <div class="certs-container">
                        <h3>Ліцензії на здійснення фармацевтичної діяльності</h3>
                        <div class="row gutters-10 row-cols-3">
                            <div class="col">
                                <a href="/images/cert-1.jpg" class="certs-item" data-fancybox="certs-1"><img src="/images/cert-1.jpg" alt="" class="lozad"></a>
                            </div>
                            <div class="col">
                                <a href="/images/cert-1.jpg" class="certs-item" data-fancybox="certs-1"><img src="/images/cert-1.jpg" alt="" class="lozad"></a>
                            </div>
                            <div class="col">
                                <a href="/images/cert-1.jpg" class="certs-item" data-fancybox="certs-1"><img src="/images/cert-1.jpg" alt="" class="lozad"></a>
                            </div>
                        </div>
                    </div>
                    <div class="certs-container">
                        <h3>Дозвіл на дистанційну торгівлю</h3>
                        <div class="row gutters-10 row-cols-3">
                            <div class="col">
                                <a href="/images/cert-2.jpg" class="certs-item" data-fancybox="certs-2"><img src="/images/cert-2.jpg" alt="" class="lozad"></a>
                            </div>
                            <div class="col">
                                <a href="/images/cert-2.jpg" class="certs-item" data-fancybox="certs-2"><img src="/images/cert-2.jpg" alt="" class="lozad"></a>
                            </div>
                            <div class="col">
                                <a href="/images/cert-2.jpg" class="certs-item" data-fancybox="certs-2"><img src="/images/cert-2.jpg" alt="" class="lozad"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Certs"
}
</script>

<style scoped>

</style>
