<template>
    <div>
        Brand

        {{brand}}
    </div>
</template>

<script>
export default {
    name: "Brand",
    props: {
        brand: {},
    },
}
</script>
