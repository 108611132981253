<template>
    <div class="personal-page">
        <div class="personal-container">
            <div class="personal-referal">
                <div class="referal-top d-md-flex align-items-center justify-content-between">
                    <h3>Реферальна программа</h3>
                    <div class="referal-bonus">
                        <span class="data">Нараховано бонусних гривень: </span>
                        <span class="value">+3.88 ₴ </span>
                    </div>
                </div>
                <div class="referal-image">
                    <VIcon icon="referal" />
                </div>
                <div class="referal-links d-md-flex align-items-center justify-content-between">
                    <div class="referal-link">
                        <label class="label">Сгенеруйте посилання</label>
                        <div class="link-form  d-sm-flex align-items-center">
                            <input type="text" class="input" value="https://www.konex.com.ua/drugstor" name="link" id="copyTarget">
                            <button id="copyButton" class="copy button-4">Скопіювати</button>
                            <span id="msg"></span>
                        </div>
                    </div>
                    <div class="referal-qr d-flex align-items-center">
                        <div class="qr-image"><VIcon icon="qr" />
                            <div class="qr-text">Або <a href="#">поділіться</a> сгенерованим QR кодом</div>
                        </div>
                    </div>
                    <div class="referal-bottom">
                        <h3>Як це працює?</h3>
                        <div class="referal-anons">Вже давно відомо, що читабельний зміст буде заважати зосередитись людині, яка оцінює композицію сторінки. Сенс використання Lorem Ipsum полягає в тому, що цей текст має більш-менш нормальне розподілення літер на відміну від, наприклад, "Тут іде текст. Тут іде текст." Це робить текст схожим на оповідний. Багато програм верстування та веб-дизайну використовують Lorem Ipsum як зразок і пошук за терміном "lorem ipsum" відкриє багато веб-сайтів, які знаходяться ще в зародковому стані. Різні версії Lorem Ipsum з'явились за минулі роки, деякі випадково, деякі було створено зумисно (зокрема, жартівливі).</div>
                    </div>
                </div>
            </div>
        </div>
    </div></template>

<script>
import ProfileLayout from "./ProfileLayout.vue";

export default {
    layout: ProfileLayout,

    mounted() {

        if ($("#copyTarget").length){
            document.getElementById("copyButton").addEventListener("click", function() {
                copyToClipboardMsg(document.getElementById("copyTarget"), "msg");
            });

            function copyToClipboardMsg(elem, msgElem) {
                var succeed = copyToClipboard(elem);
                var msg;
                if (!succeed) {
                    msg = "Copy not supported or blocked.  Press Ctrl+c to copy."
                } else {
                    msg = "Text copied to the clipboard."
                }
                if (typeof msgElem === "string") {
                    msgElem = document.getElementById(msgElem);
                }
                msgElem.innerHTML = msg;
                setTimeout(function() {
                    msgElem.innerHTML = "";
                }, 2000);
            }

            function copyToClipboard(elem) {
                // create hidden text element, if it doesn't already exist
                var targetId = "_hiddenCopyText_";
                var isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
                var origSelectionStart, origSelectionEnd;
                if (isInput) {
                    // can just use the original source element for the selection and copy
                    target = elem;
                    origSelectionStart = elem.selectionStart;
                    origSelectionEnd = elem.selectionEnd;
                } else {
                    // must use a temporary form element for the selection and copy
                    target = document.getElementById(targetId);
                    if (!target) {
                        var target = document.createElement("textarea");
                        target.style.position = "absolute";
                        target.style.left = "-9999px";
                        target.style.top = "0";
                        target.id = targetId;
                        document.body.appendChild(target);
                    }
                    target.textContent = elem.textContent;
                }
                // select the content
                var currentFocus = document.activeElement;
                target.focus();
                target.setSelectionRange(0, target.value.length);

                // copy the selection
                var succeed;
                try {
                    succeed = document.execCommand("copy");
                } catch(e) {
                    succeed = false;
                }
                // restore original focus
                if (currentFocus && typeof currentFocus.focus === "function") {
                    currentFocus.focus();
                }

                if (isInput) {
                    // restore prior selection
                    elem.setSelectionRange(origSelectionStart, origSelectionEnd);
                } else {
                    // clear temporary content
                    target.textContent = "";
                }
                return succeed;
            }
        }

    }
}
</script>

<style scoped>

</style>
