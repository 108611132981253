import store from "./index";
import axios from "axios";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const state = {
    items: [],

    products: [],

    productTypes: [],

    productsLoaded: false,
}

const getters = {
    getItemInCartById: (state) => (id) => {
        if (state.items) {
            return state.items.find(item => item.product_id === id);
        }
        return []
    },

    isPrescription(state) {
        if (state.items.length > 0  && state.products.length > 0)
            return !!state.products.find(p => p.is_prescription);

        return []
    },

    productTypesInfo(state) {
        return state.productTypes;
    },

    productTypes(state) {
        if (state.items) {
            const uniqueTypes = new Set();
            state.items.forEach(obj => {
                uniqueTypes.add(obj.type)
            });
            return Array.from(uniqueTypes)
        }
        return []
    },

    cartItems(state, getters) {
        return state.items.map(item => item)
    },

    getProduct(state) {
        if (state.products) {
            return (item, key) => {
                let product = state.products.find(p => (item.product_id || item) === p.id);
                return (product && key) ? product[key] : product
            }
        }

        return []
    },

    getSubTotal(state, getters) {
        return item => {
            const price = getters.getProduct(item.product_id||item, 'order_price') ?? 0
            let partPrice = 0
            if (item.partQuantity && item.packQuantity) {
                const priceForOneMinItem = (price/item.packQuantity).toFixed(2)
                partPrice = (priceForOneMinItem * item.partQuantity).toFixed(2)
            }
            const fullPrice = parseFloat((item ? item.fullQuantity * price : 0).toFixed(2))
            return parseFloat(1*partPrice + 1*fullPrice).toFixed(2)
        }
    },

    getTotal(state, getters){
        return parseFloat(state.items.reduce((total,item) => {
            item && (total += 1*getters.getSubTotal(item))
            return 1*total
        }, 0).toFixed(2))
    },

    getSubTotalWeight(state, getters){
        return item => {
            return parseFloat((item ? (item.qty * (getters.getProduct(item.product_id||item, 'weight')||0)) : 0).toFixed(2))
        }
    },

    getTotalWeight(state, getters){
        return parseFloat(state.items.reduce((total,item) => {
            item && (total += getters.getSubTotalWeight(item))
            return total
        }, 0).toFixed(2))
    },

    getSubTotalVolume(state, getters){
        return item => {
            return parseFloat((item ? (item.qty * (getters.getProduct(item.product_id||item, 'volume')||0)) : 0).toFixed(2))
        }
    },

    getTotalVolume(state, getters) {
        return parseFloat(state.items.reduce((total, item) => {
            item && (total += getters.getSubTotalVolume(item))
            return total
        }, 0).toFixed(2))
    },

    getCashback(state, getters) {
        return getters['getTotal'] * store.state['settings'].cashback
    },

}

const mutations = {
    setCart(state, cart) {
        state.items = cart
    },

    setProducts(state, products) {
        state.products = products
        state.productsLoaded = true
    },

    SET_PRODUCT_TYPES : (state, data) => {
        state.productTypes = data;
    }
}

const actions = {
    alert(context, params) {
        context.dispatch('layout/alert', params, {root: true});
    },

    inc(context, { item, min, itemQuantityType }) {
        if (itemQuantityType === 'part' && item.packQuantity > 1) {
            if ((item.partQuantity + min) >= item.packQuantity) {
                item.fullQuantity += min
                item.partQuantity = 0
            } else {
                item.partQuantity += min
            }
        }

        if (itemQuantityType === 'full') {
            item.fullQuantity += min
        }

        if (item.packQuantity > 1 && item.partQuantity !== 0 ) {
            item.totalQuantity = 1 * ((item.partQuantity/item.packQuantity) + item.fullQuantity)
        } else {
            item.totalQuantity = item.fullQuantity
        }

        context.dispatch('modify', item)
    },

    decr(context, { item, min, itemQuantityType }) {

        if (itemQuantityType === 'part' && item.packQuantity > 1) {
            if ((item.partQuantity - min) < 0) {
                if (item.fullQuantity >= 1) {
                    item.fullQuantity -= min
                    item.partQuantity = item.packQuantity - 1
                }
            } else if ((item.partQuantity - min) === 0) {
                console.log('2-0')
                if (item.fullQuantity >= 1) {
                    item.partQuantity = 0
                } else if (item.fullQuantity <= 0) {
                    // item.fullQuantity = 1
                    // item.partQuantity -= min
                } else {
                    item.fullQuantity = 0
                    item.partQuantity = item.packQuantity - 1
                }
            } else {
                item.partQuantity -= min
            }
        }

        if (itemQuantityType === 'full') {
            if ((item.fullQuantity - min) <= 0) {
                item.fullQuantity = 0
                if (item.partQuantity === 0) {
                    item.fullQuantity = 1
                }
            } else {
                item.fullQuantity -= min
            }
        }

        if (item.packQuantity > 1 && item.partQuantity !== 0 ) {
            item.totalQuantity = 1 * ((item.partQuantity/item.packQuantity) + item.fullQuantity)
        } else {
            item.totalQuantity = item.fullQuantity
        }

        context.dispatch('modify', item)
    },

    async setQuantity(context, item) {
        if (item.available > 0) {
            const hasFraction = item.available % 1 !== 0
            if (hasFraction) {
                if (item.available >= 1) {
                    const difference = item.totalQuantity - item.available;
                    const integerPart = Math.trunc(difference);
                    const fractionalPart = 1 * (difference - integerPart).toFixed(5);

                    item.fullQuantity = item.fullQuantity - integerPart
                    item.partQuantity = item.partQuantity - (item.packQuantity * fractionalPart)
                    item.totalQuantity = item.available
                } else {
                    item.fullQuantity = 0
                    item.partQuantity = item.packQuantity * item.available
                    item.totalQuantity = item.available
                }
            } else {
                item.totalQuantity = item.fullQuantity = item.available
                item.partQuantity = 0
            }
        }
        await context.dispatch('modify', item)
    },

    async modify(context, product) {
        const response =  await axios.post(this.$inertia.route('frontend.cart.modify'), {product});
        await context.commit('setCart', response.data.cart)
        await context.commit('setProducts', response.data.products)
        console.log('MODIFY setCart', response.data.cart)
        if (response.data.cart_message) {
            await context.dispatch('alert', {message: response.data.cart_message, type: 'warning'})
        }
    },

    add(context, { product_id, qty = null, options = [] }) {
        console.log('qty', qty)
        axios.post(this.$inertia.route('frontend.cart.add'), { product_id, qty, options }).then(response => {
            context.commit('setCart', response.data.cart)
            context.commit('setProducts', response.data.products)
            console.log('ADD setCart', response.data.cart)
            if (response.data.cart_message) {
                context.dispatch('alert', {message: response.data.cart_message, type: 'warning'})
            }
        })
    },

    remove(context, { product_id }) {
        axios.post(this.$inertia.route('frontend.cart.remove'), { product_id }, { hideLoader: true })
            .then(response => {
                context.commit('setCart', response.data.cart)
                context.commit('setProducts', response.data.products)
            })
    },

    reset(context) {
        axios.post(this.$inertia.route('frontend.cart.reset'), {}, { hideLoader: true })
            .then(response => {
                context.commit('setCart', response.data.cart)
                context.commit('setProducts', response.data.products)
            })
    },

    loadProducts(context, hideLoader = false) {
        axios.get(this.$inertia.route('frontend.cart.products'), {
            hideLoader: hideLoader
        }).then(response => {
            context.commit('setProducts', response.data)
        })
    },

    loadProductTypes({commit}) {
        axios.get(this.$inertia.route('frontend.cart.product-types')).then(response => {
            commit('SET_PRODUCT_TYPES', response.data.types)
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
