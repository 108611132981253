import('./bootstrap')
import axios from "@/frontend/bootstrap/axios"
import store from "./store";
import createVueApp from './app'
import { createInertiaApp, router } from '@inertiajs/vue3'
import MainApp from './App.vue'
import HydrateStoreFromInertia from "./Helpers/HydrateStoreFromInertia"
import { useGtag } from "vue-gtag-next";

router.on('navigate', (event) => {
    // console.log('URL', event.detail.page.url);
    const { pageview} = useGtag()
    pageview({ page_path: event.detail.page.url});
})

axios.interceptors.request.use((request) => {
    // change the url scheme from http to https
    if (location.protocol === 'https:') {
        request.url = request.url.replace('http://', 'https://')
    }

    if (request.url.indexOf('_debugbar') === -1 && !request.hideLoader) {
        store.commit('setLoading', true)
    }
    request.url = decodeURIComponent(request.url)
    return request
})

axios.interceptors.response.use((response) => {
    //console.log(response)
    //console.log(response.request.responseURL)
    if (response.request.responseURL.indexOf('_debugbar') === -1 && !response.request.hideLoader) {
        if (store.state.loading !== false)
            store.commit('setLoading', false)
    }

    setTimeout(() => window.observer.observe(), 300);

    return response;
}, (error, status, xhr) => {
    store.commit('setLoading', false)

    if ([401, 403].includes(error.status)) {
        //todo: errors
        store.dispatch('layout/alert', {message: error.response.data.message, status})
    }
    return Promise.reject(error);
})

window.axios = axios;

export default createInertiaApp({
    title: title => `${title}`,
    resolve: async (name) => {

        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        let page = pages[`./Pages/${name}.vue`]
        page.default.layout = page.default.layout || MainApp;
        if (!Array.isArray(page.default.layout) && page.default.layout !== MainApp) {
            page.default.layout = [MainApp, page.default.layout]
        }
        return page
    },
    setup({ el, App, props, plugin }) {

        HydrateStoreFromInertia(props.initialPage)

        const app = createVueApp({App, props})

        app.use(plugin)
        app.mount(el)

    },
})
