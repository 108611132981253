<template>
    <div class="modal-form">
        <div class="form-title feed">Уточнити ціну</div>
        <form @submit.prevent="submit" action="" class="form">

            <form-input v-model="form.name"
                        :error="errors.name"
                        :label="'Ваше ім’я'"
                        :required="true"
            />

            <form-input v-model="form.email"
                        :error="errors.email"
                        input-class="email"
                        type="email"
                        :label="'Ваш email'"
                        :required="true"
            />

            <form-input v-model="form.text"
                        :error="errors.text"
                        input-class="textarea"
                        type="textarea"
                        :label="'Ваш коментар'"
                        :required="true"
            />

            <button class="submit button">Відправити</button>

        </form>

        <modal-message ref="messageRef">{{ message }}</modal-message>
    </div>

</template>

<script>
import {defineAsyncComponent} from "vue";

const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));
const ModalMessage = defineAsyncComponent(() => import("@/frontend/Components/ModalMessage.vue"));


export default {
    components: {ModalMessage, FormInput},
    props: {
        errors: Array,
        productId: String,
    },
    data() {
        return {
            form: {
                name: '',
                email: '',
                text: '',
            },
            message: ''
        }
    },
    mounted() {

    },

    methods: {
        submit(e) {
            let vm = this
            let formData = new FormData();

            formData.append('name', this.form.name);
            formData.append('email', this.form.email);
            formData.append('text', this.form.text);
            formData.append('product_id', this.productId);

            this.$inertia.modal(this.route('frontend.check-price.store'), 'post', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }, (resp) => {
                this.message = resp.message;
                this.$refs.messageRef.show();
                this.$emit('submitted');
                // vm.$store.dispatch('layout/alert', {message: vm.$t('Success')})
            })
        },

        fillForm() {
            this.form.name = this.$store.user?.name || 'Кіндрат'
            this.form.email = this.$store.user?.email || 'kindrat@email.com'
            this.form.text = String.random();
        }
    }
}
</script>
