<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Пошук</span>
        </div>

        <div class="h1-containter d-lg-flex align-items-end">
            <h1 class="text-truncate" :title="'Пошук ' + query">Пошук "{{ query }}"</h1>
            <div class="h1-results text-nowrap">Знайдено {{ total }}</div>
        </div>

        <div class="catalog-list inner">

            <div class="catalog-list-top d-lg-flex align-items-center justify-content-between">
                <div>
                    <a href="#" id="reset-filters" @click.prevent="resetFilters()">{{ $t('Скинути фільтри') }}</a>
                </div>

                <div class="catalog-sort d-md-flex align-items-center">
                    <span class="data">{{ $t('Сортувати') }}:</span>
                    <v-select
                        :options="sortOptions"
                        :reduce="sortOptions => sortOptions.id"
                        :clearable="false"
                        :searchable="!isMobile"
                        v-model="sortBy"
                        placeholder="Оберить опцію"
                        @option:selected="selectValue"
                    />
                </div>
            </div>
            <div class="row gutters-6">
                <div class="col col-filter">
                    <CatalogFilterAside />
                </div>
                <div class="col">
                    <div class="catalog-items">

                        <div class="row gutters-6">
                            <div v-if="items?.length" v-for="product in items"
                                 class="col-12 col-md-6  col-lg-3">

                                <product-box :product="product" :key="'product-'+product.id" />

                            </div>
                            <div v-else class="col-12 col-md-6  col-lg-3">
                                {{ $t('На жаль за вашим запитом товарів немає') }}
                            </div>
                        </div>

                        <pagination :paginator="products" :on-link-click="sendFilterForm" @load-more="loadMore" />

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { defineAsyncComponent } from "vue";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
    components: {
        vSelect,
        Pagination: defineAsyncComponent(() => import("@/frontend/Components/Pagination.vue")),
        CatalogFilterAside: defineAsyncComponent(() => import("@/frontend/Components/Catalog/CatalogFilterAside.vue")),
        ProductBox: defineAsyncComponent(() => import("@/frontend/Components/Product/ProductBox.vue")),
    },
    props: {
        requestFilters: {
            type: Object,
            default() {
                return {
                    categories: [],
                    brands: [],
                    sell_types: [],
                    price: {
                        min: 0,
                        max: 0,
                    },
                    sorBy: 'default:asc',
                }
            }
        },
        filters: {
            type: Object,
            default() {
                return {
                    characteristics: [],
                }
            }
        },
        search: {
            type: String,
            default: ''
        },
        products: {
            type: Object,
            default() {
                return {
                    data: [],
                    links: [],
                }
            }
        },
    },
    created() {
        this.$store.commit('catalog/SET_CATEGORY', {
            name: '',
            category_url: '',
            url: '',
        });

        this.$store.dispatch('catalog/loadFilters', {...this.requestFilters || {}, q: this.search});
        this.$store.commit('catalog/SET_ITEMS', this.products.data || []);
        this.$store.commit('catalog/SET_PAGINATOR', this.products || {});
    },
    mounted() {
        const resetElement = document.getElementById("reset-filters")
        if (resetElement) resetElement.focus()
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 768;
        },
        sortBy: {
            get() {
                return this.$store.state.catalog.filterForm.sortBy;
            },
            set(value) {
                this.$store.commit('catalog/UPDATE_FILTER_FORM', {sortBy: value});
            }
        },
        total() {
            return this.products?.total || 0;
        },
        sortOptions() {
            return this.$store.state.catalog.sortOptions.map(item => ({
                label: item.text,
                id: item.value
            }))
        },
        items() {
            return this.$store.state.catalog.items;
        },
        query() {
            return this.$store.state.catalog.filterForm.q;
        }
    },
    methods: {
        resetFilters() {
            this.$store.dispatch('catalog/resetFilters', {q: this.query});
        },
        sendFilterForm(url = null) {
            this.$store.dispatch('catalog/loadProducts', url);
        },
        loadMore() {
            this.$store.dispatch('catalog/loadMore');
        },
        selectValue(data) {
            if (data.id) {
                this.sortBy = data.id
                this.$store.dispatch('catalog/updateSortBy', this.sortBy)
                this.sendFilterForm()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
