<template>

    <div class="farmacy-page personal-favorites">
        <div class="favorites-top d-sm-flex align-items-center justify-content-between">
            <h3>Обрані аптеки</h3>
            <button @click.prevent="$store.dispatch('wishlist/clearStores')"
                    class="del-all d-flex align-items-center">
                <span class="ic icon-delete"></span>
                <span class="value">Видалити всі аптеки</span>
            </button>
        </div>

        <div class="farmacy-list">
            <store-profile-row v-for="store in $store.state.wishlist.storeModels" :key="store.id"
                               :store="store"
            />


        </div>
    </div>

</template>

<script setup>
import { useRobotNoIndexFollow } from "../metaTags"
import { usePopLoader } from "../../scrollToTop";

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
useRobotNoIndexFollow()
</script>

<script>
import ProfileLayout from "./ProfileLayout.vue";
import StoreProfileRow from "../../Components/Store/StoreProfileRow.vue";

export default {
    components: {StoreProfileRow},
    layout: ProfileLayout,
    beforeCreate() {
        this.$store.dispatch('wishlist/loadStores')
    },
}
</script>

<style scoped>

</style>
