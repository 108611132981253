import axios from "axios";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const state = {
    products: [],
    productModels: [],
    productsLoaded: false,
    aids: [],
    aidModels: [],
    aidsLoaded: false,
    stores: [],
    storeModels: [],
    storesLoaded: false,
}

const getters = {
    productInWishlist: state => product => {
        let id = product.id || product

        return state.products.indexOf(id) > -1
    },
    aidInWishlist: aid => aid => {
        let id = aid.id || aid

        return state.aids.indexOf(id) > -1
    },
    storeInWishlist: state => store => {
        let id = store.id || store

        return state.stores.indexOf(id) > -1
    },
}

const mutations = {
    setProductList(state, products) {
        state.products = products
    },
    setAidList(state, aids) {
        state.aids = aids
    },
    setStoreList(state, stores) {
        state.stores = stores
    },
    setProducts(state, products) {
        state.productModels = products
        state.productsLoaded = true
    },
    setAids(state, aids) {
        state.aidModels = aids
        state.aidsLoaded = true
    },
    setStores(state, stores) {
        state.storeModels = stores
        state.storesLoaded = true
    },
    toggleProduct(state, product) {
        let id = product.id || product

        let index = state.products.indexOf(id)
        if (index > -1) {
            state.products.splice(index, 1)
            let modelIndex = state.productModels.findIndex(p => p.id === id)
            if (modelIndex > -1) {
                state.productModels.splice(modelIndex, 1)
            }
        } else {
            state.products.push(id)
        }
    },
    toggleAid(state, aid) {
        let id = aid.id || aid
        let index = state.aids.indexOf(id)
        if (index > -1) {
            state.aid.splice(index, 1)
            let modelIndex = state.aidModels.findIndex(s => s.id === id)
            if (modelIndex > -1) {
                state.aidModels.splice(modelIndex, 1)
            }
        } else {
            state.aids.push(id)
        }
    },
    toggleStore(state, store) {
        let id = store.id || store
        let index = state.stores.indexOf(id)
        if (index > -1) {
            state.stores.splice(index, 1)
            let modelIndex = state.storeModels.findIndex(s => s.id === id)
            if (modelIndex > -1) {
                state.storeModels.splice(modelIndex, 1)
            }
        } else {
            state.stores.push(id)
        }
    },
}

const actions = {
    toggleProduct(context, product) {
        let id = product.id || product
        axios.post(this.$inertia.route('frontend.wishlist.product', id)).then(response => {
            // console.log(response)
            context.commit('toggleProduct', id)
            if (response.data.status === 'success' && response.data.message) {
                this.dispatch('layout/alert', {message: response.data.message})
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 401) {
                this.dispatch('layout/alert', {message: error.response.data.message, type: 'danger'})
            }
        })
    },
    toggleAid(context, aid) {
        let id = aid.id || aid
        axios.post(this.$inertia.route('frontend.wishlist.aid', id)).then((response) => {
            context.commit('toggleAid', id)
            if (response.data.status === 'success' && response.data.message) {
                this.dispatch('layout/alert', {message: response.data.message})
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                let auth_link = {
                    template: '<a href="#" @click.prevent="clickLogin">Login</a>',
                    methods: {
                        clickLogin() {
                            // $('.alert-container').click()
                            this.$inertia.modal(this.$inertia.route('frontend.auth.login'))
                        }
                    }
                }
                this.dispatch('layout/alert', {message: error.response.data.message, component: auth_link})
            }
        })
    },
    toggleStore(context, store) {
        let id = store.id || store
        axios.post(this.$inertia.route('frontend.wishlist.store', id)).then((response) => {
            context.commit('toggleStore', id)
            if (response.data.status === 'success' && response.data.message) {
                this.dispatch('layout/alert', {message: response.data.message})
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                let auth_link = {
                    template: '<a href="#" @click.prevent="clickLogin">Login</a>',
                    methods: {
                        clickLogin() {
                            // $('.alert-container').click()
                            this.$inertia.modal(this.$inertia.route('frontend.auth.login'))
                        }
                    }
                }
                this.dispatch('layout/alert', {message: error.response.data.message, component: auth_link})
            }
        })
    },
    loadProducts(context) {
        axios.get(this.$inertia.route('frontend.wishlist.products')).then(response => {
            context.commit('setProducts', response.data)
        })
    },
    loadAids(context) {
        axios.get(this.$inertia.route('frontend.wishlist.aids')).then(response => {
            context.commit('setAids', response.data)
        })
    },
    loadStores(context) {
        axios.get(this.$inertia.route('frontend.wishlist.stores')).then(response => {
            context.commit('setStores', response.data)
        })
    },
    clearProducts(context) {
        axios.delete(this.$inertia.route('frontend.wishlist.clear-products')).then(response => {
            context.commit('setProductList', [])
            context.commit('setProducts', [])
        })
    },
    clearAids(context) {
        axios.delete(this.$inertia.route('frontend.wishlist.clear-aids')).then(response => {
            context.commit('setAidList', [])
            context.commit('setAids', [])
        })
    },
    clearStores(context) {
        axios.delete(this.$inertia.route('frontend.wishlist.clear-stores')).then(response => {
            context.commit('setStoreList', [])
            context.commit('setStores', [])
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
