<template>
    <div class="container-fluid" id="main-container">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>{{ pageData.title }}</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>{{ pageData.seo_h1 || pageData.title }}</h1>
        </div>
        <div class="content-container d-lg-flex align-items-start">
            <div class="menu-left-container">
                <nav class="menu-left">
                    <button class="button-left d-flex d-lg-none align-items-center justify-content-center collapsed" data-toggle="collapse" href="#menu-left" role="button" aria-expanded="false" aria-controls="menu-left">
                        <span class="but-icon"></span>
                        <span class="value">{{ sidebarTitle }}</span>
                    </button>
                    <ul class="collapse" id="menu-left">
                        <li v-for="page in sidebarMenu">
                            <inertia-link :href="page.url" :class="{active: isActive(page)}">{{ page.text }}</inertia-link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="content-static">
                <img v-if="mainImage" :src="mainImage.url" alt="" class="detail-image lozad">
                <div v-html="pageData.text"></div>
            </div>
        </div>
    </div>
</template>

<script>

import {usePopLoader} from "../../scrollToTop";

export default {
    name: 'PageItem',
    props: {
        mainImage: Object,
        pageData: Object
    },
    setup() {
        const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
        return {
            popLoaderElement
        };
    },
    methods: {
        isActive(page) {
            return typeof document !== "undefined" && page.url === document.location.pathname;
        },
    },
    computed: {
        sidebarTitle() {
            return this.$page.props.layout.menus?.sidebar?.title || this.$store.state.layout.menus?.sidebar?.title || 'Меню';
        },
        sidebarMenu(){
            return this.$page.props.layout.menus?.sidebar?.items || this.$store.state.layout.menus?.sidebar?.items || [];
        }
    }
}
</script>

<style scoped>

</style>
