<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Умови повернення</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>Умови повернення</h1>
        </div>
        <div class="content-container d-lg-flex align-items-start">
            <div class="menu-left-container">
                <nav class="menu-left">
                    <button class="button-left d-flex d-lg-none align-items-center justify-content-center collapsed" data-toggle="collapse" href="#menu-left" role="button" aria-expanded="false" aria-controls="menu-left">
                        <span class="but-icon"></span>
                        <span class="value">Меню</span>
                    </button>
                    <ul class="collapse" id="menu-left">
                        <li><a href="#">Про компанію</a></li>
                        <li><a href="#">Доставка та оплата</a></li>
                        <li><a href="#">Наша команда</a></li>
                        <li><a href="#">Медичні експерти</a></li>
                        <li><a href="#">Контакти</a></li>
                        <li><a href="#">Відгуки про компанію</a></li>
                        <li><a href="#" class="active">Умови повернення</a></li>
                        <li><a href="#">Часті питання та відповіді</a></li>
                        <li><a href="#">Ліцензії</a></li>
                        <li><a href="#">Соціальні програми</a></li>
                        <li><a href="#">Редакційна політика</a></li>
                        <li><a href="#">Маркетингова політика</a></li>
                        <li><a href="#">Політика публікації відгуків</a></li>
                    </ul>
                </nav>
            </div>
            <div class="content-static">
                <h4>Умови повернення</h4>
                <p>"Лікарські засоби і вироби медичного призначення належної якості, відпущені з аптек та їх структурних підрозділів, поверненню не підлягають (п. 16 Порядку відпуску лікарських засобів і виробів медичного призначення з аптек та їх структурних підрозділів, затвердженого Наказом Міністерства охорони здоров'я України № 360 від 19.07.2005)". Поверненню не підлягають наступні групи товарів:</p>
                <ul class="design">
                    <li>лікарські засоби;</li>
                    <li>медичні інструменти;</li>
                    <li>медичні прилади та апаратура;</li>
                    <li>бандажі, ортопедичні вироби;</li>
                    <li>предмети санітарії;</li>
                    <li>засоби по догляду за немовлятами;</li>
                    <li>предмети повсякденного догляду;</li>
                    <li>предмети особистої гігієни;</li>
                    <li>косметичні засоби</li>
                </ul>
                <p>Це правило поширюється і на товари, що не були у вжитку, цілісність упаковки яких не порушена.<br /> Товари неналежної якості ви можете повернути або обміняти. Для цього просимо Вас звернутися до Контактного Центру, зателефонувавши за номером 6516165, скористатися формою Зворотного зв'язку або надіслати листа на адресу: <a href="mailto:office@konex.com.ua">office@konex.com.ua</a></p>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Return"
}
</script>

<style scoped>

</style>
