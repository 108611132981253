<template>
    <div class="menu-left-container lk">
        <div class="d-flex justify-content-between lk-left">
            <inertia-link v-if="false" :href="route('frontend.profile.aids')"
                          class="my-aptechka d-flex align-items-center">
                <span class="ic">
                    <v-icon icon="my" />
                </span>
                <span class="value">Моя аптечка</span>
            </inertia-link>

            <nav class="menu-left">
                <button class="button-left d-flex d-lg-none align-items-center justify-content-center collapsed"
                        data-toggle="collapse" href="#menu-left" role="button" aria-expanded="false"
                        aria-controls="menu-left">
                    <span class="but-icon"></span>
                    <span class="value">Меню</span>
                </button>

                <ul class="collapse" id="menu-left">
                    <template v-for="(menuLink, i) in menuLinks" :key="i">
                        <li v-if="!menuLink.hidden" :class="{active: isActive(menuLink.route)}">
                            <template v-if="menuLink.route">
                                <inertia-link 
                                    :href="route(menuLink.route)" 
                                    :onclick="menuLink.action ? menuLink.action : null" 
                                    class="d-flex align-items-center"
                                >
                                    <span class="ic" :class="menuLink.icon"></span>
                                    <span class="value">{{ menuLink.title }}</span>
                                </inertia-link>
                            </template>
                            <template v-else>
                                <button 
                                    :onclick="menuLink.action ? menuLink.action : null" 
                                    class="d-flex align-items-center"
                                >
                                    <span class="ic" :class="menuLink.icon"></span>
                                    <span class="value">{{ menuLink.title }}</span>
                                </button>
                            </template>
                        </li>
                    </template>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            menuLinks: [
                {
                    title: this.$t('Мій кабінет'),
                    icon: 'icon-lk-icon-1',
                    route: 'frontend.profile.index',
                },
                {
                    title: this.$t('Мої замовлення'),
                    icon: 'icon-lk-icon-3',
                    route: 'frontend.profile.orders',
                },
                {
                    title: this.$t('Мої відгуки'),
                    icon: 'icon-lk-icon-4',
                    route: 'frontend.profile.reviews',
                },
                {
                    title: this.$t('Запитання до товарів'),
                    icon: 'icon-lk-icon-5',
                    route: 'frontend.profile.questions',
                    hidden: true,
                },
                {
                    title: this.$t('Обрані товари'),
                    icon: 'icon-lk-icon-6',
                    route: 'frontend.profile.favorites',
                },
                {
                    title: this.$t('Обрані аптеки'),
                    icon: 'icon-lk-icon-7',
                    route: 'frontend.profile.farmacy',
                },
                {
                    title: this.$t('Реферальна программа'),
                    icon: 'icon-lk-icon-8',
                    route: 'frontend.profile.referal',
                    hidden: true,
                },
                {
                    title: this.$t('Клієнтська підтримка'),
                    icon: 'icon-lk-icon-9',
                    route: 'frontend.profile.support',
                },
                {
                    title: this.$t('Вийти'),
                    icon: 'icon-lk-icon-10',
                    action: () => { this.$store.dispatch('user/logout') },
                },
            ]
        }
    },
    mounted() {
        //console.log(this.route().current())

    },
    methods: {
        isActive(route) {
            //console.log(this.route().current() === route)
            return this.route().current() === route;
        }
    },
}
</script>
