/**
 *
 * @param Proxy proxy
 * @returns {*}
 */
import {isProxy} from "vue";
import _ from 'lodash';

export const proxyToArray = (proxy) => {
    if (_.isArray(proxy)) {
        return proxy;
    }

    if (isProxy((proxy)) || _.isObject(proxy)) {
        let newTarget = [];
        Object.keys(proxy).forEach(key => {
            newTarget.push(proxy[key]);
        })
        return newTarget;
    }


    return proxy;
}
