<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Наша команда</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>Наша команда</h1>
        </div>
        <div class="content-container d-lg-flex align-items-start">
            <div class="menu-left-container">
                <nav class="menu-left">
                    <button class="button-left d-flex d-lg-none align-items-center justify-content-center collapsed" data-toggle="collapse" href="#menu-left" role="button" aria-expanded="false" aria-controls="menu-left">
                        <span class="but-icon"></span>
                        <span class="value">Меню</span>
                    </button>
                    <ul class="collapse" id="menu-left">
                        <li><a href="#">Про компанію</a></li>
                        <li><a href="#">Доставка та оплата</a></li>
                        <li><a href="#" class="active">Наша команда</a></li>
                        <li><a href="#">Медичні експерти</a></li>
                        <li><a href="#">Контакти</a></li>
                        <li><a href="#">Відгуки про компанію</a></li>
                        <li><a href="#">Умови повернення</a></li>
                        <li><a href="#">Часті питання та відповіді</a></li>
                        <li><a href="#">Ліцензії</a></li>
                        <li><a href="#">Соціальні програми</a></li>
                        <li><a href="#">Редакційна політика</a></li>
                        <li><a href="#">Маркетингова політика</a></li>
                        <li><a href="#">Політика публікації відгуків</a></li>
                    </ul>
                </nav>
            </div>
            <div class="content-static">
                <div class="team-page">
                    <p>Вже давно відомо, що читабельний зміст буде заважати зосередитись людині, яка оцінює композицію сторінки. Сенс використання Lorem Ipsum полягає в тому, що цей текст має більш-менш нормальне розподілення літер на відміну від, наприклад, "Тут іде текст. Тут іде текст." Це робить текст схожим на оповідний. Багато програм верстування та веб-дизайну використовують Lorem Ipsum як зразок і пошук за терміном "lorem ipsum" відкриє багато веб-сайтів, які знаходяться ще в зародковому стані. Різні версії Lorem Ipsum з'явились за минулі роки, деякі випадково, деякі було створено зумисно (зокрема, жартівливі).</p>
                    <div class="team-items row no-gutters row-cols-1 row-cols-sm-2 row-cols-md-3">
                        <div class="col">
                            <div class="team-item" data-toggle="modal" data-target="#modal-team-1">
                                <div class="item-image">
                                    <span><img src="/images/team-1.jpg" alt="" class="lozad"></span>
                                </div>
                                <div class="item-name">Дем’яненко Андрій Володимирович</div>
                                <div class="item-specializ">Дерматолог</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="team-item" data-toggle="modal" data-target="#modal-team-1">
                                <div class="item-image">
                                    <span><img src="/images/team-2.jpg" alt="" class="lozad"></span>
                                </div>
                                <div class="item-name">Береза Віктор Йосипович</div>
                                <div class="item-specializ">Дерматолог</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="team-item" data-toggle="modal" data-target="#modal-team-1">
                                <div class="item-image">
                                    <span><img src="/images/team-3.jpg" alt="" class="lozad"></span>
                                </div>
                                <div class="item-name">Лопушанська Леся Юріївна</div>
                                <div class="item-specializ">Дерматолог</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="team-item" data-toggle="modal" data-target="#modal-team-1">
                                <div class="item-image">
                                    <span><img src="/images/team-1.jpg" alt="" class="lozad"></span>
                                </div>
                                <div class="item-name">Дем’яненко Андрій Володимирович</div>
                                <div class="item-specializ">Дерматолог</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="team-item" data-toggle="modal" data-target="#modal-team-1">
                                <div class="item-image">
                                    <span><img src="/images/team-2.jpg" alt="" class="lozad"></span>
                                </div>
                                <div class="item-name">Береза Віктор Йосипович</div>
                                <div class="item-specializ">Дерматолог</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="team-item" data-toggle="modal" data-target="#modal-team-1">
                                <div class="item-image">
                                    <span><img src="/images/team-3.jpg" alt="" class="lozad"></span>
                                </div>
                                <div class="item-name">Лопушанська Леся Юріївна</div>
                                <div class="item-specializ">Дерматолог</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="team-item" data-toggle="modal" data-target="#modal-team-1">
                                <div class="item-image">
                                    <span><img src="/images/team-1.jpg" alt="" class="lozad"></span>
                                </div>
                                <div class="item-name">Дем’яненко Андрій Володимирович</div>
                                <div class="item-specializ">Дерматолог</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="team-item" data-toggle="modal" data-target="#modal-team-1">
                                <div class="item-image">
                                    <span><img src="/images/team-2.jpg" alt="" class="lozad"></span>
                                </div>
                                <div class="item-name">Береза Віктор Йосипович</div>
                                <div class="item-specializ">Дерматолог</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="team-item" data-toggle="modal" data-target="#modal-team-1">
                                <div class="item-image">
                                    <span><img src="/images/team-3.jpg" alt="" class="lozad"></span>
                                </div>
                                <div class="item-name">Лопушанська Леся Юріївна</div>
                                <div class="item-specializ">Дерматолог</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Team"
}
</script>

<style scoped>

</style>
