<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Акційні пропозиції</span>
        </div>
        <div class="h1-containter d-md-flex justify-content-between align-items-center">
            <h1 class="mb-3">Акційні пропозиції</h1>

            <div class="mb-3">
                <inertia-link class="more-items" :href="route('frontend.catalog.discount-group', 'all')">
                    Переглянути всі
                </inertia-link>
            </div>
        </div>

        <div class="actions-block">
            <div class="row gutters-20 equal-height-columns">
                <div v-for="discount in discounts.data" class="col-12 col-md-6 col-lg-4">
                    <inertia-link :href="route('frontend.catalog.discount', ['all', discount.slug])"
                                  class="action-item equal-height-column">
                        <div class="action-image">
                            <img :src="discount.image_url" alt="" class="lozad">
                        </div>
                        <div class="action-date" v-if="discount.time_active">Акція діє до {{ discount.end_date }}</div>
                        <div class="action-name">{{ discount.catalog_title || discount.title }}</div>
                    </inertia-link>
                </div>
            </div>

            <div class="d-flex justify-content-center pt-3 mb-2">
                <inertia-link class="more-items" :href="route('frontend.catalog.discount-group', 'all')">
                    Переглянути всі
                </inertia-link>
            </div>
            <pagination :paginator="discounts" />


        </div>
    </div>
</template>

<script setup>
import { useRobotNoIndexFollow } from "../metaTags"
import { usePopLoader } from "../../scrollToTop";
import { defineAsyncComponent } from "vue";

const Pagination = defineAsyncComponent(() => import("../../Components/Pagination.vue"))

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
useRobotNoIndexFollow()
</script>


<script>
export default {
    name: "Discounts",
    props: {
        discounts: Object
    }
}
</script>

<style scoped>

</style>
