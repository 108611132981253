import axios from "../bootstrap/axios";

let cancelTokenSource = null;
const state = {
    countries: [],
    regions: [],
    listCities: [],
    cities: [],
    city: {},
    regionCentres: [],
    selectedCity: null,
    searchCityQuery: '',
    searchCityRequest: false,
}

const getters = {
    filteredCities(state) {
        let q = state.searchCityQuery.toLowerCase().trim();
        if (q.length > 0) {
            return state.cities;
        } else {
            return state.regionCentres.sort((a, b) => b.stores_count - a.stores_count);
        }
    }
}

const mutations = {
    setLocation(state, {countries, regions, cities}) {
        state.countries = countries || []
        state.regions = regions || []
        state.cities = Object.values(cities) || []
        state.regionCentres = Object.values(cities) || []
    },
    setSearchCityQuery(state, value) {
        state.searchCityQuery = value;
    },
    setSearchCityRequest(state, value) {
        state.searchCityRequest = value;
    },
    setCities(state, value) {
        state.cities = value;
    },
    setListCities(state, value) {
        state.listCities = value;
    },
}

const actions = {
    searchCities({commit, state}, payload = {}) {
        if (cancelTokenSource) {
            cancelTokenSource.cancel();
        }
        if (state.searchCityQuery.length > 2) {
            commit('setSearchCityRequest', true);

            cancelTokenSource = axios.CancelToken.source();

            axios.get('/api/v1/cities', {
                cancelToken: cancelTokenSource.token,
                params: {
                    cityWithStores: true,
                    q: payload.query || state.searchCityQuery,
                    limit: payload.limit || 24
                }
            })
                .then(({data}) => {
                    commit('setCities', data);
                    commit('setSearchCityRequest', false);
                })
                .catch((error) => {
                    console.log(error);
                    commit('setSearchCityRequest', false);
                })
        }
    },

    getCityWithStores({commit, state}, payload = {}) {
        axios.get('/api/v1/cities', {
            params: {
                cityWithStores: true,
                limit: payload.limit || 24
            }
        })
            .then(({data}) => {
                if (payload.listOnly) {
                    commit('setListCities', data);
                } else {
                    commit('setCities', data);
                }
                commit('setSearchCityRequest', false);
            })
            .catch((error) => {
                console.log(error);
                commit('setSearchCityRequest', false);
            })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
