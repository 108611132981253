<template>
    <div class="order-info" id="order-info" :style="dynamicStyle">

        <div class="order-info-header d-flex align-item-center justify-content-between">
            <div class="title">Ваше замовлення</div>
            <inertia-link :href="route('frontend.cart.index')" class="d-flex align-items-center edit-order">
                <span class="ic icon-edit"></span>
                <span class="value">Редагувати</span>
            </inertia-link>
        </div>

        <div class="order-items" :style="dynamicStyleItems">
            <div v-for="item in items" class="order-item">
                <div class="d-flex align-items-start">
                    <a :href="getProductProperty(item, 'url')" class="item-image">
                        <img :src="getProductProperty(item, 'image_url')" alt=""
                             class="lozad">
                    </a>

                    <div class="item-info">
                        <a :href="getProductProperty(item, 'url')"
                           class="item-name">{{ getProductProperty(item, 'name') }}</a>
                        <div class="d-flex align-item-center justify-content-between">
                            <div class="item-quant">{{ totalQuantity(item.totalQuantity) }} шт.</div>
                            <div class="item-price">
                                <span class="price-integer">{{ intItemPrice(item) }}</span>
                                <span class="price-decimal">{{ decItemPrice(item) }}</span>
                                <span class="price-currency"> грн</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="item.prescription" class="item-special">Продаж лише за рецептом</div>
            </div>
        </div>

        <div class="order-cashback">
            <div class="cashback-name" v-if="false">Використати кешбек</div>

            <div v-if="false" class="cashback-info d-flex align-items-center justify-content-between">
                <div class="cashback-text d-flex align-items-center">
                    <span class="ic"><v-icon icon="cashback" />
                        <span class="value">Повернемо 10%<br />від сумми, кешбеком</span>
                    </span></div>
                <div class="summ">{{ availableCashback }} грн</div>
            </div>

            <template v-if="isLoggedIn">
                <template v-if="false">
                    <div class="d-flex align-items-center cash-check">
                        <div class="checkbox">
                            <input v-model="useCashback" type="checkbox" name="cash" id="cash" checked>
                            <label for="cash">Оплатити кешбеком</label>
                        </div>

                        <input v-show="useCashback" v-model="cashback_used" type="text" class="input"
                               id="cashback-input">
                    </div>
                    <div v-show="useCashback" id="cash-slider"></div>
                </template>
            </template>

            <div v-if="!isLoggedIn && false" class="cashback-login">
                Для застосування накопиченого кешбеку або перегляду, будь ласка
                <a @click.prevent="$inertia.modal(route('frontend.auth.login'))"
                   href="#" data-toggle="modal" data-target="#modal-login">увійдіть</a>
            </div>

        </div>
        <div class="order-promo" v-if="false">
            <div class="promo-button d-flex align-items-center" data-toggle="collapse" href="#promo" role="button"

                 aria-expanded="false" aria-controls="promo">
                <span class="ic icon-promo"></span>
                <span class="value">Додати промокод</span>
            </div>
            <div class="collapse" id="promo">
                <div class="promo-container d-flex align-item-start">
                    <input type="text" class="input" value>
                    <span class="promo-submit">Примінити</span>
                </div>
            </div>
        </div>

        <div class="order-prices">
            <div class="prices-item d-flex align-items-center justify-content-between">
                <div class="data">Сума замовлення:</div>
                <div class="value">
                    <span class="price-integer">{{ intTotalPrice }}</span>
                    <span class="price-decimal">{{ decTotalPrice }}</span>
                    <span class="price-currency"> грн</span>
                </div>
            </div>
            <div class="prices-item d-flex align-items-center justify-content-between">
                <div class="data">Вартість доставки:</div>
                <div class="value">
                    <span class="price-integer">{{ intDeliveryPrice }}</span>
                    <span class="price-decimal">{{ decDeliveryPrice }}</span>
                    <span class="price-currency"> грн</span>
                </div>
            </div>
            <div class="prices-item summ d-flex align-items-center justify-content-between">
                <div class="data">Загальна сума:</div>
                <div class="value">
                    <span class="price-integer">{{ intOrderTotalPrice }}</span>
                    <span class="price-decimal">{{ decOrderTotalPrice }}</span>
                    <span class="price-currency"> грн</span>
                </div>
            </div>
        </div>

        <div class="checkbox" v-if="false">
            <input type="checkbox" name="regular" id="regular" v-model="regular">
            <label for="regular">
                З робити замовлення регулярним <span class="ic icon-informer regular-info"></span>
            </label>
        </div>

        <div class="condition-block">
            <div class="condition-block-content">
                Підтверджуючи замовлення, я приймаю умови
                <a target="_blank" href="/page/ugoda-koristuvaca">угоди користувача</a>
                і договору
                <a target="_blank" :href="offerUrl">публічної оферти</a>
            </div>
        </div>

        <button v-if="!isMobile" type="submit" :disabled="isFormValid && !isDisable ? null : 'disabled'" class="button submit">
            Підтверджую замовлення
        </button>

        <div v-if="isMobile" class="mobile-order-confirm" :style="mobileDynamicStyle">
            <button type="submit" :disabled="isFormValid && !isDisable ? null : 'disabled'" class="button submit">
                Підтверджую замовлення
            </button>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        valid: {
            type: Boolean,
            default: false,
        },
        isDisable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mobileStyle: { position: 'fixed' },
            scrollPosition: 0,
            topPosition: 0,
            lastPosition: 0,
        };
    },
    mounted() {

        if (this.items) {
            const products =  this.items
            const items = []
            products.forEach((p) => {
                items.push({
                    item_id: p.product_id,
                    item_name: p.name,
                    price: parseFloat(p.price *  p.qty).toFixed(2),
                    quantity: p.qty
                })
            })

            this.$gtag.event('begin_checkout',  { "ecommerce":
                {
                    currency: "UAH",
                    value: this.getTotal,
                    items: items
                }
            });
        }

        this.emitter.on('scroll-position', async (val) => {
            if (!this.isMobile) {
                let orderInfo = document.getElementById('order-info')
                let lastElement = document.getElementById('recipient')

                if (val > 280 && (orderInfo.getBoundingClientRect().bottom <= lastElement.getBoundingClientRect().bottom || this.lastPosition > val)) {
                    this.lastPosition = val
                    this.topPosition = val - 280
                }
                if (val <= 280) {
                    this.lastPosition = 0
                    this.topPosition = 0
                }
            }

            if (this.isMobile) {
                this.scrollPosition = val
            }
        })
    },
    computed: {
        mobileDynamicStyle() {
            return this.mobileStyle
        },
        isMobile() {
            return window.innerWidth <= 768
        },
        dynamicStyle() {
            return {
                position: this.isMobile ? '' : 'relative',
                top: this.isMobile ? '' : this.topPosition+'px',
            }
        },
        dynamicStyleItems() {
            return {
                height: this.isMobile ? '' : '140px',
                'overflow-y': 'auto'
            }
        },
        offerUrl() {
            return this.$store.getters['order/urlOffer']
        },
        items() {
            return this.$store.state.cart.items
        },
        isLoggedIn() {
            return this.$store.state.user.isLoggedIn
        },
        totalPrice() {
            return this.$store.getters['cart/getTotal']
        },
        intTotalPrice() {
            const price = this.totalPrice
            return Math.floor(price)
        },
        decTotalPrice() {
            const price = 1 * this.totalPrice
            return `.${price.toFixed(2).slice(-2)}`
        },
        availableCashback() {
            return this.$store.getters['cart/getCashback']
        },
        deliveryType() {
            return this.$store.getters['order/deliveryType']
        },
        paymentType() {
            return this.$store.getters['order/paymentType']
        },
        isFormValid() {
            return this.$store.getters['order/isFormValid']  && this.valid
        },
        deliveryPrice() {
            return this.deliveryType?.min_price || 0
        },
        intDeliveryPrice() {
            const price = this.deliveryPrice
            return Math.floor(price)
        },
        decDeliveryPrice() {
            const price = 1 * this.deliveryPrice
            return `.${price.toFixed(2).slice(-2)}`
        },
        getTotal() {
            const cashback = this.useCashback ? this.cashback_used : 0.0;
            return (this.totalPrice - cashback + this.deliveryPrice).toFixed(2)
        },
        intOrderTotalPrice() {
            const price = this.getTotal
            return Math.floor(price)
        },
        decOrderTotalPrice() {
            const price = 1 * this.getTotal
            return `.${price.toFixed(2).slice(-2)}`
        },
        useCashback: {
            get() {
                return this.$store.state.order.payment.cashback;
            },
            set(value) {
                this.$store.commit('order/UPDATE_PAYMENT', {cashback: value})
            }
        },
        cashback_used: {
            get() {
                return this.$store.state.order.payment.cashback_used
            },
            set(value) {
                this.$store.commit('order/UPDATE_PAYMENT', {cashback_used: value})
            }
        },
        regular: {
            get() {
                return this.$store.state.order.personal.regular
            },
            set(value) {
                this.$store.commit('order/UPDATE_PERSONAL', {regular: value})
            }
        },
    },
    watch: {
        useCashback(val) {
            if (!val) {
                this.cashback_used = 0
            }
        },
        scrollPosition(newValue) {
            if (newValue >= 10) {
                const orderInfo = document.getElementById('order-info')
                if (orderInfo) {
                    let position = Math.floor(orderInfo.getBoundingClientRect().top)

                    if (position <= 250) {
                        this.mobileStyle = {position: 'relative', 'box-shadow': '0px 0px 0px rgba(0, 0, 0, 0.3)!important'}
                    } else {
                        this.mobileStyle = {position: 'fixed'}
                    }
                }
            }
        },
    },
    methods: {
        totalQuantity(totalQuantity) {
            if (Number.isInteger(totalQuantity)) {
                return totalQuantity
            }
            return parseFloat(totalQuantity).toFixed(2).toString().trim().replace(/\.?0+$/, "")
        },
        getProductProperty(item, property) {
            return this.$store.getters['cart/getProduct'](item, property)
        },
        getProductSubtotal(item) {
            return this.$store.getters['cart/getSubTotal'](item)
        },
        intItemPrice(item) {
            const price = this.getProductSubtotal(item)
            return Math.floor(price)
        },
        decItemPrice(item) {
            const price = 1 * this.getProductSubtotal(item)
            return `.${price.toFixed(2).slice(-2)}`
        },
    }
}
</script>

<style lang="scss" scoped>
  .order-info .order-cashback {
      margin: 0 0 10px 0;
  }
</style>