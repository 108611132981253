import { createI18n } from 'vue-i18n'

import uk from '../../../lang/uk';
import ru from '../../../lang/ru';

const locale = 'uk'
const fallbackLocale = 'ru'

const i18n = createI18n({
    locale,
    fallbackLocale,
    messages: {
        uk,
        ru,
    },
    silentFallbackWarn: true,
    silentTranslationWarn: true
})

export default i18n
