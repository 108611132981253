const isProd = process.env.NODE_ENV === 'production'
const isSSR = typeof window === 'undefined'

import store from './store'
import {createApp, h} from 'vue'
import {Link, Head} from '@inertiajs/vue3'
import {createEventBus} from './Plugins/eventBus'
import i18n from './Plugins/i18n'
import clickOutside from "./Plugins/clickOutside"
import HydrateStoreFromInertia from "./Helpers/HydrateStoreFromInertia";
import SvgVue from 'svg-vue3'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import route from './ziggy'
import VueSocialSharing from 'vue-social-sharing'
import VueGtag from "vue-gtag-next";
import mitt from 'mitt';

const emitter = mitt();

const createVueApp = function ({App, props}) {
    const app = createApp({
        i18n,
        store,
        beforeMount() {
            let vm = this

            store.$inertia = vm.$inertia
            store.$eventBus = vm.$eventBus

            vm.$inertia.on('success', (event) => {
                HydrateStoreFromInertia(event.detail.page)
            })
        },
        mounted() {
        },
        render: () => h(App, props),
    })

    app.config.globalProperties.isProd = isProd;
    app.config.globalProperties.isSSR = isSSR;

    const Ziggy = {
        // Pull the Ziggy config off of the props.
        ...props.initialPage.props.ziggy,
        // Build the location, since there is
        // no window.location in Node.
        location: new URL(props.initialPage.props.ziggy.url)
    }
    const Route = (name, params, absolute, config = Ziggy) => route(name, params, absolute, config)
    app.config.globalProperties.route = Route
    app.mixin({
        created() {
            this.$inertia.route = Route
        },
        methods: {
            route: Route,
        }
    })

    app.use(store)
    app.use(i18n)
    app.use(clickOutside)
    app.use(createEventBus)
    app.use(SvgVue)
    app.use(VueSocialSharing)

    app.component('InertiaLink', Link)
    app.component('InertiaHead', Head)

    app.use(PerfectScrollbar)
    app.use(VueGtag, {
        isEnabled: true,
        property: { id: "GTM-KCNKLSN" },
    })

    app.config.globalProperties.emitter = emitter;
    return app
}


export default createVueApp
