<template>
    <div class="personal-page">
        <div class="personal-container">
            <div class="personal-reviews">
                <h3>Мої відгуки</h3>
                <div class="reviews-items">
                    <div class="review-item">
                        <div class="review-product  d-sm-flex align-items-start">
                            <a href="#" class="product-image">
                                <img src="/images/rev-2.png" alt="" class="lozad">
                            </a>
                            <div class="product-info">
                                <div class="raiting d-flex">
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span class="star-full icon-star"></span>
                                    </div>
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span class="star-full icon-star"></span>
                                    </div>
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span class="star-full icon-star"></span>
                                    </div>
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span class="star-full icon-star"></span>
                                    </div>
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span style="width:0;" class="star-full icon-star"></span>
                                    </div>
                                </div>
                                <a href="#" class="name">Церегліа капсули м’які 400 мг №30</a>
                                <div class="product-prices d-flex align-items-center">
                                    <div class="old-price"><span>300</span></div>
                                    <div class="sale">-15%</div>
                                    <div class="price">1 322 <span>грн</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="d-sm-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
                                <div class="date">10 Травня 2021</div>
                            </div>
                        </div>
                        <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-sm-flex">
                                <button class="more-answer" data-toggle="collapse" href="#answers-1" role="button" aria-expanded="false" aria-controls="answers-1"><span class="value">Ще 12 відповідей</span></button>
                            </div>
                            <div class="review-likes d-flex align-items-center">
                                <span class="like d-flex align-items-center">
                                    <span class="ic icon-like"><span class="path1"></span><span class="path2"></span></span>
                                    <span class="value">6</span>
                                </span>
                            </div>
                        </div>
                        <div class="review-answers">
                            <div class="review-answers-item">
                                <div class="review-answers-top d-sm-flex align-items-center justify-content-between">
                                    <div class="review-answers-name d-flex align-items-center">
                                        <div class="review-answers-icon"><img src="/images/answer.jpg" alt="" class="lozad"></div>
                                        <div class="review-answers-names">
                                            <div class="name-anons">Представник магазину</div>
                                            <div class="name">Вікторія Дем’яненко</div>
                                        </div>
                                    </div>
                                    <div class="date">10 Травня 2021</div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                            </div>
                        </div>
                        <div class="review-answers items collapse" id="answers-1">
                            <div class="review-answers-item">
                                <div class="review-answers-top d-sm-flex align-items-center justify-content-between">
                                    <div class="review-answers-name d-flex align-items-center">
                                        <div class="review-answers-icon"><img src="/images/answer.jpg" alt="" class="lozad"></div>
                                        <div class="review-answers-names">
                                            <div class="name-anons">Представник магазину</div>
                                            <div class="name">Вікторія Дем’яненко</div>
                                        </div>
                                    </div>
                                    <div class="date">10 Травня 2021</div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                            </div>
                            <div class="review-answers-item">
                                <div class="review-answers-top d-flex align-items-center justify-content-between">
                                    <div class="review-answers-name d-sm-flex align-items-center">
                                        <div class="review-answers-icon"><img src="/images/answer.jpg" alt="" class="lozad"></div>
                                        <div class="review-answers-names">
                                            <div class="name-anons">Представник магазину</div>
                                            <div class="name">Вікторія Дем’яненко</div>
                                        </div>
                                    </div>
                                    <div class="date">10 Травня 2021</div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                            </div>
                        </div>
                    </div>
                    <div class="review-item">
                        <div class="review-product  d-sm-flex align-items-start">
                            <a href="#" class="product-image">
                                <img src="/images/rev-3.png" alt="" class="lozad">
                            </a>
                            <div class="product-info">
                                <div class="raiting d-flex">
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span class="star-full icon-star"></span>
                                    </div>
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span class="star-full icon-star"></span>
                                    </div>
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span class="star-full icon-star"></span>
                                    </div>
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span class="star-full icon-star"></span>
                                    </div>
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span style="width:0;" class="star-full icon-star"></span>
                                    </div>
                                </div>
                                <a href="#" class="name">Церегліа капсули м’які 400 мг №30</a>
                                <div class="product-prices d-flex align-items-center">
                                    <div class="old-price"><span>300</span></div>
                                    <div class="sale">-15%</div>
                                    <div class="price">1 322 <span>грн</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="d-sm-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
                                <div class="date">10 Травня 2021</div>
                            </div>
                        </div>
                        <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                        <div class="review-images row no-gutters">
                            <div class="col">
                                <a href="/images/rev1.jpg" class="review-image" data-fancybox="rev2"><img src="/images/rev1.jpg" alt="" class="lozad" data-loaded="true"></a>
                            </div>
                            <div class="col">
                                <a href="/images/rev2.jpg" class="review-image" data-fancybox="rev2"><img src="/images/rev2.jpg" alt="" class="lozad" data-loaded="true"></a>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-sm-flex">
                                <button class="more-answer" data-toggle="collapse" href="#answers-2" role="button" aria-expanded="false" aria-controls="answers-2"><span class="value">Ще 2 відповідей</span></button>
                            </div>
                            <div class="review-likes d-flex align-items-center">
                                <span class="like d-flex align-items-center">
                                    <span class="ic icon-like"><span class="path1"></span><span class="path2"></span></span>
                                    <span class="value">6</span>
                                </span>
                            </div>
                        </div>
                        <div class="review-answers">
                            <div class="review-answers-item">
                                <div class="review-answers-top d-sm-flex align-items-center justify-content-between">
                                    <div class="review-answers-name d-flex align-items-center">
                                        <div class="review-answers-icon"><img src="/images/answer.jpg" alt="" class="lozad"></div>
                                        <div class="review-answers-names">
                                            <div class="name-anons">Представник магазину</div>
                                            <div class="name">Вікторія Дем’яненко</div>
                                        </div>
                                    </div>
                                    <div class="date">10 Травня 2021</div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                            </div>
                        </div>
                        <div class="review-answers items collapse" id="answers-2">
                            <div class="review-answers-item">
                                <div class="review-answers-top d-sm-flex align-items-center justify-content-between">
                                    <div class="review-answers-name d-flex align-items-center">
                                        <div class="review-answers-icon"><img src="/images/answer.jpg" alt="" class="lozad"></div>
                                        <div class="review-answers-names">
                                            <div class="name-anons">Представник магазину</div>
                                            <div class="name">Вікторія Дем’яненко</div>
                                        </div>
                                    </div>
                                    <div class="date">10 Травня 2021</div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                            </div>
                            <div class="review-answers-item">
                                <div class="review-answers-top d-flex align-items-center justify-content-between">
                                    <div class="review-answers-name d-sm-flex align-items-center">
                                        <div class="review-answers-icon"><img src="/images/answer.jpg" alt="" class="lozad"></div>
                                        <div class="review-answers-names">
                                            <div class="name-anons">Представник магазину</div>
                                            <div class="name">Вікторія Дем’яненко</div>
                                        </div>
                                    </div>
                                    <div class="date">10 Травня 2021</div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                            </div>
                        </div>
                    </div>
                    <div class="review-item">
                        <div class="review-product  d-sm-flex align-items-start">
                            <a href="#" class="product-image">
                                <img src="/images/rev-2.png" alt="" class="lozad">
                            </a>
                            <div class="product-info">
                                <div class="raiting d-flex">
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span class="star-full icon-star"></span>
                                    </div>
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span class="star-full icon-star"></span>
                                    </div>
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span class="star-full icon-star"></span>
                                    </div>
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span class="star-full icon-star"></span>
                                    </div>
                                    <div class="star">
                                        <span class="star-empty icon-star"></span>
                                        <span style="width:0;" class="star-full icon-star"></span>
                                    </div>
                                </div>
                                <a href="#" class="name">Церегліа капсули м’які 400 мг №30</a>
                                <div class="product-prices d-flex align-items-center">
                                    <div class="old-price"><span>300</span></div>
                                    <div class="sale">-15%</div>
                                    <div class="price">1 322 <span>грн</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="d-sm-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
                                <div class="date">10 Травня 2021</div>
                            </div>
                        </div>
                        <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-sm-flex">
                                <button class="more-answer" data-toggle="collapse" href="#answers-3" role="button" aria-expanded="false" aria-controls="answers-3"><span class="value">Ще 12 відповідей</span></button>
                            </div>
                            <div class="review-likes d-flex align-items-center">
                                <span class="like d-flex align-items-center">
                                    <span class="ic icon-like"><span class="path1"></span><span class="path2"></span></span>
                                    <span class="value">6</span>
                                </span>
                            </div>
                        </div>
                        <div class="review-answers">
                            <div class="review-answers-item">
                                <div class="review-answers-top d-sm-flex align-items-center justify-content-between">
                                    <div class="review-answers-name d-flex align-items-center">
                                        <div class="review-answers-icon"><img src="/images/answer.jpg" alt="" class="lozad"></div>
                                        <div class="review-answers-names">
                                            <div class="name-anons">Представник магазину</div>
                                            <div class="name">Вікторія Дем’яненко</div>
                                        </div>
                                    </div>
                                    <div class="date">10 Травня 2021</div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                            </div>
                        </div>
                        <div class="review-answers items collapse" id="answers-3">
                            <div class="review-answers-item">
                                <div class="review-answers-top d-sm-flex align-items-center justify-content-between">
                                    <div class="review-answers-name d-flex align-items-center">
                                        <div class="review-answers-icon"><img src="/images/answer.jpg" alt="" class="lozad"></div>
                                        <div class="review-answers-names">
                                            <div class="name-anons">Представник магазину</div>
                                            <div class="name">Вікторія Дем’яненко</div>
                                        </div>
                                    </div>
                                    <div class="date">10 Травня 2021</div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                            </div>
                            <div class="review-answers-item">
                                <div class="review-answers-top d-flex align-items-center justify-content-between">
                                    <div class="review-answers-name d-sm-flex align-items-center">
                                        <div class="review-answers-icon"><img src="/images/answer.jpg" alt="" class="lozad"></div>
                                        <div class="review-answers-names">
                                            <div class="name-anons">Представник магазину</div>
                                            <div class="name">Вікторія Дем’яненко</div>
                                        </div>
                                    </div>
                                    <div class="date">10 Травня 2021</div>
                                </div>
                                <div class="review-item__text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение </div>
                                <button class="answer-link" data-toggle="modal" data-target="#modal-addcomment">Відповісти</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button class="more-items d-inline-flex align-items-center">
                        <span class="value">Показати ще</span>
                        <span class="ic icon-more"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProfileLayout from "./ProfileLayout.vue";

export default {
    layout: ProfileLayout,
}
</script>

<style scoped>

</style>
