<template>
    <div class="container-fluid">

        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Кошик</span>
        </div>
        <div class="h1-containter d-lg-flex align-items-end">
            <h1>Оформлення замовлення</h1>
        </div>


        <div class="order-page">
            <div class="order-inner">
                <form @submit.prevent="submit" class="form">
                    <div class="d-xl-flex align-items-start">
                        <div class="order-form" :style="dynamicStyleWidth">

                            <order-personal-data />

                            <order-delivery :address="address"></order-delivery>

                            <order-payment />

                            <order-recipient />

                        </div>

                        <order-info-block :valid="checkOrder" :is-disable="isDisable" />
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>


<script>
import OrderInfoBlock from "../../Components/Order/OrderInfoBlock.vue";
import OrderPersonalData from "../../Components/Order/OrderPersonalData.vue";
import OrderPayment from "../../Components/Order/OrderPayment.vue";
import OrderDelivery from "../../Components/Order/OrderDelivery.vue";
import OrderRecipient from "../../Components/Order/OrderRecipient.vue";
import { usePopLoader } from "../../scrollToTop";

export default {
    components: {
        OrderRecipient,
        OrderDelivery,
        OrderPayment,
        OrderPersonalData,
        OrderInfoBlock
    },
    props: {
        address: {
            type: [Array, Object]
        },
        errors: {
            type: [Array, Object]
        },
        deliveryTypes: {
            type: [Array, Object]
        },
        paymentTypes: {
            type: [Array, Object]
        },
        stores: {
            type: [Array, Object]
        },
        cities: {
            type: [Array, Object]
        },
        location: {
            type: Object
        },
        sessionOrder: {
            type: Object
        },
    },
    data() {
        return {
            footer: 0,
            reCheck: false,
            isDisable: false,
        };
    },
    setup() {
        const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
        return {
            popLoaderElement
        }
    },

    created() {
        this.$store.commit('order/INIT', {
            errors: this.errors || {},
            deliveryTypes: this.deliveryTypes,
            paymentTypes: this.paymentTypes,
            stores: this.stores,
            order: this.sessionOrder,
        })
    },

    computed: {
        isLoggedIn() {
            return this.$store.state.user.isLoggedIn;
        },
        isMobile() {
            return window.innerWidth <= 768;
        },
        dynamicStyleWidth() {
            return {
                width: this.isMobile ? '100% !important' : '',
            }
        },
        formData() {
            return this.$store.getters['order/formData'];
        },
        hasPersonalData() {
            const error = !!this.$store.state.order.errors.phone
            if (this.formData.phone) {
                const strippedPhoneNumber = this.formData.phone.replace(/_/g, '')
                return !error && this.formData.phone && strippedPhoneNumber.length >= 19 && this.formData.firstname && this.formData.firstname.length >= 3
            }
            return false
        },
        hasDelivery() {
            return this.formData.delivery.city_id > 0
        },
        hasPaymentType() {
            if (this.formData.payment.type)
                return this.formData.payment.type.length > 0

            return false
        },
        checkOrder() {
            if (this.hasPersonalData && this.hasDelivery && this.hasPaymentType) {
                this.$store.dispatch('order/checkOrder', this.formData)
            }
            if (this.reCheck) {
                this.$store.dispatch('order/checkOrder', this.formData)
            }
            return this.$store.getters['order/checked']
        },
    },

    mounted() {
        this.emitter.on('current-delivery-type', async (val) => {
            await this.$store.dispatch('order/availableStores', {
                cityId: this.$store.getters['order/deliveryCityId'],
                deliveryType: val,
            })
        })

        let tagNoIndexFollow = document.createElement('meta')
        tagNoIndexFollow.setAttribute('id', 'robot-noindex-nofollow')
        tagNoIndexFollow.setAttribute('name', 'robots')
        tagNoIndexFollow.setAttribute('content', 'noindex, nofollow')
        document.head.appendChild(tagNoIndexFollow)

        this.$store.dispatch('cart/loadProductTypes')
        this.$store.dispatch('order/loadDeliveryTypes')
        this.$store.dispatch('order/loadPaymentMethods')

        this.emitter.on('check-order', async () => {
            await this.$store.dispatch('order/availableStores', {
                cityId: this.$store.getters['order/deliveryCityId'],
                deliveryType: this.$store.state.order.delivery.type,
            }).then(() => {
                this.$store.dispatch('markets/initMapStatus', true)
            })
            this.reCheck = true

            setTimeout(() => {
                this.reCheck = false
            }, 500)
        })

        this.$store.dispatch('markets/getPoints')
        this.$store.dispatch('markets/getMarkets')
    },

    beforeUnmount() {
        this.isDisable = false
        let tagNoIndexFollow = document.getElementById('robot-noindex-nofollow')
        tagNoIndexFollow.remove()
    },

    methods: {
        submit() {
            this.isDisable = true
            this.formData.confirm = true
            this.formData.phone = this.formData.phone.replace(/[()\-\s]/g, '')
            if (this.formData.recipient.type === 0) {
                this.formData.recipient.email = ""
                this.formData.recipient.firstname = ""
                this.formData.recipient.lastname = ""
                this.formData.recipient.phone = ""
            } else {
                this.formData.recipient.phone = this.formData.recipient.phone.replace(/[()\-\s]/g, '')
            }

            this.$inertia.post(this.route('frontend.order.apply'), this.formData)
        },
    }
}
</script>

<style lang="scss" scoped>
  .order-page {
    position: relative;
  }
</style>
