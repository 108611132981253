<script setup>
import { useRobotNoIndexFollow } from "../metaTags"
import { usePopLoader } from "../../scrollToTop";

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
useRobotNoIndexFollow()
</script>

<script>
import axios from "axios";

import ProfileLayout from "./ProfileLayout.vue";

import { defineAsyncComponent, ref } from "vue";

const messageRef = ref(null);

export default {
    components: {
        Review: defineAsyncComponent(() => import("../../Components/Profile/Review.vue")),
        Pagination: defineAsyncComponent(() => import("../../Components/Pagination.vue")),
        ModalMessage: defineAsyncComponent(() => import("../../Components/ModalMessage.vue")),
    },
    layout: ProfileLayout,
    props: {
        paginator: [Object],
        reviews: Array
    },
    data() {
        return {
            reviews: this.paginator.data || [],
            showConfirmation: true,
            reviewToDelete: null,
        }
    },
    mounted() {
        let tagNoIndexFollow = document.createElement('meta')
        tagNoIndexFollow.setAttribute('id', 'robot-noindex-nofollow')
        tagNoIndexFollow.setAttribute('name', 'robots')
        tagNoIndexFollow.setAttribute('content', 'noindex, nofollow')
        document.head.appendChild(tagNoIndexFollow)
    },

    beforeUnmount() {
        let tagNoIndexFollow = document.getElementById('robot-noindex-nofollow')
        tagNoIndexFollow.remove()
    },
    methods: {
        nextPage(link) {
            let baseUrl = link?.url || link || this.route('frontend.profile.reviews');

            this.$inertia.get(baseUrl, {
                search: this.query || '',
            }, {
                preserveScroll: false,
                preserveState: true,
                replace: false,
                only: ['paginator']
            });
        },
        loadMore() {
            if (this.paginator.next_page_url === null) {
                return
            }

            this.$inertia.get(this.paginator.next_page_url, {}, {
                preserveState: true,
                preserveScroll: false,
                only: ['paginator'],
                onSuccess: () => {
                    this.reviews = [...this.reviews, ...this.paginator.data]
                }
            })
        },
        showDeleteConfirmation(modelType, review) {
            if (messageRef.value) {
                messageRef.value.show();
            }
            
            this.reviewToDelete = review;
            this.reviewToDelete.modelType = modelType;
        },
        confirmDelete() {
            if (messageRef.value) {
                messageRef.value.hide()
            }

            axios.delete(this.route('frontend.review.delete', [this.reviewToDelete.modelType, this.reviewToDelete.model_id]), {
                params: {
                    reviewId: this.reviewToDelete.id,
                }
            }).then(response => {
                if(response.status === 204) {
                    this.reviews = this.reviews.filter(review => review.id !== this.reviewToDelete.id);
                }

                this.reviewToDelete = null;
            });  
        },
        cancelDelete() {
            if (messageRef.value) {
                messageRef.value.hide()
            }

            this.reviewToDelete = null;
        },
    }
}

</script>

<template>
    <div class="personal-page">
        <div class="personal-container">
            <div class="personal-reviews">
                <h3>Мої відгуки</h3>
                <div class="reviews-items" v-if="reviews.length">
                    <template v-for="review in reviews" :key="review.id">
                        <review :review="review" @delete-review="showDeleteConfirmation"></review>
                    </template>
                </div>
                <div class="text-center">
                    <pagination v-if="reviews.length > 0" :paginator="paginator" :on-link-click="nextPage" @load-more="loadMore" />

                    <div v-if="reviews.length === 0">Ви ще не залишали відгуків</div>
                </div>
            </div>
        </div>
    </div>

    <ModalMessage ref="messageRef">
        Ви точно хочете видалити відгук на <b>{{ reviewToDelete?.model.short_name || reviewToDelete?.model.name }}</b>?
        <slot>
            <div class="d-flex align-items-center justify-content-center">
                <button type="button" class="btn-modal" @click.prevent="confirmDelete">Тaк</button>
                <button type="button" class="btn-modal" @click.prevent="cancelDelete">Hi</button>
            </div>
        </slot>
    </ModalMessage>
</template>

<style scoped>

</style>
