<template>
    <div class="container-fluid">
        <div class="bredscrumps" id="breadcrumbs">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Кошик</span>
        </div>
        <div class="thankyou-page">
            <div class="thankyou-success">
                <h1>Дякуємо за замовлення на eTabletka.ua</h1>
                <div class="thankyou-text">
                    Очікуйте на вказаний Вами номер телефону SMS-повідомлення про готовність замовлення <span>#{{ order.id }}</span>!
                    {{ deliveryDate ? 'Приблизна дата доставки замовлення,' + deliveryDate : '' }}
                    Отримання чеку відбувається під час відбору замовлення.
                </div>
                <div class="thankyou-image">
                    <v-icon icon="thankyou" />
                    <a href="/" class="button">Перейти на головну сторінку</a>
                </div>
            </div>

            <div class="order-table-container">
                <div class="title">Ваше замовлення #{{ order.id }}</div>
                <div class="order-table">
                    <div class="order-table-item item-header d-flex align-items-center">
                        <div class="item-n"></div>
                        <div class="item-image"></div>
                        <div class="item-tds d-flex align-items-center">
                            <div class="item-name">Назва товару</div>
                            <div class="item-kod">Код товару</div>
                            <div class="item-quant">Кількість</div>
                            <div class="item-price">Ціна</div>
                            <div class="item-skidka" v-if="false">Знижка за товар</div>
                            <div class="item-summ">Сума</div>
                        </div>
                    </div>

                    <div v-for="(item, idx) in order.items" class="order-table-item d-lg-flex align-items-center">
                        <div class="item-n">{{ idx + 1 }}</div>
                        <div class="item-image">
                            <img :src="item.product.image_url" alt="" class="lozad">
                        </div>
                        <div class="item-tds d-lg-flex align-items-center">
                            <div class="item-name">{{ item.product.name }}</div>
                            <div class="item-kod">
                                <span class="d-inline-block d-lg-none m-span d-lg-none">Код: </span>
                                {{ item.product.vendor_code }}
                            </div>
                            <div class="item-quant">
                                <span class="d-inline-block d-lg-none m-spand-lg-none">Кількість: </span>
                                {{ totalQuantity(item.quantity) }}
                            </div>
                            <div class="item-price">
                                <span class="d-inline-block d-lg-none m-span d-lg-none">Ціна: </span>
                                <span class="price-integer">{{ Math.floor(item.price) }}</span>
                                <span class="price-decimal">{{ `.${item.price.toFixed(2).slice(-2)}` }}</span>
                                <span class="price-currency"> грн</span>
                            </div>
                            <div class="item-skidka" v-if="false">
                                <span class="d-inline-block d-lg-none m-span d-lg-none">Знижка: </span>
                                {{ 0 }} грн
                            </div>
                            <div class="item-summ">
                                <span class="d-inline-block d-lg-none m-span d-lg-none">Сума: </span>
                                <span class="price-integer">{{ Math.floor(item.price * item.quantity) }}</span>
                                <span class="price-decimal">{{ `.${(item.price * item.quantity).toFixed(2).slice(-2)}` }}</span>
                                <span class="price-currency"> грн</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="order-table-summ d-sm-flex align-item-center justify-content-center justify-content-lg-end">
                    <div class="summ-item">
                        <span class="data">Сума замовлення:</span>
                        <span class="value">
                            <span class="price-integer">{{ Math.floor(order.total) }}</span>
                            <span class="price-decimal">{{ `.${order.total.toFixed(2).slice(-2)}` }}</span>
                            <span class="price-currency"> грн</span>
                        </span>
                    </div>
                    <div class="summ-item" v-if="order.total_shipping > 0">
                        <span class="data">Вартість доставки:</span>
                        <span class="value">{{ order.total_shipping }} грн</span>
                    </div>
                    <div class="summ-item" v-if="order.total_discount > 0">
                        <span class="data">Знижка:</span>
                        <span class="value">{{ order.total_discount }} грн</span>
                    </div>
                </div>
                <div class="order-table-itogo text-center text-lg-right">
                    <span class="data">Загальна сума:</span>
                    <span class="value">
                        <span class="price-integer">{{ intOrderTotalPrice }}</span>
                        <span class="price-decimal">{{ decOrderTotalPrice }}</span>
                        <span class="price-currency"> грн</span>
                    </span>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { usePopLoader } from "../../scrollToTop";
import { defineAsyncComponent } from "vue";

const VIcon = defineAsyncComponent(() => import("@/frontend/Components/Common/VIcon.vue"));

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
</script>

<script>
export default {
    props: {
        cart: {
            type: Array,
            default() {
                return []
            }
        },
        order: {
            type: Object
        },
        deliveryDate: {
            type: String
        },
    },
    data() {
        return {
            timerId: null
        }
    },
    created() {
        this.$store.commit('cart/setCart', this.cart)
    },
    mounted() {
        const items = []
        this.order.items.forEach((i) => {
            items.push({
                item_id: i.product_id,
                item_name: i.product.name,
                price: i.product.price,
                quantity: i.quantity,
            })
        })
        this.$gtag.purchase( { "ecommerce":
            {
                currency: "UAH",
                transaction_id: this.order.id,
                value: this.order.total,
                items: items
            }
        })
        // this.$gtag.event('purchase', {
        //     currency: "UAH",
        //     transaction_id: this.order.id,
        //     value: this.order.total,
        //     items: items
        // })
    },
    computed: {
        email() {
            return this.order.data?.email || '';
        },
        orderTotal() {
            return this.order.total - this.order.total_discount + this.order.total_shipping
        },
        intOrderTotalPrice() {
            const price = this.orderTotal
            return Math.floor(price)
        },
        decOrderTotalPrice() {
            const price = 1 * this.orderTotal
            return `.${price.toFixed(2).slice(-2)}`
        },
    },
    methods: {
        totalQuantity(quantity) {
            if (Number.isInteger(quantity)) {
                return quantity
            }
            return parseFloat(quantity).toFixed(2).toString().trim().replace(/\.?0+$/, "")
        },
    }
}
</script>

<style scoped>

</style>
