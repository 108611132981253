<template>
    <div class="container-radio" id="cont-pay-6">
        <div class="radio-inner">
            <div class="kredit-container d-md-flex align-items-start">
                <div class="kredit-slider">
                    <div class="kredit-termin">
                        Термін кредиту: <input type="text" name="srok"
                                               id="kredit-termin">
                    </div>
                    <div class="slider-bg">
                        <div id="kredit-slider"></div>
                    </div>
                    <div
                        class="shkala d-flex align-items-start justify-content-between">
                        <div class="shkala-item">
                            <span></span>
                            <div class="shkala-n">2</div>
                        </div>
                        <div class="shkala-item"><span></span></div>
                        <div class="shkala-item"><span></span></div>
                        <div class="shkala-item"><span></span></div>
                        <div class="shkala-item">
                            <span></span>
                            <div class="shkala-n">12</div>
                        </div>
                    </div>
                </div>
                <div class="kredit-info">
                    <div class="kredit-title">Умови покупки:</div>
                    <div
                        class="info-item d-flex align-items-center justify-content-between">
                        <div class="data">Всього до сплати:</div>
                        <div class="value"><span id="kredit-summ">4032</span>
                            грн
                        </div>
                    </div>
                    <div
                        class="info-item d-flex align-items-center justify-content-between">
                        <div class="data">Перший платіж:</div>
                        <div class="value"><span id="kredit-first">dsg</span>
                            грн
                        </div>
                    </div>
                    <div
                        class="info-item d-flex align-items-center justify-content-between">
                        <div class="data">Кількість платежів:</div>
                        <div class="value" id="kredit-chast">12</div>
                    </div>
                    <div
                        class="info-item d-flex align-items-center justify-content-between">
                        <div class="data">Щомісячний платіж</div>
                        <div class="value"><span id="kredit-plat">432</span> грн
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PaymentTypeLoan"
}
</script>

<style scoped>

</style>