<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <span>Мережа аптек</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>{{ $page.props.seo.h1.replace($store.state.city?.name, '') }}</h1>
            <span class="city-inner-button d-flex align-items-center">
                <span class="value">{{ $store.state.city?.name }}</span>
                <span class="ic icon-ic_arrow"></span>
            </span>
        </div>

        <div class="pharmacy-page" id="pharmacy-page">
            <div class="pharmacy-tabs-container d-md-flex align-items-center justify-content-between">
                <div class="search-city order-12">
                    <div class="search-container">
                        <form v-if="!isShowMap" class="form">
                            <div class="input-container">
                                <input :value="query" type="text" class="input search-input important"
                                       @input="searchDebounce($event.target.value)"
                                       placeholder="Пошук за адресою чи номером аптеки">
                                <button type="submit" class="submit" disabled>
                                    <span class="ic icon-ic_search"></span>
                                </button>
                            </div>
                        </form>
                        <div v-if="isShowMap" class="input-container">
                            <input :value="address" type="text"
                                   @input="searchAddressOnMap($event.target.value)"
                                   class="input search-input important"
                                   placeholder="Пошук за адресою аптеки">
                            <button type="submit" class="submit" disabled>
                                <span class="ic icon-ic_search"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="pharmacy-tabs">
                    <ul id="tabs-pharmacy" class="nav tabs-ul">
                        <li class="nav-item">
                            <button @click="showMap(false)" data-target="#list" data-toggle="tab" class="nav-link active">Списком</button>
                        </li>
                        <li class="nav-item">
                            <button @click="showMap(true)" data-target="#map" data-toggle="tab" class="nav-link">На карті</button>
                        </li>
                    </ul>
                </div>
            </div>

            <div v-if="!isShowMap" class="" id="list">
                <div class="farmacy-list">
                    <div class="farmacy-results">Знайдено: <span>{{ stores.total }} аптеки</span></div>

                    <store-row v-for="(store) in stores.data" :key="store.id" :store="store" />

                    <pagination :paginator="stores" :on-link-click="sendStoresForm" @load-more="loadMore" />

                </div>
            </div>

            <leaflet-map v-if="isShowMap" style="z-index: 10!important; margin-bottom: 10px"></leaflet-map>
        </div>

        <seo-text />
    </div>

</template>

<script>
import { defineAsyncComponent, nextTick } from "vue";

export default {
    components: {
        SeoText: defineAsyncComponent(() => import("@/frontend/Components/Seo/SeoText.vue")),
        Pagination: defineAsyncComponent(() => import("@/frontend/Components/Pagination.vue")),
        LeafletMap: defineAsyncComponent(() => import("@/frontend/Components/LeafletMap.vue")),
        StoreRow: defineAsyncComponent(() => import("@/frontend/Components/Store/StoreRow.vue"))
    },
    props: {
        search: String,
        stores: [Array, Object],
        popularProducts: [Array, Object],
    },
    data() {
        return {
            isShowMap: false,
            query: this.search || '',
            address: '',
            allStores: this.stores.data || [],
            loadMoreItems:  16,
            mapZoom: 12,
            timeout: null,
        }
    },
    computed: {
        filteredStoresMarkers() {
            return (this.allStores?.map(store => ({
                id: store.id,
                title: store.name,
                position: {
                    lat: 1 * store.lat,
                    lng: 1 * store.lng,
                }
            })) || []).filter(marker => {
                return marker.position.lat > 0 && marker.position.lng > 0
            })
        },
    },
    mounted() {
        this.$store.dispatch('markets/getPoints')
        this.$store.dispatch('markets/getMarkets')
    },
    methods: {
        showMap(data) {
            this.isShowMap = data
        },
        searchAddressOnMap(value) {
            if (value.length >= 5) {
                this.$store.dispatch('markets/getPointByAddress',  { value, city: this.$store.state.city })
            }
        },
        scrollToTop() {
            nextTick(() => {
                const element = document.getElementById('pharmacy-page');

                if (element) {
                    element.scrollIntoView({behavior: "smooth"})
                }
            })
        },
        searchDebounce(value) {
            let baseUrl = this.route('frontend.stores.index');

            const self = this;
            self.query = value || '';

            clearTimeout(self.timeout);
            self.timeout = setTimeout(() => {
                self.$inertia.get(baseUrl, {
                    search: value || '',
                }, {
                    preserveScroll: false,
                    preserveState: true,
                    replace: false,
                    only: ['stores'],
                    onSuccess: () => {
                        this.allStores = this.stores.data || [];
                    }
                });
            }, 500);
        },
        sendStoresForm(link) {
            let baseUrl = link?.url || link || this.route('frontend.stores.index');

            this.$inertia.get(baseUrl, {
                search: this.query || '',
            }, {
                preserveScroll: false,
                preserveState: true,
                replace: false,
                only: ['stores']
            });
            this.scrollToTop()
        },
        onMarkerClick(marker) {
            this.mapZoom++
        },
        onZoomChanged(event) {
            if (this.mapZoom !== event) {
                this.mapZoom = event;
            }
        },
        loadMore() {
            if (this.stores.next_page_url === null) {
                return
            }

            const updatedUrl = this.stores?.first_page_url.replace(/(page=)[^&]*/, `$1${this.stores?.current_page}`);
            this.loadMoreItems = this.loadMoreItems + 16

            this.$inertia.get(updatedUrl, { items: this.loadMoreItems }, {
                preserveState: true,
                preserveScroll: false,
                only: ['stores'],
                onSuccess: () => {
                    this.allStores = [...this.allStores, ...this.stores.data]
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
