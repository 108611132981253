<template>
    <div class="personal-page">
        <div class="personal-container">
            <div class="personal-orders">
                <h3>Мої замовлення</h3>
                <div class="personal-orders-items" :class="{wocashback: !cashbackEnable}">
                    <div class="order-items-header d-none d-md-flex align-items-center">
                        <div class="td-number">Номер замовлення</div>
                        <div class="td-status">Статус</div>
                        <div class="td-date">Дата</div>
                        <div class="td-cash" v-if="cashbackEnable">Нараховано кешбеку</div>
                        <div class="td-summ">Сума</div>
                        <div class="td-detail"></div>
                    </div>

                    <div v-for="order in orderItems" class="order-items-item">
                        <div class="d-md-flex align-items-center">
                            <div class="td-number">
                                <div class="label d-block d-md-none">Номер замовлення</div>
                                <div class="number">№{{ order.id }}</div>
                            </div>
                            <div class="td-status">
                                <div class="status" :class="getStatusClass(order.order_status)">
                                    {{ order.status_text }}
                                </div>
                            </div>
                            <div class="td-date">
                                <div class="label d-block d-md-none">Дата</div>
                                <div class="date">{{ order.created_at }}</div>
                            </div>
                            <div class="td-cash" v-if="cashbackEnable">
                                <div class="label d-block d-md-none">Нараховано кешбеку</div>
                                <div class="cash"></div>
                            </div>
                            <div class="td-summ">
                                <div class="label d-block d-md-none">Сума</div>
                                <div class="summ">{{ order.total + order.total_shipping }} ₴</div>
                            </div>
                            <div class="td-detail">
                                <button class="to-detail d-inline-flex align-items-center collapsed" type="button" data-toggle="collapse" :data-target="`#order-${order.id}`" aria-expanded="true" :aria-controls="`order-${order.id}`">
                                    <span class="value">Розгорнути</span>
                                    <span class="ic icon-ic_arrow"></span>
                                </button>
                            </div>
                        </div>
                        <div :id="`order-${order.id}`" class="collapse order-collapse">
                            <div class="order-detail">
                                <div class="detail-header d-md-flex align-items-start justify-content-between">
                                    <div class="order-12">
                                        <button class="button-2 retry d-flex align-items-center mb-3"
                                                v-if="order.can_be_edited && false">
                                            <span class="ic icon-retry"></span>
                                            <span class="value">Повторити замовлення</span>
                                        </button>
                                        <button class="button-2 cancel"
                                                @click.prevent="cancelOrder(order)"
                                                v-if="order.can_be_edited">
                                            <span class="value">Відмінити замовлення</span>
                                        </button>
                                    </div>

                                    <div class="order-detail-info">
                                        <div class="info-item d-sm-flex align-items-center">
                                            <span class="data">Спосіб оплати:</span>
                                            <span class="value d-flex align-items-center"
                                                  v-if="editOrderId !== order.id">
                                                <span>{{ order.payment_type?.name }}</span>
                                                <a v-if="order.can_be_edited && changePaymentEnabled"
                                                   @click.prevent="editOrder(order)"
                                                   class="ml-2">
                                                    <i class="ic icon-edit"></i>
                                                </a>
                                                <inertia-link
                                                    v-if="canBePayed(order)"
                                                    class="action ml-3"
                                                    :href="`/order/${order.id}/pay`"
                                                >Сплатити</inertia-link>

                                            </span>
                                            <span v-if="editOrderId === order.id" class="ml-2">
                                                <select class="action-select" v-model="paymentTypeId">
                                                    <option v-for="paymentType in paymentTypes"
                                                            :value="paymentType.value">
                                                        {{ paymentType.text }}
                                                    </option>
                                                </select>
                                                <a href="#" @click.prevent="saveOrder()" class="action primary ml-2">Зберегти</a>
                                                <a href="#" @click.prevent="cancelEdit()" class="action default ml-2">Відмінити</a>
                                            </span>

                                        </div>
                                        <div class="info-item d-sm-flex align-items-start">
                                            <span class="data">Доставка:</span>
                                            <span class="value">{{ order.delivery_type.name }} </span>
                                        </div>
                                        <div class="info-item d-sm-flex align-items-start">
                                            <span class="data">Оформлено на:</span>
                                            <span class="value">{{ order.recipient_data.firstname }} {{ order.recipient_data.lastname }}</span>
                                        </div>
                                        <div class="info-item d-sm-flex align-items-start">
                                            <span class="data">Номер телефону:</span>
                                            <span class="value">{{ order.recipient_data.phone }}</span>
                                        </div>
                                        <div class="info-item d-sm-flex align-items-start">
                                            <span class="data">Email:</span>
                                            <span class="value">{{ order.recipient_data.email }}</span>
                                        </div>
                                        <a @click.prevent href="#" class="el-check">Завантажити електронний чек</a>
                                    </div>
                                </div>
                                <div class="detail-order-items">
                                    <div class="row no-gutters">
                                        <div v-for="item in order.items" class="col-12 col-md-6">
                                            <div class="detail-order-item d-sm-flex align-items-start">
                                                <inertia-link :href="item.product.url" class="item-image">
                                                    <img :src="item.product.image_url" alt="" class="lozad">
                                                </inertia-link>
                                                <div class="item-info">
                                                    <a href="#" class="name">{{ item.product.name }}</a>
                                                    <div class="price">{{ item.price * item.quantity }} грн</div>
                                                    <button @click.prevent="$inertia.modal(route('frontend.review.add', ['product', item.product.id]))"
                                                            class="add-review d-flex align-items-center justify-content-start">
                                                        <span class="ic icon-lk-icon-4"></span>
                                                        <span class="value">Залишити відгук</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="detail-order-itogo d-flex justify-content-end">
                                    <div class="itogo-container">
                                        <div class="itogo-item d-flex align-items-center justify-content-between">
                                            <div class="data">Всього:</div>
                                            <div class="value">{{ order.total }} грн</div>
                                        </div>
                                        <div class="itogo-item cash d-flex align-items-center justify-content-between"
                                             v-if="cashbackEnable">
                                            <div class="data">Нараховано кешбеку:</div>
                                            <div class="value">0 грн</div>
                                        </div>
                                        <div
                                            class="itogo-item delivery d-flex align-items-center justify-content-between">
                                            <div class="data">Доставка:</div>
                                            <div class="value">{{ order.total_shipping || 'Безкоштовно' }}</div>
                                        </div>
                                        <div class="itogo-item summ d-flex align-items-center justify-content-between">
                                            <div class="data">Разом:</div>
                                            <div class="value">{{ order.total + order.total_shipping }} грн</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="text-center">
                    <!-- todo: pagination -->
                    <button class="more-items d-inline-flex align-items-center" v-if="hasMorePages"
                            @click.prevent="loadMore()">
                        <span class="value">Показати ще</span>
                        <span class="ic icon-more"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRobotNoIndexFollow } from "../metaTags"
import { usePopLoader } from "../../scrollToTop";

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
useRobotNoIndexFollow()
</script>

<script>
import ProfileLayout from "./ProfileLayout.vue";

export default {
    layout: ProfileLayout,
    props: {
        orders: {
            type: Array,
            default() {
                return []
            }
        },
        paymentTypes: {
            type: Array,
            default() {
                return []
            }
        },
        page: {
            type: [Object, Number],
            default: 1
        },
        hasMore: {
            type: [Object, Boolean],
            default: false,

        }
    },
    setup() {

    },
    data() {
        return {
            editOrderId: 0,
            paymentTypeId: 0,
            currentPage: 1,
            hasMorePages: false,
            orderItems: [],
        }
    },
    created() {
        this.currentPage = this.page || 1;
        this.hasMorePages = this.hasMore || false;
        this.orderItems = this.orders || [];
    },
    computed: {
        getStatusClass() {
            return status => {
                if (status === 0) return 'otmena'
                if (status > 0) return 'deliv'
                if (status === 3) return 'ok'
            }
        },
        cashbackEnable() {
            return false;
        },
        changePaymentEnabled() {
            return false;
        }
    },
    methods: {
        editOrder(order) {
            this.paymentTypeId = order.payment_type_id;
            this.editOrderId = order.id;
        },
        cancelEdit() {
            this.paymentTypeId = 0;
            this.editOrderId = 0;
        },
        saveOrder() {
            this.$inertia.post(this.route('frontend.profile.orders.update', this.editOrderId), {
                payment_type_id: this.paymentTypeId,
            }, {
                onSuccess() {
                    this.paymentTypeId = 0;
                    this.editOrderId = 0;
                }
            })
        },
        cancelOrder(order) {
            this.$inertia.post(this.route('frontend.profile.orders.cancel', order.id));
        },
        canBePayed(order) {
            if (order.payment_type)
                return order.payment_type.slug === 'card' && order.payment_status === 1 && order.order_status !== 5 && order.order_status !== 6
        },
        loadMore() {
            const page = this.currentPage + 1;
            axios.get(this.route('frontend.profile.orders') + '?page=' + page)
                .then(({data: paginator}) => {
                    this.orderItems = [...this.orderItems, ...paginator.data];
                    this.currentPage = paginator.current_page;
                    this.hasMorePages = !!paginator.next_page_url;
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    }
}
</script>

<style scoped>

</style>
